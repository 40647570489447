import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { sidebarToggle } from "../../../store/UI/control";
import Avatar from "@material-ui/core/Avatar";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();

  const { sidebarState } = useSelector((state) => state.ui.control);
  const navigate = useNavigate();
  const goToProfilePage = () => navigate("/profile");

  const [time, setTime] = useState();
  const [date, setDate] = useState();
  const [userInfo] = useState(() => {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    return info || null;
  });

  useEffect(() => {
    if (!userInfo) navigate("/login");
  }, [userInfo, navigate]);

  const signout = () => {
    navigate("/login");
    localStorage.clear();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().format("hh:mm:ss").toString());
    }, 1000);
    setDate(moment().format("ddd DD MMM, yyyy").toString());

    return () => clearInterval(interval);
  }, []);

  return (
    <nav className="header">
      <div>
        <span>propx</span>
        <span
          role="button"
          onClick={() => dispatch(sidebarToggle())}
          className="nav-toggle">
          <i
            className={
              sidebarState ? "fas fa-arrow-left fa-xs" : "fas fa-bars fa-xs "
            }></i>
        </span>
      </div>
      <div>
        <div className="clock">
          {date} {time}
        </div>
        <div type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <div>
            <Avatar alt="" src={null} className="avatar">
              {userInfo.firstName
                ? userInfo.firstName.charAt(0).toUpperCase() +
                  userInfo.lastName.charAt(0).toUpperCase()
                : "A"}
            </Avatar>
            <span>{`${userInfo.firstName}  ${userInfo.lastName}`}</span>
          </div>
        </div>
        <ul className="dropdown-menu">
          <li>
            <a
              className="dropdown-item dropItem"
              href="#d"
              role="button"
              onClick={goToProfilePage}>
              Profile Setting
            </a>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <Link
              className="dropdown-item dropItem"
              to="/login"
              role="button"
              onClick={signout}>
              Sign out
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
