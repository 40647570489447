import { ViewPropPage, PropsPage, CDRsPage } from "../../pages";
import { modules } from "../../constants/modules";

export const props = [
  {
    path: "/props/view/:propId",
    element: ViewPropPage,
    isPrivate: true,
    gurd: modules.PROP_VIEW,
    module: modules.PROP,
  },
  {
    path: "/props/list",
    element: PropsPage,
    isPrivate: true,
    gurd: modules.PROP_VIEW,
    module: modules.PROP,
  },

  {
    path: "/props/cdr",
    element: CDRsPage,
    isPrivate: true,
    gurd: modules.CDR_VIEW,
    module: modules.PROP,
  },
];
