import React from "react";
import moment from "moment";

import { Table } from "../Layouts/shared/Table-B";
import { ActionButtons } from "./actions";
import { TaskSlice } from "../../store/actions/tasks";

export const TaskTable = () => {
  const formatZero = (cell, ttype) =>
    cell !== 0 ? <div className={ttype}>{cell}</div> : <span>{cell}</span>;

  const columns = [
    {
      dataField: "id",
      // isDummyField: true,
      text: "#",
      headerAlign: "center",
      align: "center",
      formatter: (cell, row, index) => <span>{index + 1}</span>,
    },
    {
      dataField: "taskName",
      text: "Task Name",
      sort: true,
      style: {
        fontWeight: "bold",
      },
      attrs: {
        "data-label": "Task Name",
      },
    },
    {
      dataField: "scheduleTime",
      text: "Schedule Time",
      sort: true,
      attrs: {
        "data-label": "Task Name",
      },
      formatter: (cell, row) => (
        <span>{moment(cell).format("YYYY MMM, DD  -  HH:mm")}</span>
      ),
    },
    {
      dataField: "idle",
      text: "Idel",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Task Name",
      },
    },
    {
      dataField: "sending",
      text: "Sending",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Sending",
      },
    },
    {
      dataField: "schedule",
      text: "Scheduled",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Scheduled",
      },
      formatter: (cell) =>
        formatZero(parseInt(cell), "badge rounded-pill bg-warning "),
    },
    {
      dataField: "delivered",
      text: "Delivered",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Delivered",
      },
    },
    {
      dataField: "incomplete",
      text: "Incomplete",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Incomplete",
      },
      formatter: (cell) =>
        formatZero(parseInt(cell), "badge rounded-pill bg-danger text-light"),
    },
    {
      dataField: "complete",
      text: "Completed",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Completed",
      },
      formatter: (cell) =>
        formatZero(parseInt(cell), "badge rounded-pill bg-success text-light "),
    },
    {
      dataField: "taskName1",
      text: "Actions",
      isDummyField: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Actions",
      },
      formatter: (cell, row) => <ActionButtons data={row} />,
    },
  ];

  const innerColumns = [
    {
      dataField: "id",
      // isDummyField: true,
      text: "#",
      headerAlign: "center",
      align: "center",
      formatter: (cell, row, index) => <span>{index + 1}</span>,
    },
    {
      dataField: "username",
      text: "Props",
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Prop Name",
      },
    },
    {
      dataField: "sim",
      text: "Slot",
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Slot",
      },
    },
    {
      dataField: "scheduleTime",
      text: "Scheduled",
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Scheduled",
      },
      formatter: (cell, row) => (
        <span>{moment(cell).format("YYYY MMM, DD  -  HH:mm")}</span>
      ),
    },
    {
      dataField: "idle",
      text: "Idle",
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Idle",
      },
    },
    {
      dataField: "sending",
      text: "Sending",
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Sending",
      },
    },
    {
      dataField: "schedule",
      text: "Scheduled",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Scheduled",
      },
      formatter: (cell) =>
        formatZero(parseInt(cell), "badge rounded-pill bg-warning "),
    },
    {
      dataField: "delivered",
      text: "Delivered",
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Delivered",
      },
    },
    {
      dataField: "incomplete",
      text: "Incompleted",
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Incompleted",
      },
      formatter: (cell) =>
        formatZero(parseInt(cell), "badge rounded-pill bg-danger text-light "),
    },
    {
      dataField: "complete",
      text: "Completed",
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Completed",
      },
      formatter: (cell) =>
        formatZero(parseInt(cell), "badge rounded-pill bg-success text-light "),
    },
  ];

  return (
    <Table
      keyField="taskName"
      sliceName="tasks"
      sliceReducer={TaskSlice}
      columns={columns}
      showSizePerPage={false}
      expandData={{
        innerKey: "id",
        columns: innerColumns,
      }}
    />
  );
};
