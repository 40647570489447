import React from "react";
import { useField, ErrorMessage } from "formik";
import { TextError } from "./TextError";
import { Input as InputField } from "antd";

export const Input = ({
  label,
  name,
  classNameLabel = "form-label pt-2 mb-1",
  ...rest
}) => {
  /*eslint-disable */
  const [field, state, { setValue, setTouched }] = useField(name);
  const onChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div>
      <label className={classNameLabel} htmlFor={name}>
        {label}
      </label>
      <InputField onChange={onChange} value={state.value} {...rest} />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
