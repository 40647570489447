import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { PageTitle } from "../../components/Layouts/shared/PageTitle";
import { MobileTable } from "../../components/Mobile/MobileTable";
import { UploadModal } from "../../components/Mobile/UploadModal";
import { UploadTag } from "../../components/Mobile/UploadTag";

import "./index.scss";

export const MobilePage = () => {
  const location = useLocation();
  const { isLoading } = useSelector((state) => state.entities.files);

  const [show, setShow] = useState(false);
  const [onProgress, setOnProgress] = useState(0);

  const setShowModal = () => setShow(!show);

  return (
    <>
      <PageTitle location={location}>Software List</PageTitle>
      <div className=" bg-white border  p-3">
        <UploadTag onProgress={onProgress} setShowModal={setShowModal} />
        <MobileTable isLoading={isLoading} />
      </div>
      <UploadModal
        show={show}
        onProgress={onProgress}
        setOnProgress={setOnProgress}
        setShowModal={setShowModal}
      />
    </>
  );
};
