import { createSlice } from "@reduxjs/toolkit";
import ThunkHelper from "../thunkHelper";

const slicePrefix = "Users";
const endPointUrl = "/api/users";

const UserSlice = {
  paginate: ThunkHelper.paginate(slicePrefix, endPointUrl),
  get: ThunkHelper.get(slicePrefix, endPointUrl),
  update: ThunkHelper.put(slicePrefix, endPointUrl),
  updateUserRole: ThunkHelper.put(slicePrefix, `${endPointUrl}/group`),
  updateUserStatus: ThunkHelper.put(slicePrefix, `${endPointUrl}/status`),
  add: ThunkHelper.post(slicePrefix, endPointUrl),
  delete: ThunkHelper.delete(slicePrefix, endPointUrl),
};

const UserSliceInit = createSlice({
  name: slicePrefix,
  initialState: ThunkHelper.initialState,
  extraReducers: {
    [UserSlice.paginate.pending]: ThunkHelper.pendingReducer,
    [UserSlice.paginate.rejected]: ThunkHelper.rejectReducer,
    [UserSlice.paginate.fulfilled]: ThunkHelper.setPaginateReducer,
    [UserSlice.add.pending]: ThunkHelper.pendingReducer,
    [UserSlice.add.rejected]: ThunkHelper.rejectReducer,
    [UserSlice.add.fulfilled]: ThunkHelper.setAddReducer,
    [UserSlice.get.pending]: ThunkHelper.pendingReducer,
    [UserSlice.get.rejected]: ThunkHelper.rejectReducer,
    [UserSlice.get.fulfilled]: ThunkHelper.setAddObjReducer,
    [UserSlice.update.pending]: ThunkHelper.pendingReducer,
    [UserSlice.update.rejected]: ThunkHelper.rejectReducer,
    [UserSlice.update.fulfilled]: ThunkHelper.setUpdateReducer,
    [UserSlice.updateUserRole.pending]: ThunkHelper.pendingReducer,
    [UserSlice.updateUserRole.rejected]: ThunkHelper.rejectReducer,
    [UserSlice.updateUserRole.fulfilled]: ThunkHelper.setUpdateReducer,
    [UserSlice.updateUserStatus.pending]: ThunkHelper.pendingReducer,
    [UserSlice.updateUserStatus.rejected]: ThunkHelper.rejectReducer,
    [UserSlice.updateUserStatus.fulfilled]: ThunkHelper.setUpdateReducer,
    [UserSlice.delete.pending]: ThunkHelper.pendingReducer,
    [UserSlice.delete.rejected]: ThunkHelper.rejectReducer,
    [UserSlice.delete.fulfilled]: ThunkHelper.setDeleteReducer,
  },
});

export { UserSlice, UserSliceInit };
