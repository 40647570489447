import { decryptPermissions } from "./tokenCheck";

const notUndefined = (anyValue) => typeof anyValue !== "undefined";

export const canAccess = (route) => {
  const hasAccess = localStorage.getItem("access_token");
  if (!hasAccess) return false;

  if (!route.module) return true;

  const permissions = decryptPermissions();

  const available = permissions
    .map((permission) =>
      route.gurd === permission.gurdName ? permission.gurdName : null
    )
    .filter(notUndefined);

  return available.includes(route.gurd);
};
