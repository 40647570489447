import React from "react";
import { useField, ErrorMessage } from "formik";
import { TextError } from "./TextError";
import { Switch } from "antd";

export const SwitchControl = ({
  label,
  name,
  options,
  classNameLabel = "ms-2",
  ...rest
}) => {
  /*eslint-disable */
  const [field, state, { setValue, setTouched }] = useField(name);

  function onChange(checked) {
    setValue(checked);
  }

  return (
    <div className="form-control border-0 ">
      <Switch
        onChange={onChange}
        size="small"
        {...rest}
        checked={state.value}
      />
      {/* <Field name={name} {...rest} /> */}
      <label className={classNameLabel}>{label}</label>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
