import React from "react";
import { useLocation } from "react-router-dom";

import { AddButton } from "../../components/Groups/AddButton";
import GroupTable from "../../components/Groups/groupTable";
import { PageTitle } from "../../components/Layouts/shared/PageTitle";

export const GroupPage = () => {
  const location = useLocation();

  return (
    <>
      <PageTitle location={location}>Group policy</PageTitle>
      <div className=" bg-white border  p-3">
        <AddButton />
        <GroupTable />
      </div>
    </>
  );
};
