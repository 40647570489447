import React from "react";
import "./index.scss";

const DataLoading = ({ height }) => {
  return (
    <div className="data-loader" style={{ height: height }}>
      <div className="titles">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="box"></div>
    </div>
  );
};

export const TableLoader = ({ height }) => {
  return (
    <div className="data-loader" style={{ height: height }}>
      <div className="titles">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="box"></div>
    </div>
  );
};

export const StatLoading = ({ height }) => {
  return (
    <div className="data-loader" style={{ height: height }}>
      <div className="titles-lg">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="box"></div>
    </div>
  );
};

export const ListLoader = ({ height }) => {
  return (
    <div className="pl-4 pr-4 pt-4">
      <div className="data-loader" style={{ height: height }}>
        <div className="box"></div>
      </div>
    </div>
  );
};

export const FullPageLoader = () => {
  return (
    <div className="page-loader">
      <div className="sidebar-left">
        <div className="profile">
          <div className="shade">
            <div className="name-1 shade"></div>
          </div>
        </div>
        <div className="nav-group">
          <div className="label shade"></div>
          <div className="group-container">
            <div className="link shade"></div>
            <div className="link shade"></div>
            <div className="link shade"></div>
          </div>
        </div>
        <div className="nav-group">
          <div className="label shade"></div>
          <div className="group-container">
            <div className="link shade"></div>
            <div className="link shade"></div>
            <div className="link shade"> </div>
          </div>
        </div>
      </div>
      <div className="dashboard-container">
        <div className="cards">
          <div className="card-xl shade"></div>
          <div className="card shade"></div>
          <div className="card shade"></div>
        </div>
        <div className="cards">
          <div className="card-m shade"></div>
          <div className="card-m shade"></div>
        </div>
      </div>
    </div>
  );
};

export default DataLoading;
