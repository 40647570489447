import React, { useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { defaultTab, tabs } from "../../components/CDR/view/tabs";
import { TabContainer } from "../../components/Layouts/shared/TabContainer";
import DataLoading from "../../components/Layouts/shared/DataLoading";
import { Button } from "../../components/Layouts/shared/Button";
import { PropSlice } from "../../store/actions/props";

export const ViewPropPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propId } = useParams();

  const propInfo = useSelector((state) =>
    state.entities.props.list.find((prop) => prop.id === propId)
  );
  const { object: propStatistics, isLoading } = useSelector(
    (state) => state.entities.props
  );

  const goToPropsList = useCallback(() => {
    navigate("/props/list");
  }, [navigate]);

  useEffect(() => {
    const init = async () => {
      await dispatch(PropSlice.paginate());
      await dispatch(PropSlice.get({ id: propId }));
    };
    init();
  }, [dispatch, propId]);

  return (
    <div className="content-body">
      <div className="container-fluid ">
        <div className="d-flex justify-content-between">
          <div className="p-2">
            <Button
              title="Props List"
              onClick={goToPropsList}
              className="custom-button w-100 m-1"
            />
          </div>
        </div>

        {isLoading ? (
          <DataLoading />
        ) : (
          <TabContainer
            defaultTab={defaultTab}
            tabs={tabs}
            propInfo={propInfo}
            statistics={propStatistics}
          />
        )}
      </div>
    </div>
  );
};
