import { createSlice } from "@reduxjs/toolkit";
import ThunkHelper from "../thunkHelper";

const slicePrefix = "Groups";
const endPointUrl = "/api/groups";

const GroupSlice = {
  paginate: ThunkHelper.paginate(slicePrefix, endPointUrl),
  getAll: ThunkHelper.getAll(slicePrefix, endPointUrl),
  get: ThunkHelper.get(slicePrefix, endPointUrl),
  add: ThunkHelper.post(slicePrefix, endPointUrl),
  updateStatus: ThunkHelper.put(slicePrefix, `${endPointUrl}/status`),
  updatePermission: ThunkHelper.put(slicePrefix, `${endPointUrl}/permission`),
  delete: ThunkHelper.delete(slicePrefix, endPointUrl),
};

const GroupSliceInit = createSlice({
  name: slicePrefix,
  initialState: ThunkHelper.initialState,
  extraReducers: {
    [GroupSlice.paginate.pending]: ThunkHelper.pendingReducer,
    [GroupSlice.paginate.rejected]: ThunkHelper.rejectReducer,
    [GroupSlice.paginate.fulfilled]: ThunkHelper.setPaginateReducer,
    [GroupSlice.getAll.pending]: ThunkHelper.pendingReducer,
    [GroupSlice.getAll.rejected]: ThunkHelper.rejectReducer,
    [GroupSlice.getAll.fulfilled]: ThunkHelper.setPaginateReducer,
    [GroupSlice.add.pending]: ThunkHelper.pendingReducer,
    [GroupSlice.add.rejected]: ThunkHelper.rejectReducer,
    [GroupSlice.add.fulfilled]: ThunkHelper.setAddReducer,
    [GroupSlice.get.pending]: ThunkHelper.pendingReducer,
    [GroupSlice.get.rejected]: ThunkHelper.rejectReducer,
    [GroupSlice.get.fulfilled]: ThunkHelper.setAddObjReducer,
    [GroupSlice.updateStatus.pending]: ThunkHelper.pendingReducer,
    [GroupSlice.updateStatus.rejected]: ThunkHelper.rejectReducer,
    [GroupSlice.updateStatus.fulfilled]: ThunkHelper.setUpdateReducer,
    [GroupSlice.updatePermission.pending]: ThunkHelper.pendingReducer,
    [GroupSlice.updatePermission.rejected]: ThunkHelper.rejectReducer,
    [GroupSlice.updatePermission.fulfilled]: ThunkHelper.setUpdateReducer,
    [GroupSlice.delete.pending]: ThunkHelper.pendingReducer,
    [GroupSlice.delete.rejected]: ThunkHelper.rejectReducer,
    [GroupSlice.delete.fulfilled]: ThunkHelper.setDeleteReducer,
  },
});

export { GroupSlice, GroupSliceInit };
