import React from "react";
import { Checkbox } from "./Checkbox";
import { DatePicker } from "./DatePicker";
import { Input } from "./Input";
import { RadioButton } from "./RadioButton";
import { Selector } from "./Select";
import { Textarea } from "./Textarea";
import { SwitchControl } from "./Switch";
import { Multi } from "./Multi";
import { CheckboxSingular } from "./Check";

const FormikControl = ({ control, ...rest }) => {
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <Selector {...rest} />;
    case "radio":
      return <RadioButton {...rest} />;
    case "checkbox":
      return <Checkbox {...rest} />;
    case "date":
      return <DatePicker {...rest} />;
    case "multiSelect":
      return <Multi {...rest} />;
    case "switch":
      return <SwitchControl {...rest} />;
    case "checkboxs":
      return <CheckboxSingular {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
