import { createSlice } from "@reduxjs/toolkit";
import ThunkHelper from "../thunkHelper";
import moment from "moment";

const slicePrefix = "SysLogs";
const endPointUrl = "/api/sysLogs";

const SysLogSlice = {
  paginate: ThunkHelper.paginate(slicePrefix, endPointUrl),
};

const filterProps = {
  page: 1,
  per_page: 10,
  refresh: 0,
  prop: "",
  status: [],
  start: moment().startOf("day").valueOf(),
  end: moment().endOf("day").valueOf(),
};

const SysLogSliceInit = createSlice({
  name: slicePrefix,
  initialState: { ...ThunkHelper.initialState, filterProps },
  reducers: {
    setFilter: ThunkHelper.setInitFilter,
  },
  extraReducers: {
    [SysLogSlice.paginate.pending]: ThunkHelper.pendingReducer,
    [SysLogSlice.paginate.rejected]: ThunkHelper.rejectReducer,
    [SysLogSlice.paginate.fulfilled]: ThunkHelper.setPaginateReducer,
  },
});

export const { setFilter } = SysLogSliceInit.actions;
export { SysLogSlice, SysLogSliceInit };
