import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import FormikControl from "../Layouts/shared/Formik/FormikControl";
import ErrorField from "../Layouts/shared/ErrorField";
import { PasswordSechema } from "../../schemas/changePassword";
import { Button } from "../Layouts/shared/Button";
import { CustomModal } from "../Layouts/shared/Modals/modals";
import { UserSlice } from "../../store/actions/users";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [showModal, setShowModal] = useState(false);

  const { error } = useSelector((state) => state.entities.users);

  const { email, userId } = JSON.parse(localStorage.getItem("userInfo"));

  const initialValues = {
    username: email,
    oldPassword: null,
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values) => {
    const { error } = await dispatch(
      UserSlice.update({ id: userId, data: values })
    );
    if (!error) {
      setShowModal(true);
    } else {
      addToast(error.message, { appearance: "error", autoDismiss: true });
    }
  };

  const loginAgain = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="col-12 app-list p-4">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={PasswordSechema}>
        {({ dirty, isSubmitting }) => (
          <Form>
            <div>
              <div className="col-lg-4">
                <FormikControl
                  control="input"
                  type="email"
                  name="username"
                  label="Email"
                  disabled
                />
              </div>
              <div className="col-lg-4">
                <FormikControl
                  control="input"
                  type="password"
                  name="oldPassword"
                  label="Current Password"
                />
                <ErrorField message={error} />
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <FormikControl
                    control="input"
                    type="password"
                    name="password"
                    label="New Password"
                  />
                </div>
                <div className="col-lg-4">
                  <FormikControl
                    control="input"
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end p-4">
                <div className="m-1">
                  <Button type="submit" disabled={!dirty}>
                    {isSubmitting ? "Loading ..." : "Change Password"}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <CustomModal
        title="Login"
        showModal={showModal}
        setShowModal={setShowModal}
        actionName="Login Again"
        action={loginAgain}>
        You need to Re-Login again after change password .
      </CustomModal>
    </div>
  );
};

export default ChangePassword;
