import React from "react";
import { Formik, Form } from "formik";
import { Progress, Modal } from "antd";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import { FileSlice, setReload } from "../../store/actions/files";
import { apkSchema } from "../../schemas/apk";
import FormikControl from "../../components/Layouts/shared/Formik/FormikControl";

export const UploadModal = ({
  show,
  onProgress,
  setShowModal,
  setOnProgress,
}) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { apk, version } = values;
    let data = new FormData();
    data.append("propx", apk);
    data.append("version", version);

    const config = {
      onUploadProgress: (progressEven) => {
        const { loaded, total } = progressEven;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) setOnProgress(percent);
      },
    };

    const res = await dispatch(FileSlice.add({ data, config }));

    if (!res.error) {
      setOnProgress(0);
      dispatch(setReload());
      setShowModal();
      addToast("File has been uploaded", {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      addToast(res.data, { appearance: "error", autoDismiss: true });
    }
    setSubmitting(false);
    resetForm();
  };

  let submitMyForm = null;

  const handleSubmitForm = (e) => {
    if (submitMyForm) submitMyForm(e);
  };

  const bindSubmitForm = (submitForm) => {
    submitMyForm = submitForm;
  };

  return (
    <Modal
      visible={show}
      title="Add New Release"
      okText="Upload"
      okButtonProps={{ disabled: onProgress > 0 }}
      onOk={handleSubmitForm}
      onCancel={setShowModal}>
      <Formik
        initialValues={{ apk: "", version: "" }}
        onSubmit={handleSubmit}
        validationSchema={apkSchema}>
        {({ setFieldValue, submitForm }) => {
          bindSubmitForm(submitForm);
          return (
            <Form className="p-2">
              <FormikControl
                control="input"
                type="file"
                name="file"
                label="APK File"
                accept=".apk"
                className="border-0"
                onChange={(event) => {
                  setFieldValue("apk", event.target.files[0]);
                }}
              />
              <div className="pt-3">
                <FormikControl
                  control="input"
                  name="version"
                  type="text"
                  label="Version"
                  placeholder="Enter Release number"
                />
              </div>
              {onProgress > 0 ? (
                <div className="pt-4">
                  <span>Upload progress ...</span>
                  <Progress strokeLinecap="square" percent={onProgress} />
                </div>
              ) : null}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
