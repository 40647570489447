import React, { useEffect } from "react";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";

import FormikControl from "../../components/Layouts/shared/Formik/FormikControl";
import { Button } from "../../components/Layouts/shared/Button";
import { addUserSchema } from "../../schemas/addUser";
import { UserSlice } from "../../store/actions/users";
import { TextError } from "../../components/Layouts/shared/Formik/TextError";
import { PageTitle } from "../../components/Layouts/shared/PageTitle";
import { getTypes } from "../../store/actions/types";
import { success } from "../../components/Layouts/shared/Modals";

export const AddUser = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error: errors } = useSelector((state) => state.entities.users);
  const { object: user } = useSelector((state) => state.entities.users);
  const { types } = useSelector((state) => state.entities.types);

  const initialValues =
    id !== "-1"
      ? {
          username: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
          passsword: null,
          active: user.active ? user.active : false,
          groups:
            user.Groups && user.Groups.length > 0 ? user.Groups[0].name : "",
        }
      : {
          username: "",
          firstName: "",
          lastName: "",
          password: null,
          confirmPassword: null,
          active: false,
          groups: "",
        };

  useEffect(() => {
    const initTypes = async () => await dispatch(getTypes());
    const initUser = async () =>
      id !== "-1" && (await dispatch(UserSlice.get({ id })));
    initTypes();
    initUser();
  }, [dispatch, id]);

  const handleOnSubmit = async (values, { setSubmitting }) => {
    if (setSubmitting) {
      if (id === "-1") {
        const newUserInfo = _.omit(values, "confirmPassword");
        const res = await dispatch(UserSlice.add({ data: newUserInfo }));
        if (!res.error) {
          success("User Add Successfully");
          navigate("/security/user");
        }
      } else {
        const res = await dispatch(UserSlice.update({ id, data: values }));
        if (!res.error) {
          success("User Add Successfully");
          navigate("/security/user");
        }
      }
    }
    setSubmitting(false);
  };
  return (
    <React.Fragment>
      <PageTitle location={location}>Add User</PageTitle>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={addUserSchema}>
        {({ isSubmitting, dirty }) => (
          <Form className="p-5 bg-white">
            {errors && <TextError> {errors}</TextError>}
            <div className="row">
              <FormikControl
                control="input"
                label="Email"
                name="username"
                type="text"
                placeholder="...."
              />
              <div className="col-md-6  pt-4">
                <FormikControl
                  control="input"
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="...."
                />
              </div>
              <div className="col-md-6  pt-4">
                <FormikControl
                  control="input"
                  label="Re - Password"
                  name="confirmPassword"
                  type="password"
                  placeholder="...."
                />
              </div>
              <div className="col-md-6 pt-4">
                <FormikControl
                  control="input"
                  label="First Name"
                  name="firstName"
                  type="text"
                  placeholder="...."
                />
              </div>
              <div className="col-md-6 pt-4">
                <FormikControl
                  control="input"
                  label="Last Name"
                  name="lastName"
                  type="text"
                  placeholder="...."
                />
              </div>
              <div className="col-md-6 pt-4">
                <FormikControl
                  control="select"
                  options={types.roles ? types.roles : []}
                  label="Assign to Group"
                  name="groups"
                />
              </div>
              <div className="col-md-6 pt-5">
                <div className="">
                  <FormikControl
                    control="checkboxs"
                    type="checkbox"
                    className="form-check form-switch"
                    name="active"
                    label="Active"
                  />
                </div>
              </div>
              <div className="d-flex pt-5">
                <div className="m-1">
                  <Button type="submit" disabled={!dirty}>
                    {isSubmitting
                      ? "Loading"
                      : id !== "-1"
                      ? "update"
                      : "Submit"}
                  </Button>
                </div>
                <div className="m-1">
                  <Button
                    type="button"
                    className="bg-danger"
                    onClick={() => navigate("/security/user")}>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
