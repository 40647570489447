import { createSlice } from "@reduxjs/toolkit";
import ThunkHelper from "../thunkHelper";
import moment from "moment";

const slicePrefix = "Cdrs";
const endPointUrl = "/api/callLogs";

const CDRtSlice = {
  paginate: ThunkHelper.paginate(slicePrefix, endPointUrl),
};

const filterProps = {
  page: 1,
  per_page: 10,
  refresh: 0,
  propName: "",
  number: "",
  cli: "",
  types: [],
  state: [],
  client: [],
  operator: [],
  country: [],
  start: moment().startOf("day").valueOf(),
  end: moment().endOf("day").valueOf(),
};

const CDRSliceInit = createSlice({
  name: slicePrefix,
  initialState: { ...ThunkHelper.initialState, filterProps },
  reducers: {
    setFilter: ThunkHelper.setInitFilter,
    setReload: ThunkHelper.reloadData,
  },
  extraReducers: {
    [CDRtSlice.paginate.pending]: ThunkHelper.pendingReducer,
    [CDRtSlice.paginate.rejected]: ThunkHelper.rejectReducer,
    [CDRtSlice.paginate.fulfilled]: ThunkHelper.setPaginateReducer,
  },
});

export const { setFilter, setRelaod } = CDRSliceInit.actions;
export { CDRtSlice, CDRSliceInit };
