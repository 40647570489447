import { createSlice } from "@reduxjs/toolkit";
import ThunkHelper from "../thunkHelper";
import jwt from "jsonwebtoken";
import { encryptData } from "../../utils/auth/crypto";
import { GroupSlice } from "./groups";

const slicePrefix = "Auth";
const endPointUrl = "/api/auth";

const AuthSlice = {
  login: ThunkHelper.post(slicePrefix, endPointUrl, async (thunkAPI, data) => {
    localStorage.setItem("access_token", data.accessToken);
    localStorage.setItem("refresh_token", data.refreshToken);

    const decoded = jwt.decode(data.accessToken);
    localStorage.setItem(
      "userInfo",
      JSON.stringify({
        userId: decoded.userId,
        firstName: decoded.firstName,
        lastName: decoded.lastName,
        email: data.username,
        lastLogin: decoded.lastLogin,
        createdAt: decoded.createdAt,
      })
    );

    const { Permissions } = await thunkAPI
      .dispatch(GroupSlice.get({ id: decoded.groupId }))
      .unwrap();

    const salt = "6d090796-ecdf-11ea-adc1-0242ac120003";

    const encryptedData = await encryptData(Permissions, salt);
    await localStorage.setItem("permissions", encryptedData);
    return { ...decoded, email: data.username };
  }),
};

const AuthSliceInit = createSlice({
  name: slicePrefix,
  initialState: {
    userInfo: null,
    isSuccess: false,
    isError: false,
    error: "",
  },
  reducers: {
    clearState: (state) => {
      state.isSuccess = false;
      state.isError = false;
      state.error = "";
    },
  },
  extraReducers: {
    [AuthSlice.login.pending]: (state, { payload }) => {
      state.isSuccess = false;
      state.isError = false;
      state.error = "";
    },
    [AuthSlice.login.rejected]: (state, { payload }) => {
      state.isSuccess = false;
      state.isError = true;
      state.error = payload && payload.message ? payload.message : payload;
    },
    [AuthSlice.login.fulfilled]: (state, { payload }) => {
      state.userId = payload;
      state.isSuccess = true;
      state.isError = false;
      state.error = "";
    },
  },
});

export const { login } = AuthSlice;
export const { clearState } = AuthSliceInit.actions;
export { AuthSlice, AuthSliceInit };
