import { SystemLog, MobilePage, ServicesPage } from "../../pages";
import { modules } from "../../constants/modules";

export const settings = [
  {
    path: "/settings/sysLog",
    element: SystemLog,
    isPrivate: true,
    gurd: modules.SYSLOG_VIEW,
    module: modules.SETTING,
  },
  {
    path: "/settings/mobile-app",
    element: MobilePage,
    isPrivate: true,
    gurd: modules.MOBILE_VIEW,
    module: modules.SETTING,
  },
  {
    path: "/settings/service",
    element: ServicesPage,
    isPrivate: true,
    gurd: modules.SERVICE_VIEW,
    module: modules.SETTING,
  },
];
