import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { ClientTable } from "../../components/Client/ClientTable";
import { PageTitle } from "../../components/Layouts/shared/PageTitle";
import { AddButton } from "../../components/Client/AddButton";
import { AddModal } from "../../components/Client/AddModal";

export const ClientPage = () => {
  const location = useLocation();

  const [show, setShow] = useState(false);

  const showHandler = () => setShow(!show);

  return (
    <>
      <PageTitle location={location}>Client List</PageTitle>
      <div className=" bg-white border  p-3">
        <AddButton showHandler={showHandler} />
        <ClientTable />
        <AddModal show={show} showHandler={showHandler} />
      </div>
    </>
  );
};
