import { createSlice } from "@reduxjs/toolkit";
import ThunkHelper from "../thunkHelper";
import moment from "moment";

const slicePrefix = "Attempts";
const endPointUrl = "/api/attempts";

const AttemptSlice = {
  paginate: ThunkHelper.paginate(slicePrefix, endPointUrl),
};

const filterProps = {
  page: 1,
  per_page: 10,
  username: [],
  taskName: [],
  calling: "",
  called: "",
  attemptStatis: [],
  start: moment().subtract(3, "days").valueOf(),
  end: moment().endOf("day").valueOf(),
};

const AttemptSliceInit = createSlice({
  name: slicePrefix,
  initialState: { ...ThunkHelper.initialState, filterProps },
  reducers: {
    setFilter: ThunkHelper.setInitFilter,
    setReload: ThunkHelper.reloadData,
  },
  extraReducers: {
    [AttemptSlice.paginate.pending]: ThunkHelper.pendingReducer,
    [AttemptSlice.paginate.rejected]: ThunkHelper.rejectReducer,
    [AttemptSlice.paginate.fulfilled]: ThunkHelper.setPaginateReducer,
  },
});

export const { setFilter, setReload } = AttemptSliceInit.actions;
export { AttemptSlice, AttemptSliceInit };
