import React, { useState, useEffect } from "react";
import * as AiIcons from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "../../components/Layouts/shared/Button";
import UserTable from "../../components/Users/userTable";
import { getTypes } from "../../store/actions/types";
import { PageTitle } from "../../components/Layouts/shared/PageTitle";
import { PermissionGate } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";

export const UserPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [values, setValues] = useState({ page: 1, per_page: 10, refresh: 0 });

  useEffect(() => {
    const init = async () => {
      await dispatch(getTypes());
    };
    init();
  }, [dispatch]);

  let refresh = 0;
  const refreshTable = () =>
    setValues({ ...values, refresh: () => refresh + 1 });
  return (
    <>
      <PageTitle location={location}>system Users</PageTitle>
      <div className=" bg-white border  p-3">
        <div className="add-button mt-4">
          <PermissionGate scope={[Scope.CAN_ADD_USERS]}>
            <Button onClick={() => navigate("/security/user/-1")} size="sm">
              <AiIcons.AiOutlineUserAdd size={17} />
              Add User
            </Button>
          </PermissionGate>
        </div>
        <UserTable filter={values} refreshTable={refreshTable} />
      </div>
    </>
  );
};
