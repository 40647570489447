import React from "react";
import Account from "./AccountDetails";
import Setting from "./AccountSetting";
import TerminateAccount from "./TerminateAccount";
import ChangePassword from "./ChangePassword";

export const tabs = [
  {
    name: "Account",
    component: Account,
    icon: <i className="fas fa-user"></i>,
    isDefault: true,
  },
  {
    name: "Change password",
    component: ChangePassword,
    icon: <i className="fas fa-lock"></i>,
  },
  {
    name: "Setting",
    component: Setting,
    icon: <i className="fas fa-user-cog"></i>,
  },
  {
    name: "Terminate Account",
    component: TerminateAccount,
    icon: <i className="fas fa-user-slash"></i>,
  },
];

export const defaultTab = tabs.find((tab) => tab.isDefault);
