import React from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import { showDeleteConfirm } from "../Layouts/shared/Modals";
import { PermissionGate } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";
import * as MdIcons from "react-icons/md";
import * as GiIcons from "react-icons/gi";
import { FileSlice } from "../../store/actions/files";

export const ActionButtons = ({ row }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const updatePublished = async () => {
    const values = {
      published: !row.published,
    };
    const res = await dispatch(FileSlice.update({ id: row.id, data: values }));
    if (!res.error) {
      addToast("Updated Success", {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      addToast(res.payload.message, { appearance: "error", autoDismiss: true });
    }
  };

  const deleteApk = async () => {
    const res = await dispatch(FileSlice.delete({ id: row.id }));
    if (!res.error) {
      addToast("File has been deleted", {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      addToast(res.payload.message, { appearance: "error", autoDismiss: true });
    }
  };

  const deleteHandler = () => showDeleteConfirm("APK", deleteApk);

  return (
    <div>
      <PermissionGate scope={[Scope.CAN_PUBLISH_MOBILE_APP]}>
        <span
          role="button"
          className="action-button m-1"
          onClick={updatePublished}>
          {row.published ? (
            <GiIcons.GiSightDisabled size={25} color="orange" />
          ) : (
            <MdIcons.MdCloudUpload size={25} color="green" />
          )}
        </span>
      </PermissionGate>
      <PermissionGate scope={[Scope.CAN_DELETE_MOBILE_APP]}>
        <span
          role="button"
          className=" action-button m-1"
          onClick={deleteHandler}>
          <i className="far fa-trash-alt fa-lg text-danger"></i>
        </span>
      </PermissionGate>
    </div>
  );
};
