import React, { Fragment, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Options } from '../Layouts/shared/DropdownOptionsMenu';
import { ThreeDotIcon } from '../../assets/icons/icon';
import { isActive } from '../../constants/status';
import { Button } from '../Layouts/shared/Button';
import FormikControl from '../Layouts/shared/Formik/FormikControl';
import { PropSlice } from '../../store/actions/props';
import { PermissionGate } from '../../utils/auth/PermissionGate';
import { Scope } from '../../constants/modules';
import { Modal } from 'antd';
import { showDeleteConfirm, info } from '../Layouts/shared/Modals';

export const ActionButtons = ({ row }) => {
  const { id, isActive: status, client, sim1, sim2, username } = row;
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const { types } = useSelector((state) => state.entities.types);
  const { isLoading } = useSelector((state) => state.entities.props);

  const [showRemoveClient, setShowRemoveClient] = useState(false);
  const [showEditClient, setShowEditClient] = useState(false);
  const [showEditNumber, setShowEditNumber] = useState(false);

  const updatePropStatus = async () => {
    if (!client || status.isActive) {
      info('Assign prop to a Client First !');
    } else {
      const { error } = await dispatch(PropSlice.update({ id, data: { isActive: !status } }));
      if (!error) {
        addToast('Updated successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
      } else {
        addToast(error.message, { appearance: 'error', autoDismiss: true });
      }
    }
  };

  const removeProp = async () => {
    const { error } = await dispatch(PropSlice.delete({ id }));
    if (!error) {
      addToast('Removed Successfully', {
        appearance: 'warning',
        autoDismiss: true,
      });
    } else {
      addToast(error.message, { appearance: 'error', autoDismiss: true });
    }
  };

  const removeClient = async () => {
    const values = { client: null };
    const { error } = await dispatch(PropSlice.update({ id, data: values }));
    if (!error) {
      addToast('Client has been Removed!', {
        appearance: 'warning',
        autoDismiss: true,
      });
    } else {
      addToast(error.message, { appearance: 'error', autoDismiss: true });
    }
    removeClientHandler();
  };

  const handleOnSubmit = async (values, { setSubmitting }) => {
    if (setSubmitting) {
      const res = await dispatch(PropSlice.update({ id, data: values }));
      if (!res.error) {
        addToast('Updated successfully', {
          appearance: 'success',
          autoDismiss: true,
        });
        values.client ? editClientHandler() : editNumberHandler();
      } else {
        addToast(res.payload.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
    setSubmitting(false);
  };

  let submitMyForm = null;

  const handleSubmitForm = (e) => {
    if (submitMyForm) submitMyForm(e);
  };

  const bindSubmitForm = (submitForm) => {
    submitMyForm = submitForm;
  };

  const removePropHandler = () => showDeleteConfirm(row.username, removeProp);
  const removeClientHandler = () => setShowRemoveClient(!showRemoveClient);
  const editClientHandler = () => setShowEditClient(!showEditClient);
  const editNumberHandler = () => setShowEditNumber(!showEditNumber);

  return (
    <Fragment>
      {isLoading ? (
        <span className='dots-loading'>{ThreeDotIcon}</span>
      ) : (
        <Options
          arrow={false}
          buttonTitle={ThreeDotIcon}
          menuItems={
            <div>
              <PermissionGate scope={[Scope.CAN_EDIT_PROP_NO]}>
                <button className='' onClick={editNumberHandler}>
                  <i className='far fa-edit m-1'></i>
                  Edit Number
                </button>
              </PermissionGate>
              <PermissionGate scope={[Scope.CAN_EDIT_PROP_CLIENT]}>
                <button className='' onClick={editClientHandler}>
                  <i className='far fa-edit m-1'></i>
                  Edit client
                </button>
              </PermissionGate>
              <PermissionGate scope={[Scope.CAN_DELETE_CLIENT]}>
                <button className='' onClick={removeClientHandler}>
                  <i className='far fa-minus-square m-1'></i>
                  Remove client
                </button>
              </PermissionGate>
              <PermissionGate scope={[Scope.CAN_DEACTIVE_PROP]}>
                {isActive(status) && (
                  <button className='text-danger' onClick={updatePropStatus}>
                    <i className='far fa-times-circle m-1'></i>
                    Deactivate
                  </button>
                )}
                {!isActive(status) && (
                  <button className='text-success' onClick={updatePropStatus}>
                    <i className='far fa-check-circle m-1'></i>
                    Active
                  </button>
                )}
              </PermissionGate>
              <PermissionGate scope={[Scope.CAN_DELETE_PROP]}>
                <button className='text-danger' onClick={removePropHandler}>
                  <i className='far fa-trash-alt m-1'></i>
                  Delete
                </button>
              </PermissionGate>
            </div>
          }
        />
      )}
      <Modal
        visible={showRemoveClient}
        onCancel={removeClientHandler}
        title='Confirmation !'
        onOk={client ? removeClient : setShowRemoveClient}>
        {client
          ? `You Are Going to Remove Client: ${client} from ${username}, Are you Sure to Proceed ?`
          : `There is no client to removed!`}
      </Modal>
      <Modal visible={showEditClient} onCancel={editClientHandler} onOk={handleSubmitForm} title='Client Assignment'>
        <Formik initialValues={{ client: '' }} onSubmit={handleOnSubmit}>
          {({ submitForm }) => {
            bindSubmitForm(submitForm);
            return (
              <Form>
                <div className='px-5'>
                  <FormikControl control='select' name='client' options={types.clientProp} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      {true ? (
        <Modal visible={showEditNumber} onCancel={editNumberHandler} title='Edit Number' onOk={handleSubmitForm}>
          <Formik enableReinitialize initialValues={{ sim1, sim2 }} onSubmit={handleOnSubmit}>
            {({ values, isSubmitting, submitForm }) => {
              bindSubmitForm(submitForm);
              return (
                <Form>
                  <label>you are going to change NUMBERS for -{username.toUpperCase()}</label>
                  <FormikControl control='input' type='text' name='sim1' label='SIM-1' placeholder='Enter New Number' />
                  <FormikControl control='input' type='text' name='sim2' label='SIM-2' placeholder='Enter New Number' />
                  <h6 className='pt-3 error'>
                    Note: after this process all incoming call records will use this number
                  </h6>
                </Form>
              );
            }}
          </Formik>
        </Modal>
      ) : (
        <Modal
          visible={showEditNumber}
          footer={[
            <Button key='ok' onClick={editNumberHandler}>
              OK
            </Button>,
          ]}
          title={
            <div>
              <i className='fas fa-exclamation-triangle m-2'></i>
              <span>Attention</span>
            </div>
          }>
          This device has the ability to change numbers automatically, no action required.
        </Modal>
      )}
    </Fragment>
  );
};
