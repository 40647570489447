import { decryptData } from "./crypto";

export const decryptPermissions = () => {
  const decrypt = localStorage.getItem("permissions");

  if (decrypt) {
    const salt = "6d090796-ecdf-11ea-adc1-0242ac120003";
    const permissions = decryptData(decrypt, salt);
    return permissions;
  } else return [];
};
