import React from "react";
import { Field, ErrorMessage } from "formik";
import { TextError } from "./TextError";

export const Checkbox = ({
  label,
  name,
  options,
  className = "form-control border-0",
  ...rest
}) => {
  return (
    <div className={className}>
      <label>{label}</label>
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <div className="form-check" key={option.key}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={option.value}
                  {...field}
                  // onChange={changeHandler}
                  value={option.value}
                  checked={field.value.includes(option.value)}
                />
                <label className="form-check-label" htmlFor={option.value}>
                  {option.key}
                </label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
