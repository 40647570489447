export const modules = {
  DASHBOARD: "dashboard",
  PROP: "prop",
  ATTEMPT: "attempt",
  CONFIGURATION: "configuration",
  SECURITY: "security",
  SETTING: "setting",
  DASHBOARD_VIEW: "CAN_VIEW_DASHBOARD",
  PROP_VIEW: "CAN_VIEW_PROP",
  CDR_VIEW: "CAN_VIEW_CDR",
  TASK_VIEW: "CAN_VIEW_TASK",
  ATTEMPT_VIEW: "CAN_VIEW_ATTEMPTS",
  CLIENT_VIEW: "CAN_VIEW_CLIENT",
  USER_VIEW: "CAN_VIEW_USERS",
  GROUP_VIEW: "CAN_VIEW_GROUP",
  MOBILE_VIEW: "CAN_VIEW_MOBILE",
  SERVICE_VIEW: "CAN_VIEW_SERVICES",
  SYSLOG_VIEW: "CAN_VIEW_SYSLOG",
};

export const Scope = {
  CAN_DEACTIVE_PROP: "CAN_DEACTIVE_PROP",
  CAN_DELETE_PROP: "CAN_DELETE_PROP",
  CAN_VIEW_MAP: "CAN_VIEW_MAP",
  CAN_EDIT_PROP_NO: "CAN_EDIT_PROP_NO",
  CAN_EDIT_PROP_CLIENT: "CAN_EDIT_PROP_CLIENT",
  CAN_DELETE_PROP_CLIENT: "CAN_DELETE_PROP_CLIENT",
  CAN_CREATE_TASK: "CAN_CREATE_TASK",
  CAN_DELETE_TASK: "CAN_DELETE_TASK",
  CAN_EDIT_CLIENT: "CAN_EDIT_CLIENT",
  CAN_ADD_CLIENT: "CAN_ADD_CLIENT",
  CAN_DELETE_CLIENT: "CAN_DELETE_CLIENT",
  CAN_GRANT_USERS: "CAN_GRANT_USERS",
  CAN_EDIT_USERS: "CAN_EDIT_USERS",
  CAN_ACTIVE_USERS: "CAN_ACTIVE_USERS",
  CAN_ADD_USERS: "CAN_ADD_USERS",
  CAN_DELETE_USERS: "CAN_DELETE_USERS",
  CAN_EDIT_GROUP: "CAN_EDIT_GROUP",
  CAN_ADD_GROUP: "CAN_ADD_GROUP",
  CAN_DELETE_GROUP: "CAN_DELETE_GROUP",
  CAN_ADD_MOBILE_APP: "CAN_ADD_MOBILE_APP",
  CAN_PUBLISH_MOBILE_APP: "CAN_PUBLISH_MOBILE_APP",
  CAN_DELETE_MOBILE_APP: "CAN_DELETE_MOBILE_APP",
  CAN_MANAGE_SERVICES: "CAN_MANAGE_SERVICES",
};
