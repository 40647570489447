import React from "react";
import { Formik, Form } from "formik";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import FormikControl from "../../components/Layouts/shared/Formik/FormikControl";
import { Button } from "../../components/Layouts/shared/Button";
import { setFilter } from "../../store/actions/cdr";

export const Filter = ({ values, setValues, reload }) => {
  const dispatch = useDispatch();

  const { types } = useSelector((state) => state.entities.types);
  const { filterProps } = useSelector((state) => state.entities.cdrs);

  const handleOnSubmit = (values, { setSubmitting }) => {
    if (setSubmitting) {
      const data = { ...values };
      data.start = moment(data.start).valueOf();
      data.end = moment(data.end).valueOf();
      dispatch(setFilter(data));
    }
    setSubmitting(false);
  };

  return (
    <Formik initialValues={filterProps} onSubmit={handleOnSubmit}>
      {({ values, isSubmitting }) => (
        <Form>
          <div className="container-cdr">
            <div>
              <FormikControl
                control="input"
                type="text"
                label="Number"
                name="number"
              />
            </div>
            <div>
              <FormikControl
                control="input"
                type="text"
                label="CLI"
                name="cli"
              />
            </div>
            <div>
              <FormikControl
                control="input"
                type="text"
                label="Prop Name"
                name="propName"
              />
            </div>
            <div>
              <FormikControl
                control="date"
                label="From"
                name="start"
                selected={values.start}
                dateFormat="yyyy-MM-dd HH:mm"
                showTimeSelect
              />
            </div>

            <div>
              <FormikControl
                control="date"
                label="To"
                name="end"
                selected={values.end}
                dateFormat="yyyy-MM-dd HH:mm"
                showTimeSelect
              />
            </div>
            <div className="m-select">
              <FormikControl
                control="multiSelect"
                label="Country"
                name="country"
                options={types.countryCdr ? types.countryCdr : []}
              />
            </div>
            <div className="m-select">
              <FormikControl
                control="multiSelect"
                label="Operator"
                name="operator"
                options={types.operatorCdr ? types.operatorCdr : []}
              />
            </div>
            <div className="m-select">
              <FormikControl
                control="multiSelect"
                label="Client"
                name="client"
                options={types.clientCdr ? types.clientCdr : []}
              />
            </div>
            <div className="m-select">
              <FormikControl
                control="multiSelect"
                label="State"
                name="state"
                options={types.callState ? types.callState : []}
              />
            </div>
            <div>
              <FormikControl
                control="multiSelect"
                label="Types"
                name="types"
                options={types.callType ? types.callType : []}
              />
            </div>
          </div>
          <div className="d-flex  justify-content-end pb-2 pt-3 ">
            <div className="m-1">
              <Button
                type="submit"
                title={isSubmitting ? "Loading ..." : "Search"}
              />
            </div>
            <div className="m-1">
              <Button type="reset" title="Reset" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
