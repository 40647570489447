import { createSlice } from "@reduxjs/toolkit";
import ThunkHelper from "../thunkHelper";
import { dynamicSort } from "../../constants/orderFunc";

const slicePrefix = "Permissions";
const endPointUrl = "/api/permissions";

const PermissionSlice = {
  getAll: ThunkHelper.getAll(slicePrefix, endPointUrl, (ThunkApi, data) => {
    var helper = {};
    const newList = data
      .reduce((r, rows) => {
        var key = rows.category;
        if (!helper[key]) {
          helper[key] = Object.assign({}, rows);
          r.push(helper[key]);
        }
        return r;
      }, [])
      .map((row) => {
        const permissions = (row.gurdName = data.filter(
          (f) => f.category === row.category
        ));
        return {
          id: row.id,
          category: row.category,
          permissions,
        };
      });
    return newList.sort(dynamicSort("category"));
  }),
};

const PermissionSliceInit = createSlice({
  name: slicePrefix,
  initialState: ThunkHelper.initialState,
  extraReducers: {
    [PermissionSlice.getAll.pending]: ThunkHelper.pendingReducer,
    [PermissionSlice.getAll.rejected]: ThunkHelper.rejectReducer,
    [PermissionSlice.getAll.fulfilled]: ThunkHelper.setAppendReducer,
  },
});

export { PermissionSlice, PermissionSliceInit };
