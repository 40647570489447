import * as Yup from "yup";
import { PASSWORD_REGEX } from "../constants/core.js";

export const addUserSchema = Yup.object().shape({
  username: Yup.string().email("Invalid email").required("Email is Required"),
  password: Yup.string()
    .min(6, "password is too short")
    .max(50, "password is too long")
    .matches(
      PASSWORD_REGEX,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special Character"
    )
    .optional(),
  confirmPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    ),
  }),
  firstName: Yup.string()
    .min(3, "First Name is too Shrort")
    .required("First Name is Required"),
  lastName: Yup.string()
    .min(3, "Last Name is too Shrort")
    .required("Last Name is Required"),
  groups: Yup.string().required("Group is Required"),
});

export const groupValidation = Yup.object().shape({
  groups: Yup.string().required("Group is Required"),
});
