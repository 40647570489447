import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AttemptTable } from "../../components/Attempt/AttemptTable";
import { PageTitle } from "../../components/Layouts/shared/PageTitle";
import { getTypes } from "../../store/actions/types";
import { Filter } from "../../components/Attempt/Filter";

export const ListAttemptPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(getTypes());
  }, [dispatch]);

  return (
    <>
      <PageTitle location={location}>Attempt List</PageTitle>
      <div className=" bg-white border  p-3">
        <Filter />
      </div>
      <div className="table-text bg-white border rounded-xl p-4 pb-4 my-2">
        <AttemptTable />
      </div>
    </>
  );
};
