import React from "react";
import { FormattedDate } from "../Layouts/shared/FormattedDate";

const AccountDetails = () => {
  const { firstName, lastName, email, createdAt } = JSON.parse(
    localStorage.getItem("userInfo")
  );

  return (
    <div className="profile-personal-info mt-5">
      <div className="row mb-2">
        <div className="col-3">
          <h5 className="f-w-500">
            Name
            <span className="pull-right">:</span>
          </h5>
        </div>
        <div className="col-9">
          <span>{`${firstName} ${lastName}`}</span>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3">
          <h5 className="f-w-500">
            Email
            <span className="pull-right">:</span>
          </h5>
        </div>
        <div className="col-9">
          <span>{email}</span>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-3">
          <h5 className="f-w-500">
            Creation At
            <span className="pull-right">:</span>
          </h5>
        </div>
        <div className="col-9">
          <span>
            <FormattedDate date={createdAt} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
