import React from "react";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";

import FormikControl from "../../components/Layouts/shared/Formik/FormikControl";
import { clientSchema } from "../../schemas/client";
import { TextError } from "../../components/Layouts/shared/Formik/TextError";
import { ClientSlice } from "../../store/actions/clients";

export const AddModal = ({ show, showHandler, row = null }) => {
  const dispatch = useDispatch();

  const { error } = useSelector((state) => state.entities.clients);

  const initialValues = {
    client: row ? row.client : "",
    fullName: row ? row.fullName : "",
    discription: row ? row.discription : "",
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    if (setSubmitting) {
      const res = !row
        ? await dispatch(ClientSlice.add({ data: values }))
        : dispatch(ClientSlice.update({ id: row.id, data: values }));
      if (res.error) {
        setSubmitting(false);
      } else {
        showHandler();
        resetForm();
      }
    }
  };

  let submitMyForm = null;

  const handleSubmitForm = (e) => {
    if (submitMyForm) submitMyForm(e);
  };

  const bindSubmitForm = (submitForm) => {
    submitMyForm = submitForm;
  };

  return (
    <Modal visible={show} onCancel={showHandler} onOk={handleSubmitForm}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={clientSchema}>
        {({ submitForm }) => {
          bindSubmitForm(submitForm);
          return (
            <Form>
              <TextError>{error && error.toUpperCase()}</TextError>
              <div className="p-3">
                <div>
                  <FormikControl
                    control="input"
                    name="client"
                    label="Client Name"
                  />
                </div>
                <div className="pt-3">
                  <FormikControl
                    control="input"
                    name="fullName"
                    label="Full Name"
                  />
                </div>
                <div className="pt-3">
                  <FormikControl
                    control="textarea"
                    name="discription"
                    label="Discription"
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
