import { decryptPermissions } from "./tokenCheck";
export const PermissionGate = ({ scope = [], children }) => {
  const permissions = decryptPermissions();
  const perms = permissions.map((per) => per.gurdName);

  if (perms.some((role) => scope.includes(role))) {
    return <>{children}</>;
  }
  return null;
};

export const hasMany = (scope = []) => {
  const permissions = decryptPermissions();
  const perms = permissions.map((per) => per.gurdName);

  if (perms.some((role) => scope.includes(role))) return false;
  else return true;
};
