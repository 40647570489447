import React, { Fragment, useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";

import { Options } from "../Layouts/shared/DropdownOptionsMenu";
import { ThreeDotIcon } from "../../assets/icons/icon";
import { isActive } from "../../constants/status";
import { showDeleteConfirm } from "../Layouts/shared/Modals";
import { groupValidation } from "../../schemas/addUser";
import FormikControl from "../Layouts/shared/Formik/FormikControl";

import { UserSlice } from "../../store/actions/users";
import { PermissionGate } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";

export const ActionButtons = ({ row, refresh }) => {
  const navigate = useNavigate();
  const { id: userId, active: status } = row;
  const [showGroup, setShowGroup] = useState(false);

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const { isLoading } = useSelector((state) => state.entities.users);
  const user = useSelector((state) =>
    state.entities.users.list.find((user) => user.id === userId)
  );
  const { types } = useSelector((state) => state.entities.types);

  const gotRemove = async () => {
    const res = await dispatch(UserSlice.delete({ id: userId }));
    if (!res.error) {
      addToast("Remove successfully", {
        appearance: "warning",
        autoDismiss: true,
      });
      refresh();
    } else {
      addToast(res.payload.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const updateUserState = async (userId, values) => {
    const res = await dispatch(UserSlice.update({ id: userId, data: values }));
    if (!res.error) {
      addToast("Update User Successfully", {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      addToast(res.payload.message, { appearance: "error", autoDismiss: true });
    }
    refresh();
  };

  const handleOnSubmit = async (values, { setSubmitting }) => {
    if (setSubmitting) {
      const res = await dispatch(
        UserSlice.update({ id: userId, data: values })
      );
      if (!res.error) {
        addToast("Update Successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        addToast(res.payload.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
    refresh();
    setShowGroup(false);
    setSubmitting(false);
  };

  let submitMyForm = null;

  const handleSubmitMyForm = (e) => {
    if (submitMyForm) submitMyForm(e);
  };

  const bindSubmitForm = (submitForm) => {
    submitMyForm = submitForm;
  };

  return (
    <Fragment>
      {isLoading ? (
        <span className="dot-loading">{ThreeDotIcon}</span>
      ) : (
        <Options
          arrow={false}
          buttonTitle={ThreeDotIcon}
          menuItems={
            <div>
              <PermissionGate scope={[Scope.CAN_EDIT_USERS]}>
                <button
                  onClick={() => {
                    navigate(`/security/user/${userId}`);
                  }}>
                  Edit
                </button>
              </PermissionGate>
              <PermissionGate scope={[Scope.CAN_GRANT_USERS]}>
                <button
                  onClick={() => {
                    setShowGroup(true);
                  }}>
                  Group
                </button>
              </PermissionGate>
              <PermissionGate scope={[Scope.CAN_GRANT_USERS]}>
                {isActive(status) && (
                  <button
                    className="text-danger"
                    onClick={() => {
                      updateUserState(userId, { active: false });
                    }}>
                    Deactivate
                  </button>
                )}
                {!isActive(status) && (
                  <button
                    className="text-success"
                    onClick={() => {
                      updateUserState(userId, { active: true });
                    }}>
                    Active
                  </button>
                )}
              </PermissionGate>
              <PermissionGate scope={[Scope.CAN_DELETE_USERS]}>
                <button
                  className="text-danger"
                  onClick={() => showDeleteConfirm(user.username, gotRemove)}>
                  Delete
                </button>
              </PermissionGate>
            </div>
          }
        />
      )}
      <Modal
        title="Group Assignment"
        visible={showGroup}
        onOk={handleSubmitMyForm}
        onCancel={() => setShowGroup(false)}>
        <Formik
          enableReinitialize={true}
          initialValues={{ groups: "" }}
          onSubmit={handleOnSubmit}
          validationSchema={groupValidation}>
          {({ submitForm }) => {
            bindSubmitForm(submitForm);
            return (
              <Form>
                <div className="px-4 ">
                  <FormikControl
                    control="select"
                    label="ASSIGN TO GROUP"
                    name="groups"
                    options={types.roles ? types.roles : []}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </Fragment>
  );
};
