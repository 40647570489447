import React from "react";
import { Table } from "../Layouts/shared/Table-B";
import { FormattedDate } from "../Layouts/shared/FormattedDate";
import { ActionButtons } from "./actions";

import "./index.scss";
import { UserSlice } from "../../store/actions/users";
import { hasMany } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";

const UserTable = ({ filter, refreshTable }) => {
  const sliceName = "users";

  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      isDummyField: true,
      attrs: {
        "data-label": "#",
      },
      formatter: (cell, row, index) => <span>{index + 1}</span>,
    },
    {
      dataField: "username",
      text: "User",
      sort: true,

      style: {
        fontWeight: "bold",
      },
      attrs: {
        "data-label": "User",
      },
    },
    {
      dataField: "Groups",
      text: "Group",
      sort: true,
      attrs: {
        "data-label": "Group",
      },
      formatter: (cell, row) => (
        <div>
          {row.Groups && row.Groups.length === 0 ? (
            <span className="error">Required</span>
          ) : (
            row.Groups && row.Groups[0].name
          )}
        </div>
      ),
    },
    {
      dataField: "firstName",
      text: "First Name",
      sort: true,
      attrs: {
        "data-label": "First Name",
      },
    },
    {
      dataField: "lastName",
      text: "Last Name",
      sort: true,
      attrs: {
        "data-label": "Last Name",
      },
    },
    {
      dataField: "active",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Status",
      },
      formatter: (cell, row) =>
        row.active ? (
          <span className="text-success">Active</span>
        ) : (
          <span className="text-danger">Disable</span>
        ),
    },
    {
      dataField: "lastLogin",
      text: "Last Login",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Last Login",
      },
      formatter: (cell, row) => (
        <FormattedDate date={row.lastLogin && row.lastLogin} />
      ),
    },
    {
      dataField: "createdAt",
      text: "Creation",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Creataion",
      },
      formatter: (cell, row) => <FormattedDate date={cell} />,
    },
    {
      dataField: "id#",
      text: "Actions",
      isDummyField: true,
      headerAlign: "center",

      align: "center",
      attrs: {
        "data-label": "Actions",
      },
      hidden: hasMany([
        Scope.CAN_DELETE_USERS,
        Scope.CAN_GRANT_USERS,
        Scope.CAN_EDIT_USERS,
      ]),
      formatter: (cell, row) => (
        <ActionButtons refresh={refreshTable} row={row} />
      ),
    },
  ];

  return (
    <div className="table-text p-4">
      <Table
        keyField="id"
        columns={columns}
        params={filter}
        sliceName={sliceName}
        sliceReducer={UserSlice}
      />
    </div>
  );
};

export default UserTable;
