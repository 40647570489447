import React, { useState, useEffect } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, FieldArray } from "formik";
import { useSelector, useDispatch } from "react-redux";

import FormikControl from "../../components/Layouts/shared/Formik/FormikControl";
import { Button } from "../../components/Layouts/shared/Button";
import { PageTitle } from "../../components/Layouts/shared/PageTitle";
import { getTypes } from "../../store/actions/types";
import { TaskSlice } from "../../store/actions/tasks";
import ErrorField from "../../components/Layouts/shared/ErrorField";
import { NumbersModal } from "../../components/Task/NumbersModal";
import { PropSlice } from "../../store/actions/props";
import { SelectedTable } from "../../components/Task/SelectedTable";
import { error, success } from "../../components/Layouts/shared/Modals";
import { taskNameSchema } from "../../schemas/schemas";

export const CreateTaskPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { types } = useSelector((state) => state.entities.types);
  const { list: props } = useSelector((state) => state.entities.props);

  const [called, setCalled] = useState([]);
  const [show, setShow] = useState(false);
  const [prop, setProp] = useState();
  const [pdd, setPdd] = useState(5);
  const [call, setCall] = useState(0);
  const [type, setType] = useState("calls");
  const [body, setBody] = useState("");
  const [simsList, setSimsList] = useState([]);
  const [sim, setSim] = useState();
  const [propError, setPropError] = useState();
  const [pddError, setPddError] = useState();
  const [callError, setCallError] = useState();
  const [simError, setSimError] = useState();

  const initialValues = {
    taskName: "",
    attemptStatus: "idle",
    scheduleTime: moment().valueOf(),
    attempts: [],
  };

  const attemptStatusList = [
    { key: "Run Now", value: "idle" },
    { key: "Scheduler", value: "schedule" },
  ];

  const attemptTypeList = [
    { key: "CALL", value: "calls" },
    { key: "SMS", value: "sms" },
  ];

  useEffect(() => {
    dispatch(getTypes());
    dispatch(PropSlice.paginate({ params: { page: 1, per_page: 100 } }));
  }, [dispatch]);

  const handleAdd = (data, helper, values, edit = false) => {
    const { attempts } = values;

    const existPropCheck = attempts.find(
      (propd) => propd.username === prop && propd.sim === sim && propd.type === type
    );

    if (existPropCheck) {
      existPropCheck.called = !edit ? [...existPropCheck.called, ...data] : data;
    } else {
      let attemptItem = {};
      const exactProp = props.find((el) => el.username === prop);

      attemptItem.username = exactProp.username;
      attemptItem.propId = exactProp.id;
      attemptItem.calling = exactProp[sim];
      attemptItem.pdd = pdd;
      attemptItem.call = call;
      attemptItem.body = body;
      attemptItem.sim = sim;
      attemptItem.type = type;
      attemptItem.called = data;
      helper.push(attemptItem);
    }

    setProp();
    setSimError();
    setSim();
  };

  const handleChange = (event) => {
    setProp(event);
    setPropError();
  };

  const handleSelect = (e) => {
    const sims = types.onlineProps.find((pro) => pro.value === e);
    setSimsList(sims.sims);
  };

  const handleChangeSim = (e) => setSim(e);

  const handleChangePdd = (event) => {
    setPdd(event.currentTarget.value);
    setPddError();
  };

  const handleChangeCall = (event) => {
    setCall(event.currentTarget.value);
    setCallError();
  };

  const handleChangeBody = (event) => {
    setBody(event.currentTarget.value);
  };

  const handleChangeType = (value) => {
    setType(value);
    setPddError();
  };

  const sendHandler = async (values) => {
    const res =
      values.attemptStatus === "idle" &&
      (await dispatch(TaskSlice.send({ data: { taskName: values.taskName, attemptStatus: values.attemptStatus } })));
    if (!res.error) navigate("/attempt/task");
    else error(res.payload.message);
  };

  const handleSubmit = async (values) => {
    const res = await dispatch(TaskSlice.add({ data: values }));
    if (!res.errror)
      success("Done", `Task will run at - ${moment(values.scheduleTime).format("yyyy/MM/DD HH:mm:ss")}`, () =>
        sendHandler(values)
      );
  };

  const showNumberModal = (row, newOne = true) => {
    if (newOne) {
      setCalled();
      // setProp();
      // setPdd(5);
      // setSim();
    } else {
      setCalled(row.called);
      setProp(row.username);
      setPdd(row.pdd);
      setSim(row.sim);
    }

    if (!prop && !row.called) setPropError("A-Party is required");
    else if (pdd < 0 && !row.called) setPddError("PDD should be negative number");
    else if (!sim && !row.called) setSimError("Select Sim");
    else {
      setShow(true);
      setSimError();
    }
  };

  return (
    <React.Fragment>
      <PageTitle location={location}>Generate Calls & SMS</PageTitle>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={taskNameSchema}>
        {({ values }) => (
          <Form>
            <div className=" bg-white border p-3 pb-3">
              <h6 className="border-bottom">Task Details</h6>
              <p className="ms-3 px-3 text-secondary">
                Here is the start point of call generator, Provide name for task that you are going to create and
                scheduler time for call in order to use it as a reference into system then proceed to next step.
              </p>
              <div className=" container-cdr pb-4">
                <div className="m-1">
                  <FormikControl control="input" type="text" name="taskName" label="Task Name" />
                </div>
                <div className="m-1">
                  <FormikControl
                    control="select"
                    defaultValue="idle"
                    options={attemptStatusList}
                    name="attemptStatus"
                    label="Timing"
                  />
                </div>

                <div className="m-1">
                  <FormikControl
                    disabled={values.attemptStatus === "idle"}
                    control="date"
                    label="Schdule Time"
                    name="scheduleTime"
                    selected={values.scheduleTime}
                    dateFormat="yyyy-MM-dd HH:mm"
                    showTimeSelect
                  />
                </div>
              </div>
            </div>
            <FieldArray name="attempts">
              {(arrayHelpers) => (
                <>
                  <div className=" bg-white border my-3 p-3">
                    <h6 className="border-bottom">Call Details</h6>
                    <p className="ms-3 px-3 text-secondary">
                      Spacify props that need to generate call from, as well as sim slot and make sure to check prop
                      availability, and PDD for selected prop with B-party number.
                    </p>

                    <div className="container-cdr pb-4">
                      <div className="m-1 ">
                        <FormikControl
                          control="select"
                          options={types.onlineProps ? types.onlineProps : []}
                          name="prop"
                          value={prop}
                          label="A-Party"
                          onChange={handleChange}
                          onSelect={handleSelect}
                        />
                        <ErrorField message={propError} />
                      </div>
                      <div className="m-1 ">
                        <FormikControl
                          control="select"
                          options={simsList}
                          name="sim"
                          value={sim}
                          label="SIM"
                          placeholder="Select Sim"
                          onChange={handleChangeSim}
                        />
                        <ErrorField message={simError} />
                      </div>
                      <div className="m-1 ">
                        <FormikControl
                          control="select"
                          defaultValue="calls"
                          options={attemptTypeList}
                          name="type"
                          label="Type"
                          onChange={handleChangeType}
                        />
                        <ErrorField message={simError} />
                      </div>
                      <div className="m-1 ">
                        <FormikControl
                          control="input"
                          type="number"
                          name="pdd"
                          label="PDD"
                          disabled={type !== "calls"}
                          defaultValue={5}
                          onChange={handleChangePdd}
                        />
                        <ErrorField message={pddError} />
                      </div>
                      <div className="m-1 ">
                        <FormikControl
                          control="input"
                          type="number"
                          name="call"
                          label="Call Time"
                          disabled={type !== "calls"}
                          defaultValue={0}
                          onChange={handleChangeCall}
                        />
                        <ErrorField message={pddError} />
                      </div>
                    </div>
                      <div className="m-1 row">
                        <div className="col-6">
                        <FormikControl
                          control="textarea"
                          type="text"
                          name="body"
                          rows={5}
                          label="Message Body"
                          disabled={type !== "sms"}
                          defaultValue=''
                          placeholder="Try to write instance message in sms case generated."
                          onChange={handleChangeBody}
                        />
                        <ErrorField message={pddError} />
                      </div>
                        </div>
                    <div className="m-1 pt-4 mt-2 ms-5">
                      <Button className="bg-dark" type="button" onClick={() => showNumberModal()}>
                        <i className="far fa-plus-square fa-lg"></i>
                        <span className="ms-2">Add B-Numbers</span>
                      </Button>
                    </div>
                    <NumbersModal
                      show={show}
                      setShow={setShow}
                      addHandler={handleAdd}
                      helper={arrayHelpers}
                      values={values}
                      called={called}
                    />
                  </div>
                  <div className=" bg-white border my-1 p-3 pb-3">
                    <h6 className="border-bottom">Calls Ready to Send</h6>
                    <SelectedTable
                      arrayHelpers={arrayHelpers}
                      data={values.attempts}
                      showNumberModal={showNumberModal}
                    />
                  </div>
                </>
              )}
            </FieldArray>
            <div className="d-flex">
              <div className="m-1">
                <Button className="custom-button reset" type="button" onClick={() => navigate("/attempt/task")}>
                  Cancel
                </Button>
              </div>
              <div className="m-1">
                <Button type="submit">Submit</Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
