import React, { Fragment, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";

import { ClientSlice } from "../../store/actions/clients";
import { PermissionGate } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";
import { AddModal } from "./AddModal";
import { showDeleteConfirm } from "../Layouts/shared/Modals";

export const ActionButtons = ({ row }) => {
  const { id } = row;
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const [showEditModal, setShowEditModal] = useState(false);

  const deleteClient = async () => {
    const res = await dispatch(ClientSlice.delete({ id }));
    if (res.error) {
      addToast(res.payload.message, {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      addToast("Remove is done", {
        appearance: "success",
        autoDismiss: true,
      });
    }
  };

  const showEditHandler = () => setShowEditModal(!showEditModal);
  const deleteHandler = () => showDeleteConfirm(row.client, deleteClient);

  return (
    <Fragment>
      <PermissionGate scope={[Scope.CAN_EDIT_CLIENT]}>
        <span className="text-success" role="button" onClick={showEditHandler}>
          <i className="far fa-edit "></i>
        </span>
      </PermissionGate>
      <PermissionGate scope={[Scope.CAN_DELETE_CLIENT]}>
        <span
          className="text-danger ms-3"
          role="button"
          onClick={deleteHandler}>
          <i className="far fa-trash-alt "></i>
        </span>
      </PermissionGate>
      <AddModal show={showEditModal} showHandler={showEditHandler} row={row} />
    </Fragment>
  );
};
