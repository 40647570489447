import { defaultRoutes } from "./defualt";
import { dashboard } from "./dashboard";
import { props } from "./props";
import { settings } from "./settings";
import { configuration } from "./configuration";
import { attempt } from "./attempt";
import { security } from "./security";
import { Navigate } from "react-router-dom";

const routes = [
  ...defaultRoutes,
  ...attempt,
  ...dashboard,
  ...props,
  ...settings,
  ...configuration,
  ...security,
  {
    path: "/*",
    element: () => <Navigate to="/dashboard" replace />,
    isPrivate: true,
  },
];

export default routes;
