import React from "react";
import DateView from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field, ErrorMessage } from "formik";
import { TextError } from "./TextError";

export const DatePicker = ({
  label,
  name,
  className = "form-control form-control-sm",
  ...rest
}) => {
  return (
    <div>
      <label className="form-label pt-2 mb-1" htmlFor={name}>
        {label}
      </label>
      <Field name={name}>
        {({ field, form }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <DateView
              id={name}
              className={className}
              {...field}
              {...rest}
              selecte={value}
              onChange={(val) => setFieldValue(name, val)}
            />
          );
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
