import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import { TextError } from "../../components/Layouts/shared/Formik/TextError";
import { PermissionSlice } from "../../store/actions/permissions";
import { GroupSlice } from "../../store/actions/groups";
import FormikControl from "../Layouts/shared/Formik/FormikControl";
import { Button } from "../Layouts/shared/Button";

export const GroupEdit = () => {
  const { id } = useParams();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToGroupList = () => navigate("/security/group");

  const { list } = useSelector((state) => state.entities.permissions);
  const { object: group, error } = useSelector(
    (state) => state.entities.groups
  );
  const [selected, setSelected] = useState();

  useEffect(() => {
    const initPermissions = async () => {
      await dispatch(PermissionSlice.getAll());
      await dispatch(GroupSlice.get({ id }));
    };
    initPermissions();
  }, [dispatch, id]);

  const initValues =
    id !== "-1"
      ? {
          name: group.name ? group.name : "",
          active: group.active ? group.active : false,
          description: group.description ? group.description : "",
          permissions: group.Permissions
            ? group.Permissions.map((el) => el.gurdName)
            : [],
        }
      : {
          name: "",
          active: false,
          description: "",
          permissions: [],
        };

  const handleSelect = (index) => {
    setSelected(list[index]);
  };

  const numberCurrentPer = (permissions, selected) => {
    return permissions.filter((per) => selected.includes(per.gurdName)).length;
  };

  const checked = (item, permissions) => {
    return permissions.some((perm) => perm === item.gurdName);
  };

  const handleOnSubmit = async (values, { setSubmitting }) => {
    if (setSubmitting) {
      if (id === "-1") {
        const res = await dispatch(GroupSlice.add({ data: values }));
        if (res.error)
          addToast(res.payload.message, {
            appearance: "error",
            autoDismiss: true,
          });
        else {
          addToast("Successfully!", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate("/security/group");
        }
      } else {
        const res = await dispatch(
          GroupSlice.updatePermission({ id, data: values })
        );
        if (res.error)
          addToast(res.payload.message, {
            appearance: "error",
            autoDismiss: true,
          });
        else {
          addToast("Successfully!", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate("/security/group");
        }
      }
    }
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initValues}
        onSubmit={handleOnSubmit}>
        {({ values, dirty, isSubmitting }) => (
          <Form className="">
            <div className=" bg-white border p-3">
              <div className="fw-bold fs-6 border-bottom">Group Detials</div>

              {error && <TextError> {error}</TextError>}
              <div className=" col-md-4 p-2">
                <div className=" pt-2 ">
                  <FormikControl
                    control="textarea"
                    label="Description"
                    name="description"
                  />
                  <FormikControl
                    control="input"
                    label="Group Name"
                    name="name"
                  />
                </div>
              </div>
              <div className="m-2 ms-4">
                <FormikControl
                  control="checkboxs"
                  type="checkbox"
                  label={
                    !values.active ? (
                      <span className="text-danger fw-bold">Inactive</span>
                    ) : (
                      <span className="text-success fw-bold">Active</span>
                    )
                  }
                  className="p-2"
                  name="active"
                />
              </div>
              <div className="d-flex justify-content-start pt-2 ms-2">
                <div className="m-1">
                  <Button type="submit" disabled={!dirty}>
                    {isSubmitting
                      ? "Loading..."
                      : id !== "-1"
                      ? "update"
                      : "submit"}
                  </Button>
                </div>
                <div className="m-1">
                  <Button
                    type="button"
                    onClick={goToGroupList}
                    className="bg-danger">
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
            <div className="bg-white border p-4 mt-3">
              <div className="pb-3"></div>
              <div className="row">
                <div className="col-md-4 pb-4">
                  <div className="fw-bold fs-6 pb-2 ">Modules</div>
                  <ul className="list-group" id="list-tab" role="tablist">
                    {list.map((el, index) => (
                      <li
                        key={index}
                        onClick={() => handleSelect(index)}
                        className={`list-group-item list-group-item-action  ${
                          selected && selected.category === el.category
                            ? "active"
                            : ""
                        }`}
                        id="list-home-list"
                        data-bs-toggle="list"
                        href="#list-home"
                        role="tab"
                        aria-controls="list-home">
                        <div className="d-flex justify-content-between">
                          {el.category.toUpperCase()} - ({el.permissions.length}
                          )
                          <span className="badge bg-success rounded-pill">
                            {numberCurrentPer(
                              el.permissions,
                              values.permissions
                            )}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-md-8">
                  {selected && (
                    <div className="fw-bold fs-6 pb-2 ">Permissions</div>
                  )}
                  {selected &&
                    selected.permissions.map((el, index) => (
                      <ul key={index} className="list-group">
                        <li className="list-group-item p-1">
                          <FormikControl
                            control="checkboxs"
                            type="checkbox"
                            value={el.gurdName}
                            className="form-check-input me-1"
                            name="permissions"
                            label={
                              <>
                                <span className="fw-bold">{el.gurdName}</span> -
                                <span className="ms-2 text-success">
                                  {checked(el, values.permissions) &&
                                    el.description}
                                </span>
                              </>
                            }
                          />
                        </li>
                      </ul>
                    ))}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
