import { createSlice } from "@reduxjs/toolkit";
import ThunkHelper from "../thunkHelper";

const slicePrefix = "Files";
const endPointUrl = "/api/files";

const FileSlice = {
  paginate: ThunkHelper.paginate(slicePrefix, endPointUrl),
  update: ThunkHelper.put(slicePrefix, endPointUrl),
  add: ThunkHelper.post(slicePrefix, endPointUrl),
  delete: ThunkHelper.delete(slicePrefix, endPointUrl),
};

const FileSliceInit = createSlice({
  name: slicePrefix,
  initialState: ThunkHelper.initialState,
  reducers: {
    setReload: ThunkHelper.reloadData,
  },
  extraReducers: {
    [FileSlice.add.pending]: ThunkHelper.pendingReducer,
    [FileSlice.add.rejected]: ThunkHelper.rejectReducer,
    [FileSlice.add.fulfilled]: ThunkHelper.setAddObjReducer,
    [FileSlice.paginate.pending]: ThunkHelper.pendingReducer,
    [FileSlice.paginate.rejected]: ThunkHelper.rejectReducer,
    [FileSlice.paginate.fulfilled]: ThunkHelper.setPaginateReducer,
    [FileSlice.update.pending]: ThunkHelper.pendingReducer,
    [FileSlice.update.rejected]: ThunkHelper.rejectReducer,
    [FileSlice.update.fulfilled]: ThunkHelper.setUpdateReducer,
    [FileSlice.delete.pending]: ThunkHelper.pendingReducer,
    [FileSlice.delete.rejected]: ThunkHelper.rejectReducer,
    [FileSlice.delete.fulfilled]: ThunkHelper.setDeleteReducer,
  },
});

export const { setReload } = FileSliceInit.actions;
export { FileSlice, FileSliceInit };
