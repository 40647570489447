import React from "react";
import { Formik, Form } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import FormikControl from "../../components/Layouts/shared/Formik/FormikControl";
import { Button } from "../../components/Layouts/shared/Button";
import { setFilter } from "../../store/actions/sysLogs";

export const Filter = () => {
  const dispatch = useDispatch();

  const { filterProps } = useSelector((state) => state.entities.sysLogs);

  const options = [
    { value: "info", key: "INFO" },
    { value: "warning", key: "WARNING" },
    { value: "error", key: "ERROR" },
  ];

  const handleOnSubmit = (values, { setSubmitting }) => {
    if (setSubmitting) {
      const data = { ...values };
      data.start = moment(data.start).valueOf();
      data.end = moment(data.end).valueOf();
      dispatch(setFilter(data));
    }
    setSubmitting(false);
  };

  return (
    <Formik initialValues={filterProps} onSubmit={handleOnSubmit}>
      {({ values, isSubmitting }) => (
        <Form>
          <div className="container-logs">
            <div>
              <FormikControl
                control="input"
                name="prop"
                label="Meta"
                placeholder="..."
                className="form-control form-control-sm"
              />
            </div>
            <div>
              <FormikControl
                control="multiSelect"
                name="status"
                label="Level"
                options={options}
              />
            </div>
            <div>
              <FormikControl
                control="date"
                label="From"
                name="start"
                selected={values.start}
                dateFormat="yyyy-MM-dd HH:mm"
                showTimeSelect
              />
            </div>
            <div>
              <FormikControl
                control="date"
                label="To"
                name="end"
                selected={values.end}
                dateFormat="yyyy-MM-dd HH:mm"
                showTimeSelect
              />
            </div>
          </div>
          <div className="d-flex justify-content-end p-2">
            <div className="m-1">
              <Button
                type="submit"
                className="custom-button submit"
                title={isSubmitting ? "Loading ..." : "Search"}
              />
            </div>

            <div className="m-1">
              <Button
                type="reset"
                className="custom-button reset"
                title="Reset"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
