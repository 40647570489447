import React from "react";
import * as MdIcons from "react-icons/md";

import { Button } from "../../components/Layouts/shared/Button";
import { PermissionGate } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";

export const AddButton = ({ showHandler }) => {
  return (
    <div className="d-flex  justify-content-end p-2">
      <PermissionGate scope={[Scope.CAN_ADD_CLIENT]}>
        <div className="m-1">
          <Button onClick={showHandler}>
            <MdIcons.MdAddCircle size={20} />
            <span className="ms-2">ADD CLIENT</span>
          </Button>
        </div>
      </PermissionGate>
    </div>
  );
};
