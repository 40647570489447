import { ListAttemptPage, TaskPage, CreateTaskPage } from "../../pages";
import { modules, Scope } from "../../constants/modules";

export const attempt = [
  {
    path: "/attempt/create",
    element: CreateTaskPage,
    isPrivate: true,
    gurd: Scope.CAN_CREATE_TASK,
    module: modules.ATTEMPT,
  },
  {
    path: "/attempt/task",
    element: TaskPage,
    isPrivate: true,
    gurd: modules.TASK_VIEW,
    module: modules.ATTEMPT,
  },
  {
    path: "/attempt/attempt",
    element: ListAttemptPage,
    isPrivate: true,
    gurd: modules.ATTEMPT_VIEW,
    module: modules.ATTEMPT,
  },
];
