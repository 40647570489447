import React, { Fragment } from "react";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { GroupSlice } from "../../store/actions/groups";
import { PermissionGate } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";
import { showDeleteConfirm } from "../Layouts/shared/Modals";

export const ActionButtons = ({ row }) => {
  const { id } = row;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { addToast } = useToasts();

  const deleteGroup = () => {
    const res = dispatch(GroupSlice.delete({ id }));
    if (!res.error) {
      addToast("Romoved successfully!", {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      addToast(res.paload.message, { appearance: "error", autoDismiss: true });
    }
  };

  const goToEditGroup = () => navigate(`/security/group/${id}`);
  const deleteHandler = () => showDeleteConfirm(row.name, deleteGroup);

  return (
    <Fragment>
      <PermissionGate scope={[Scope.CAN_EDIT_GROUP]}>
        <span className="text-success" role="button" onClick={goToEditGroup}>
          <i className="far fa-edit "></i>
        </span>
      </PermissionGate>
      <PermissionGate scope={[Scope.CAN_DELETE_GROUP]}>
        <span
          className="text-danger ms-3"
          role="button"
          onClick={deleteHandler}>
          <i className="far fa-trash-alt "></i>
        </span>
      </PermissionGate>
    </Fragment>
  );
};
