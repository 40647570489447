import React from "react";
import {Field, ErrorMessage} from "formik";
import {TextError} from "./TextError";

export const Textarea = ({label, name, ...rest}) => {
  return (
    <div>
      <label className="form-label" htmlFor={name}>
        {label}
      </label>
      <Field
        className="form-control"
        as="textarea"
        id={name}
        name={name}
        {...rest}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
