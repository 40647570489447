import React from "react";
import {FormattedDate} from "../../../Layouts/shared/FormattedDate";
import "./index.scss";

const Info = ({propInfo, statistics}) => {
  const deviceInfo = [
    {
      title: "Brand",
      value: propInfo && propInfo.brand,
    },
    {
      title: "Model",
      value: propInfo && propInfo.model,
    },
    {
      title: "IMEI",
      value: propInfo && propInfo.imei,
    },
    {
      title: "Operator",
      value: propInfo && propInfo.operatorName,
    },
    {
      title: "SIM Number",
      value: propInfo && propInfo.phoneNumber,
    },
    {
      title: "Last Seen",
      value: propInfo && <FormattedDate date={propInfo.time} />,
    },
  ];

  const deviceState = [
    {
      title: "User",
      value: propInfo && propInfo.username && (
        <span className="device">{propInfo.username.toUpperCase()}</span>
      ),
    },
    {
      title: "Mode",
      value:
        propInfo && propInfo.isActive ? (
          <div className="on">Active</div>
        ) : (
          <div className="off">Inactive</div>
        ),
    },
    {
      title: "Status",
      value:
        propInfo && propInfo.status ? (
          <div className="on">Online</div>
        ) : (
          <div className="off">Offline</div>
        ),
    },
  ];

  const {overall, incoming, outgoing, sms_In, sms_Out, viber_In} = statistics;
  const dataStatistics = [
    {
      title: "Total Records",
      value: overall ? overall : 0,
      icon: <i className="bi bi-telephone-fill pr-2"></i>,
    },
    {
      title: "IN calls",
      value: incoming ? incoming : 0,
      icon: <i className="bi bi-telephone-inbound-fill pr-2"></i>,
    },
    {
      title: "OUT calls",
      value: outgoing ? outgoing : 0,
      icon: <i className="bi bi-telephone-forward-fill pr-2"></i>,
    },
    {
      title: "SMS IN",
      value: sms_In ? sms_In : 0,
      icon: <i className="bi bi-envelope-fill pr-2"></i>,
    },
    {
      title: "SMS OUT",
      value: sms_Out ? sms_Out : 0,
      icon: <i className="bi bi-envelope-fill pr-2"></i>,
    },
    {
      title: "Viber In",
      value: viber_In ? viber_In : 0,
      icon: <i className="bi bi-whatsapp pr-2"></i>,
    },
  ];

  return (
    <div className="row mb-2">
      <div className="col-xl-6 border-right">
        <div className="profile-personal-info">
          <h4>Device Status</h4>
          {deviceState &&
            deviceState.map((info, index) => (
              <div key={index} className="row mb-2">
                <div className="col-5">
                  <h5 className="f-w-500">
                    {info.title}
                    <span className="pull-right">:</span>
                  </h5>
                </div>
                <div className="col-5">
                  <span>{info.value}</span>
                </div>
              </div>
            ))}
        </div>
        <div className="profile-personal-info border-top pt-4">
          {/* <h4 className='text-primary mb-5 pt-5'>Device Data Statistics</h4> */}
          {dataStatistics &&
            dataStatistics.map((info, index) => (
              <div key={index} className="row mb-1">
                <div className="col-5">
                  <h5 className="f-w-500">
                    {info.icon}
                    {info.title}
                    <span className="pull-right">:</span>
                  </h5>
                </div>
                <div className="col-5">
                  <span>{info.value}</span>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="col-xl-6  ">
        <div className="profile-personal-info pl-4">
          <h4>Device Information</h4>
          {deviceInfo &&
            deviceInfo.map((info, index) => (
              <div key={index} className="row mb-2">
                <div className="col-7">
                  <h5 className="f-w-500">
                    {info.title}
                    <span className="pull-right">:</span>
                  </h5>
                </div>
                <div className="col-5">
                  <span>{info.value}</span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Info;
