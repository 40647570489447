import { useField } from "formik";
import { Select } from "antd";

export const Multi = ({
  options,
  value,
  name,
  placeholder = "select...",
  label,
}) => {
  /*eslint-disable */
  const [field, state, { setValue, setTouched }] = useField(name);

  const onChange = (value) => {
    setValue(value);
  };

  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    value,
    options,
    onChange,
    placeholder,
    maxTagCount: "responsive",
  };

  return (
    <div style={{ height: "2rem" }}>
      <label className="form-label  pt-2 mb-1" htmlFor={name}>
        {label}
      </label>
      <Select showArrow {...selectProps} />
    </div>
  );
};
