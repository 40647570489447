import { Modal } from "antd";

import { ExclamationCircleOutlined } from "@ant-design/icons";

export const info = (title, content, ...rest) => {
  Modal.info({
    title,
    content,
    ...rest,
  });
};

export const success = (title, content, onOk, ...rest) => {
  Modal.success({
    title,
    content,
    onOk,
    ...rest,
  });
};

export const error = (title, content, ...rest) => {
  Modal.error({
    title,
    content,
    ...rest,
  });
};

export const warning = (title, content, ...rest) => {
  Modal.warning({
    title,
    content,
    ...rest,
  });
};

export const showConfirm = (title, content, onOk, onCancel, ...rest) => {
  const { confirm } = Modal;
  confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    onOk,
    onCancel,
    ...rest,
  });
};

export const showDeleteConfirm = (item, onOk, content, ...rest) => {
  const { confirm } = Modal;
  confirm({
    title: `Are you sure delete this ${item}?`,
    icon: <ExclamationCircleOutlined />,
    content,
    ...rest,
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk,
    onCancel() {},
  });
};
