import Records from "./Records";
import Info from "./Info";

export const tabs = [
  {
    name: "Prop Info",
    component: Info,
    icon: <i className="fas fa-info-circle"></i>,
    isDefault: true,
  },
  {
    name: "CDRs",
    icon: <i className="fas fa-database"></i>,
    component: Records,
  },
];

export const defaultTab = tabs.find((tab) => tab.isDefault);
