import React from "react";
import Header from "./Header";
import Sidebar from "./SideBar";
import { useSelector } from "react-redux";

export const Layout = ({ children }) => {
  const { sidebarState } = useSelector((state) => state.ui.control);

  const sidebar = sidebarState
    ? "layout__content-main active"
    : " layout__content-main";

  return (
    <div className="layout">
      <Header />
      <Sidebar />
      <div className="layout__content">
        <div className={sidebar}>{children}</div>
      </div>
    </div>
  );
};
