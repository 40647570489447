import { UserPage, GroupPage, GroupEditPage, AddUser } from "../../pages";
import { modules } from "../../constants/modules";

export const security = [
  {
    path: "/security/user",
    element: UserPage,
    isPrivate: true,
    gurd: modules.USER_VIEW,
    module: modules.SECURITY,
  },
  {
    path: "/security/group",
    element: GroupPage,
    isPrivate: true,
    gurd: modules.GROUP_VIEW,
    module: modules.SECURITY,
  },
  {
    path: "/security/group/:id",
    element: GroupEditPage,
    isPrivate: true,
    gurd: modules.GROUP_VIEW,
    module: modules.SECURITY,
  },
  {
    path: "/security/user/:id",
    element: AddUser,
    isPrivate: true,
    gurd: modules.USER_VIEW,
    module: modules.SECURITY,
  },
];
