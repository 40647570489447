import React from "react";
import { useNavigate } from "react-router-dom";

export const NoAccess = () => {
  const navigate = useNavigate();
  const goToLogin = () => navigate("/login");
  return (
    <div
      id=""
      className="mh100vh"
      style={{ minHeight: window.screen.height - 60 }}>
      <div className="authincation h-70 p-meddle">
        <div className="container h-70">
          <div className="row justify-content-center h-100 align-items-center ">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center-button mb-1">
                        <h2>Access Denied !</h2>
                        <div
                          onClick={goToLogin}
                          role="button"
                          className="login-button">
                          Login
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
