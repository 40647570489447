import { DashboardPage } from "../../pages";
import { modules } from "../../constants/modules";

export const dashboard = [
  {
    path: "/dashboard",
    element: DashboardPage,
    isPrivate: true,
    gurd: modules.DASHBOARD_VIEW,
    module: modules.DASHBOARD,
  },
];
