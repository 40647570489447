import axiosInstance from "./http-common";

const APIService = {
  get: async (url, config = {}) => {
    return await axiosInstance.get(url, config).then((response) => response);
  },

  post: async (url, data, config = {}) => {
    return await axiosInstance
      .post(url, data, { ...config })
      .then((response) => response);
  },

  put: async (url, data, config = {}) => {
    return axiosInstance
      .put(url, data, { ...config })
      .then((response) => response);
  },

  del: async (url, config = {}) => {
    return await axiosInstance
      .delete(url, { ...config })
      .then((response) => response);
  },
};

export default APIService;
