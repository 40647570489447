import * as Yup from "yup";

export const ProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "First Name is too Shrort")
    .required("First Name is Required"),
  lastName: Yup.string()
    .min(3, "Last Name is too Shrort")
    .required("Last Name is Required"),
});
