import { Modal } from "react-bootstrap";
import { Button } from "../Button";
import "./index.scss";

export const CustomModal = ({
  showModal,
  setShowModal,
  title,
  children,
  action,
  closes,
  actionName = "Confirm",
}) => {
  return (
    <Modal className="fade" show={showModal}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <Button
          title={<span>&times;</span>}
          className="close-modal"
          onClick={() => setShowModal(false)}
        />
      </Modal.Header>
      <Modal.Body>
        <span className="modal-body">{children}</span>
      </Modal.Body>
      {action || closes ? (
        <Modal.Footer>
          {action && <Button onClick={action}>{actionName}</Button>}
          {closes && (
            <Button
              className="custom-button reset"
              onClick={() => setShowModal(false)}>
              Close
            </Button>
          )}
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};
