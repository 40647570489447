import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from '../../components/Layouts/shared/Button';
import { LoginSchema } from '../../schemas/login';
import ErrorField from '../../components/Layouts/shared/ErrorField';
import FormikControl from '../../components/Layouts/shared/Formik/FormikControl';
import { login, clearState } from '../../store/actions/auth';

import './index.scss';

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let from = location.state?.from?.pathname || '/dashboard';

  const { isSuccess, isError, error } = useSelector((state) => state.auth.access);

  const [errorsMessage, setErrors] = useState(null);

  const initialValues = {
    username: '',
    password: '',
    isStaff: true,
  };

  const onSubmit = async (values) => {
    await dispatch(login({ data: values }));
    await dispatch(clearState());
  };

  useEffect(() => {
    if (isError) {
      setErrors(error);
    }
    if (isSuccess) {
      dispatch(clearState());
      navigate(from, { replace: true });
    }
  }, [dispatch, isError, isSuccess, navigate, error, from]);
  return (
    <div id='' className='mh100vh' style={{ minHeight: window.screen.height - 200 }}>
      <div className='authincation h-70 p-meddle'>
        <div className='container h-70'>
          <div className='row justify-content-center h-100 align-items-center '>
            <div className='col-auto'>
              <div className='authincation-content'>
                <div className='row no-gutters'>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
                    <div className='auth-form'>
                      <div className='text-center-button mb-1'>
                        <Link to='/'>
                          <span className='logo-style'>propx</span>
                        </Link>
                      </div>
                      <h5 className='text-center mb-1 mt-1'>SIGN IN</h5>
                      <Formik
                        initialValues={initialValues}
                        validationSchema={LoginSchema}
                        onSubmit={onSubmit}
                        validateOnChange={false}>
                        <Form>
                          <div className='my-3'>
                            <FormikControl control='input' type='email' name='username' placeholder='Email Address' />
                          </div>
                          <div className='my-3'>
                            <FormikControl control='input' type='password' name='password' placeholder='Password' />
                          </div>
                          <div className='form-error'>
                            <ErrorField message={errorsMessage} />
                          </div>
                          <div className='text-center-button text-primary'>
                            <Button type='submit' className='login-button'>
                              Login
                            </Button>
                          </div>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center text-center-button pb-4 text-primary'>
                  <a className='privacy-policy' href='/policy/privacy'>
                    Policy Privacy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
