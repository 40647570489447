import { ClientPage } from "../../pages";
import { modules } from "../../constants/modules";

export const configuration = [
  {
    path: "/configuration/client",
    element: ClientPage,
    isPrivate: true,
    gurd: modules.CLIENT_VIEW,
    module: modules.CONFIGURATION,
  },
];
