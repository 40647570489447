import React from "react";
import { PageTitle } from "../../components/Layouts/shared/PageTitle";
import { useLocation } from "react-router-dom";

import { GroupEdit } from "../../components/Groups/groupEdit";

export const GroupEditPage = () => {
  const location = useLocation();

  return (
    <>
      <PageTitle location={location}>Group Permissions</PageTitle>
      <GroupEdit />
    </>
  );
};
