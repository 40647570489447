import { Table, Tag, Space } from "antd";

export const SelectedTable = ({ data, arrayHelpers, showNumberModal }) => {
  const columns = [
    {
      title: "Prop",
      dataIndex: "username",
      key: "1",
    },
    {
      title: "Slot",
      dataIndex: "sim",
      key: "2",
    },
    {
      title: "A-Number",
      dataIndex: "calling",
      key: "3",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "4",
    },
    {
      title: "PDD/s",
      dataIndex: "pdd",
      key: "5",
    },
    {
      title: "Limit/50",
      dataIndex: "called",
      key: "6",
      render: (tags) => {
        let color =
          tags.length > 35 && tags.length <= 50
            ? "orange"
            : tags.length > 50
            ? "red"
            : "green";
        return <Tag color={color}>{tags.length}/50</Tag>;
      },
    },
    {
      title: "B-Numbers",
      dataIndex: "called",
      key: "7",
      render: (tags) => (
        // <Tag color="green">{tags.length}</Tag>
        <>
          {tags.map((tag, index) => {
            let color = tag.length > 5 ? "geekblue" : "blue";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={index}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "8",
      render: (text, row, index) => (
        <Space size="middle">
          <span
            className="ms-3"
            role="button"
            onClick={() => showNumberModal(row, false)}>
            <i className="fas fa-edit fa-lg"></i>
          </span>
          <span
            className="text-danger ms-3"
            role="button"
            onClick={() => arrayHelpers.remove(index)}>
            <i className="fas fa-trash-alt fa-lg"></i>
          </span>
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey="username"
      pagination={false}
      columns={columns}
      dataSource={data}
    />
  );
};
