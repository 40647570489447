import React from "react";
import { useField, ErrorMessage } from "formik";
import { TextError } from "./TextError";

import { Select } from "antd";

const { Option } = Select;

export const Selector = ({
  label,
  name,
  options,
  className = "form-control border-0",
  ...rest
}) => {
  /*eslint-disable */
  const [field, state, { setValue, setTouched }] = useField(name);
  function handleChange(value) {
    setValue(value);
  }

  return (
    <div>
      <label className="form-label  pt-2 mb-1" htmlFor={name}>
        {label}
      </label>
      <Select
        value={state.value || undefined}
        className="w-100"
        showSearch
        onChange={handleChange}
        {...rest}>
        {options.map((option, index) => (
          <Option key={index} value={option.value}>
            {option.key}
          </Option>
        ))}
      </Select>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
