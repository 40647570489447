import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

import { NoDataIndication } from "./noDataIndication";

export const InnerTable = (innerData) => {
  const { innerKey, columns, bordered = false } = innerData;

  return {
    parentClassName: "parent-expand",
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i role="button" className="fas fa-minus"></i>;
      }
      return <i role="button" className="fas fa-plus"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <i role="button" className="fas fa-chevron-down"></i>;
      }
      return <i role="button" className="fas fa-chevron-right "></i>;
    },
    onlyOneExpanding: true,
    renderer: (row) => (
      <BootstrapTable
        wrapperClasses="wrapper-cl"
        keyField={innerKey}
        data={row.rows}
        columns={columns}
        bordered={bordered}
        noDataIndication={NoDataIndication}
      />
    ),
  };
};
