import React from "react";
import { Table } from "../Layouts/shared/Table-B";
import { ActionButtons } from "./actions";
import { FormattedDate } from "../Layouts/shared/FormattedDate";
import { ClientSlice } from "../../store/actions/clients";
import { hasMany } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";

export const ClientTable = () => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      isDummyField: true,
      attrs: {
        "data-label": "#",
      },
      formatter: (cell, row, index) => <span>{index + 1}</span>,
    },
    {
      dataField: "client",
      text: "Client Name",
      sort: true,
      style: {
        fontWeight: "bold",
      },
      attrs: {
        "data-label": "Client Name",
      },
    },
    {
      dataField: "fullName",
      text: "Full Name",
      sort: true,
      attrs: {
        "data-label": "Full Name",
      },
    },
    {
      dataField: "props",
      text: "Props",
      sort: true,
      isDummyField: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Props",
      },
      formatter: () => <span>0</span>,
    },
    {
      dataField: "createdAt",
      text: "Creation",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Creataion",
      },
      formatter: (cell, row) => <FormattedDate date={cell} />,
    },
    {
      dataField: "id#",
      text: "Actions",
      isDummyField: true,
      hidden: hasMany(Scope.CAN_EDIT_CLIENT, Scope.CAN_DELETE_CLIENT),
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Actions",
      },
      formatter: (cell, row) => <ActionButtons row={row} />,
    },
  ];

  return (
    <div className="table-text p-4">
      <Table
        keyField="client"
        columns={columns}
        sliceName="clients"
        sliceReducer={ClientSlice}
      />
    </div>
  );
};
