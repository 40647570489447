import * as Yup from "yup";
// import {PASSWORD_REGEX} from "../constants/core.js";

export const LoginSchema = Yup.object().shape({
  username: Yup.string().email("Invalid email").required("Email is Required"),
  password: Yup.string()
    .min(6, "password is too short")
    .max(50, "password is too long")
    // .matches(
    //   PASSWORD_REGEX,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special Character"
    // )
    .required("Password is Required"),
});
