import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { CDRTable } from "../../components/CDR/CDRTable";
import { getTypes } from "../../store/actions/types";
import { PageTitle } from "../../components/Layouts/shared/PageTitle";
import { Filter } from "../../components/CDR/Filter";
import "./index.scss";

export const CDRsPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const init = async () => {
      await dispatch(getTypes());
    };
    init();
  }, [dispatch]);

  return (
    <>
      <PageTitle location={location}>CDRs List</PageTitle>
      <div className=" bg-white border  p-3">
        <Filter />
      </div>
      <div className=" bg-white border rounded-xl  pb-2 my-2">
        <CDRTable />
      </div>
    </>
  );
};
