import React from "react";
import { Route, Routes } from "react-router-dom";
import AppRoute from "./Route";

const App = () => {
  return (
    <Routes>
      <Route>{AppRoute()}</Route>
    </Routes>
  );
};

export default App;
