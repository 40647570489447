import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { CustomModal } from "../Layouts/shared/Modals/modals";
import { Button } from "../Layouts/shared/Button";
import { UserSlice } from "../../store/actions/users";

const TerminateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const [showModal, setShowModal] = useState(false);

  const { userId } = JSON.parse(localStorage.getItem("userInfo"));

  const handleDeactive = async () => {
    const values = { active: false };
    const { error } = await dispatch(
      UserSlice.update({ id: userId, data: values })
    );
    if (!error) {
      setShowModal(true);
    } else {
      addToast(error.message, { appearance: "error", autoDismiss: true });
    }
  };

  const loginAgain = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <div className="pt-3">
      <div className="">
        <p className="text-danger border p-3 mt-5 mb-5">
          This function going to Deactivate your Account, and it will need
          administrator support to active it Again .
        </p>
        <div className="d-flex justify-content-end p-4">
          <div className="m-1">
            <Button
              className=" btn-danger font-weigth-bold mt-2"
              onClick={handleDeactive}>
              Deactivate Account
            </Button>
          </div>
        </div>
      </div>
      <CustomModal
        title="Notes !"
        showModal={showModal}
        setShowModal={setShowModal}
        actionName="Logout"
        action={loginAgain}>
        <p className="text-danger">Your account has been deactivated.</p>
      </CustomModal>
    </div>
  );
};

export default TerminateAccount;
