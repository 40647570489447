import APIService from "../services/APIService";
import { createAsyncThunk } from "@reduxjs/toolkit";

const ThunkHelper = {
  initialState: {
    paginate: {
      page: 1,
      total: 0,
      per_page: 10,
    },
    filterProps: { refresh: 1 },
    list: [],
    object: {},
    isLoading: false,
    error: false,
  },

  paginate: (prefix, url, callback) =>
    createAsyncThunk(`${prefix}/Paginate`, async (config = {}, thunkAPI) => {
      try {
        const response = await APIService.get(url, config);
        if (response.status === 200) {
          if (typeof callback == "function") {
            return callback(thunkAPI, response.data);
          } else return response.data;
        }
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }),

  getAll: (prefix, url, callback) =>
    createAsyncThunk(`${prefix}/GetAll`, async (config = {}, thunkAPI) => {
      try {
        const response = await APIService.get(url, config);
        if (response.status === 200) {
          if (typeof callback === "function") {
            return callback(thunkAPI, response.data);
          } else return response.data;
        }

        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }),

  get: (prefix, url) =>
    createAsyncThunk(`${prefix}/Get`, async ({ id, config = {} }, thunkAPI) => {
      try {
        const response = await APIService.get(`${url}/${id}`, config);
        if (response.status === 200) return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }),

  put: (prefix, url) =>
    createAsyncThunk(
      `${prefix}/Update`,
      async ({ id, data, config = {} }, thunkAPI) => {
        try {
          const response = await APIService.put(`${url}/${id}`, data, config);
          if (response.status === 200) return response.data;
        } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data);
        }
      }
    ),

  post: (prefix, url, callback) =>
    createAsyncThunk(
      `${prefix}/Post`,
      async ({ data, config = {} }, thunkAPI) => {
        try {
          const response = await APIService.post(url, data, config);
          if (response.status === 200) {
            if (typeof callback === "function") {
              return callback(thunkAPI, response.data);
            } else return response.data;
          }
        } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data);
        }
      }
    ),

  delete: (prefix, url) =>
    createAsyncThunk(
      `${prefix}/Delete`,
      async ({ id, field = "id", config = {} }, thunkAPI) => {
        try {
          const response = await APIService.del(`${url}/${id}`, config);
          if (response.status === 200) return { id, field };
        } catch (error) {
          return thunkAPI.rejectWithValue(error.response.data);
        }
      }
    ),

  pendingReducer: (state, action) => {
    state.isLoading = true;
    state.error = null;
  },

  rejectReducer: (state, { payload }) => {
    state.error = payload && payload.message ? payload.message : payload;
    state.isLoading = false;
  },

  setInitFilter: (state, { payload }) => {
    state.isLoading = true;
    state.filterProps = payload;
  },
  reloadData: (state) => {
    state.filterProps.refresh += 1;
  },

  setPaginateReducer: (state, { payload }) => {
    state.list = Array.isArray(payload.rows) ? payload.rows : [];

    state.paginate = {
      ...state.paginate,
      page: payload.page,
      total: payload.total,
      per_page: Number(payload.per_page),
    };

    state.isLoading = false;
    state.error = !payload ?? "Error not have payload data !!!";
  },

  setAppendReducer: (state, { payload }) => {
    state.list = payload;
    state.isLoading = false;
  },

  setAddReducer: (state, { payload }) => {
    state.list.push(payload);
    state.isLoading = false;
  },

  setAddObjReducer: (state, { payload }) => {
    state.object = payload;
    state.isLoading = false;
  },

  setUpdateReducer: (state, { payload }) => {
    const index = state.list.findIndex((client) => client.id === payload.id);
    state.list[index] = {
      ...state.list[index],
      ...payload,
    };
    state.isLoading = false;
  },

  setUpdateObjectReducer: (state, { payload }) => {
    state.object = payload;
    state.isLoading = false;
  },

  setDeleteReducer: (state, { payload }) => {
    const index = state.list.findIndex(
      (row) => row[payload.field] === payload.id
    );
    state.list.splice(index, 1);
    state.isLoading = false;
  },
};

export default ThunkHelper;
