import { createSlice } from "@reduxjs/toolkit";
import ThunkHelper from "../thunkHelper";

const slicePrefix = "Props";
const endPointUrl = "/api/props";

const PropSlice = {
  paginate: ThunkHelper.paginate(slicePrefix, endPointUrl),
  getAll: ThunkHelper.post(slicePrefix, `${endPointUrl}/getByFilter`),
  get: ThunkHelper.get(slicePrefix, `${endPointUrl}/statistics`),
  update: ThunkHelper.put(slicePrefix, `${endPointUrl}/update`),
  delete: ThunkHelper.delete(slicePrefix, endPointUrl),
};

const filterProps = {
  page: 1,
  per_page: 10,
  refresh: 1,
  propName: [],
  phoneNumber: "",
  client: [],
  status: [],
  operatorName: [],
  country: [],
};

const PropSliceInit = createSlice({
  name: slicePrefix,
  initialState: { ...ThunkHelper.initialState, filterProps },
  reducers: {
    setFilter: ThunkHelper.setInitFilter,
  },
  extraReducers: {
    [PropSlice.paginate.pending]: ThunkHelper.pendingReducer,
    [PropSlice.paginate.rejected]: ThunkHelper.rejectReducer,
    [PropSlice.paginate.fulfilled]: ThunkHelper.setPaginateReducer,
    [PropSlice.getAll.pending]: ThunkHelper.pendingReducer,
    [PropSlice.getAll.rejected]: ThunkHelper.rejectReducer,
    [PropSlice.getAll.fulfilled]: ThunkHelper.setAppendReducer,
    [PropSlice.get.pending]: ThunkHelper.pendingReducer,
    [PropSlice.get.rejected]: ThunkHelper.rejectReducer,
    [PropSlice.get.fulfilled]: ThunkHelper.setAddObjReducer,
    [PropSlice.update.pending]: ThunkHelper.pendingReducer,
    [PropSlice.update.rejected]: ThunkHelper.rejectReducer,
    [PropSlice.update.fulfilled]: ThunkHelper.setUpdateReducer,
    [PropSlice.delete.pending]: ThunkHelper.pendingReducer,
    [PropSlice.delete.rejected]: ThunkHelper.rejectReducer,
    [PropSlice.delete.fulfilled]: ThunkHelper.setDeleteReducer,
  },
});

export const { setFilter } = PropSliceInit.actions;
export { PropSlice, PropSliceInit };
