import { createSlice } from "@reduxjs/toolkit";

const slicePrefix = "Control";

const ControlSliceInit = createSlice({
  name: slicePrefix,
  initialState: {
    sidebarState: false,
  },
  reducers: {
    sidebarToggle: (state) => {
      state.sidebarState = !state.sidebarState;
    },
  },
});

export const { sidebarToggle } = ControlSliceInit.actions;
export { ControlSliceInit };
