import React from "react";
import { useLocation } from "react-router-dom";

import { SysLogTable } from "../../components/SysemLog/SysLogTable";
import { PageTitle } from "../../components/Layouts/shared/PageTitle";
import { Filter } from "../../components/SysemLog/Filter";
import "./index.scss";

export const SystemLog = () => {
  const location = useLocation();

  return (
    <>
      <PageTitle location={location}>system logs</PageTitle>
      <div className=" bg-white border rounded-xl p-3">
        <Filter />
      </div>
      <div className="table-text bg-white border rounded-xl p-4 pb-4 my-2">
        <SysLogTable />
      </div>
    </>
  );
};
