import React, { useState } from "react";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import FormikControl from "../Layouts/shared/Formik/FormikControl";
import { TaskSlice } from "../../store/actions/tasks";
import { PermissionGate } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";
import { Modal } from "antd";
import { showDeleteConfirm } from "../Layouts/shared/Modals";
import { setReload } from "../../store/actions/tasks";

export const ActionButtons = ({ data }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const AttemptsTypes = [
    { key: "idle", value: "idle" },
    { key: "sending", value: "sending" },
    { key: "schedule", value: "schedule" },
    { key: "delivered", value: "delivered" },
    { key: "incomplete", value: "incomplete" },
    { key: "completed", value: "completed" },
  ];

  const handleOnSubmit = async (values, { setSubmitting }) => {
    if (setSubmitting) {
      const res = await dispatch(TaskSlice.send({ data: values }));
      if (res.error) {
        addToast(res.payload.message, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast("send Successfully", {
          appearance: "success",
          autoDismiss: true,
        });
      }
    }
    setSubmitting(false);
    setShow(false);
  };

  const handleRemove = (data) => {
    const res = dispatch(
      TaskSlice.delete({ id: data.taskName, field: "taskName" })
    );
    if (res.error) {
      addToast(res.payload.message, {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      addToast("send Successfully", {
        appearance: "success",
        autoDismiss: true,
      });
    }
    dispatch(setReload());
  };

  let submitMyForm = null;

  const handleSubmitForm = (e) => {
    if (submitMyForm) submitMyForm(e);
  };

  const bindSubmitForm = (submitForm) => {
    submitMyForm = submitForm;
  };

  return (
    <div>
      <span
        className="text-success"
        role="button"
        onClick={() => setShow(true)}>
        <i className="fas fa-sync "></i>
      </span>
      <PermissionGate scope={[Scope.CAN_DELETE_TASK]}>
        <span
          className="text-danger ms-3"
          role="button"
          onClick={() =>
            showDeleteConfirm(data.taskName, () => handleRemove(data))
          }>
          <i className="far fa-trash-alt fa-lg"></i>
        </span>
      </PermissionGate>
      <Modal
        visible={show}
        onCancel={() => setShow(false)}
        onOk={handleSubmitForm}
        title="Task Retry">
        <span>{`Select status that you need to re-run again:`}</span>
        <Formik
          initialValues={{ taskName: data.taskName, attemptStatus: "" }}
          onSubmit={handleOnSubmit}>
          {({ values, isSubmitting, submitForm }) => {
            bindSubmitForm(submitForm);

            return (
              <Form className="p-3">
                <div className="p-4">
                  <FormikControl
                    control="select"
                    name="attemptStatus"
                    options={AttemptsTypes}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};
