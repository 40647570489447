import Select, { components } from "react-select";
import { useField } from "formik";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const Option = (props) => {
  return (
    <div className="form-check">
      <components.Option {...props}>
        <input
          type="checkbox"
          className="form-check-input"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label className="form-check-label">{props.label}</label>
      </components.Option>
    </div>
  );
};

export const SelectField = ({ options, value, cName, placeholder, label }) => {
  /*eslint-disable */
  const [field, state, { setValue, setTouched }] = useField(cName);
  const onChange = (value) => {
    const types = value.map((val) => val.value);
    setValue(types);
  };

  const customStyles = {
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "transpernt" : "transpernt",
        color: "#333333",
      };
    },
    control: (provided, state) => ({
      ...provided,
      // width: "188px",
      minHeight: "32px",
      color: state ? "black" : "gray",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: "1px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "1px",
    }),
  };

  return (
    <div style={{ height: "2rem" }}>
      <label className="form-label  pt-2 mb-1" htmlFor={cName}>
        {label}
      </label>
      <Select
        options={options.filter((option) => option.value !== null)}
        name={cName}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        components={animatedComponents}
        isMulti
        placeholder={placeholder}
        onChange={onChange}
        onBlur={setTouched}
        value={value}
        styles={customStyles}
        getOptionLabel={(options) => options["key"].toUpperCase()}
        getOptionValue={(options) => options["value"]}
        components={{
          Option,
          ValueContainer: ({ children, ...props }) => {
            let [values, input] = children;

            if (Array.isArray(values)) {
              const plural = values.length === 1 ? "" : "s";
              values =
                values.length <= 2 ? values : `${values.length} item${plural} `;
            }

            return (
              <components.ValueContainer {...props}>
                {values}
                {input}
              </components.ValueContainer>
            );
          },
        }}
        allowSelectAll={true}
        theme={(theme) => ({
          ...theme,
          borderRadius: 3,
          colors: {
            ...theme.colors,
            primary25: "transpernt",
            primary75: "transpernt",
            primary50: "transpernt",
            primary: "transpernt",
          },
        })}
      />
    </div>
  );
};
