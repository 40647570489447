import React from "react";
import { Formik, Form } from "formik";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import FormikControl from "../Layouts/shared/Formik/FormikControl";
import { Button } from "../Layouts/shared/Button";
import { setFilter } from "../../store/actions/attempts";

export const Filter = () => {
  const dispatch = useDispatch();
  const { types } = useSelector((state) => state.entities.types);
  const { filterProps } = useSelector((state) => state.entities.attempts);

  const statusList = [
    { key: "idel", value: "idle" },
    { key: "sending", value: "sending" },
    { key: "scheduled", value: "schedule" },
    { key: "delivered", value: "delivered" },
    { key: "incomplete", value: "incomplete" },
    { key: "completed", value: "completed" },
  ];

  const handleOnSubmit = (values, { setSubmitting }) => {
    if (setSubmitting) {
      const data = { ...values };
      data.start = moment(data.start).valueOf();
      data.end = moment(data.end).valueOf();
      dispatch(setFilter(data));
    }
    setSubmitting(false);
  };

  return (
    <>
      <Formik initialValues={filterProps} onSubmit={handleOnSubmit}>
        {({ values, isSubmitting }) => (
          <Form>
            <div className="container-cdr">
              <div>
                <FormikControl
                  control="date"
                  label="From"
                  name="start"
                  selected={values.start}
                  dateFormat="yyyy-MM-dd HH:mm"
                  showTimeSelect
                />
              </div>

              <div>
                <FormikControl
                  control="date"
                  label="To"
                  name="end"
                  selected={values.end}
                  dateFormat="yyyy-MM-dd HH:mm"
                  showTimeSelect
                />
              </div>
              <div>
                <FormikControl
                  control="multiSelect"
                  label="Props"
                  name="username"
                  options={types.propAttempt ? types.propAttempt : []}
                />
              </div>
              <div>
                <FormikControl
                  control="multiSelect"
                  label="Task"
                  name="taskName"
                  options={types.tasks ? types.tasks : []}
                />
              </div>
              <div>
                <FormikControl control="input" label="A-Party" name="calling" />
              </div>
              <div>
                <FormikControl control="input" label="B-Party" name="called" />
              </div>
              <div>
                <FormikControl
                  control="multiSelect"
                  label="Status"
                  name="attemptStatus"
                  options={statusList}
                />
              </div>
            </div>

            <div className="d-flex  justify-content-end pb-2 pt-2">
              <div className="m-1">
                <Button
                  type="submit"
                  title={isSubmitting ? "Loading ..." : "Search"}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
