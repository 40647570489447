import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { sidebarItems } from "./sidebarData";
import { decryptPermissions } from "../../../utils/auth/tokenCheck";
import { ArrowDownSilver } from "../../../assets/icons/icon";

import "./Navbar.scss";
import { sidebarToggle } from "../../../store/UI/control";

const notUndefined = (anyValue) => typeof anyValue !== "undefined";

const hasSubItems = (item) => item.subItems;

const SubItem = ({ item, pathname, toggleSidebar }) => {
  const isSelected = item.subItems.some((i) => i.link === pathname);
  const [isOpen, setIsOpen] = useState(isSelected);

  const hasRight = ({ module }) => {
    const rights = decryptPermissions();

    return rights
      .map((right) => (right.gurdName === module ? module : null))
      .filter(notUndefined)
      .includes(module);
  };

  const rights = decryptPermissions();
  const transaction = rights.filter((el) => item.modules.includes(el.category));
  if (transaction.length === 0) {
    return null;
  } else {
    return (
      <li>
        <Link
          to={item.subItems[0].link}
          onClick={() => setIsOpen(true)}
          className={pathname.includes(item.link) ? "active" : ""}>
          {item.icon}
          {item.title}
          <span>{ArrowDownSilver}</span>
        </Link>
        {isOpen && pathname.includes(item.link) && (
          <ul className="sub-menu">
            {item.subItems.map((subItem, index) =>
              hasRight(subItem) ? (
                <li key={index}>
                  <Link
                    onClick={toggleSidebar}
                    className={pathname.includes(subItem.link) ? "active" : ""}
                    to={subItem.link}>
                    {subItem.name}
                  </Link>
                </li>
              ) : null
            )}
          </ul>
        )}
      </li>
    );
  }
};

const SideBar = () => {
  const dispatch = useDispatch();
  const sidebarState = useSelector((state) => state.ui.control.sidebarState);
  const { pathname } = useLocation();

  const toggleSidebar = () => dispatch(sidebarToggle());

  return (
    <>
      <div className={sidebarState ? "sidebar active " : "sidebar"}>
        <div className="mb-auto w-100">
          <ul className="nav-menu-items">
            {sidebarItems
              .filter((menu) => menu.isVisible)
              .map((item, index) => (
                <Fragment key={index}>
                  {hasSubItems(item) ? (
                    <SubItem
                      item={item}
                      pathname={pathname}
                      toggleSidebar={toggleSidebar}
                    />
                  ) : (
                    <li>
                      <Link
                        to={item.link}
                        className={item.link === pathname ? "active" : ""}>
                        {item.icon}
                        {item.title}
                      </Link>
                    </li>
                  )}
                </Fragment>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBar;
