import React from "react";
import { Table } from "../Layouts/shared/Table-B";
import { isActive } from "../../constants/status";
import { FormattedDate } from "../Layouts/shared/FormattedDate";
import { ActionButtons } from "./actions";
import { GroupSlice } from "../../store/actions/groups";
import { hasMany } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";
import "./index.scss";

const GroupTable = ({ filter }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      isDummyField: true,
      attrs: {
        "data-label": "#",
      },
      formatter: (cell, row, index) => <span>{index + 1}</span>,
    },
    {
      dataField: "name",
      text: "Group Name",
      sort: true,
      style: {
        fontWeight: "bold",
      },
      attrs: {
        "data-label": "Group Name",
      },
    },
    {
      dataField: "active",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Status",
      },
      formatter: (cell, row) =>
        isActive(row.active) ? (
          <span className="text-success">Active</span>
        ) : (
          <span className="text-danger">Disabled</span>
        ),
    },
    {
      dataField: "Users",
      text: "Users",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Users",
      },
      formatter: (cell, row) => (
        <span className="no-permissions">{row.Users && row.Users.length}</span>
      ),
    },
    {
      dataField: "Permissions",
      text: "No. Permission",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "No. Permissions",
      },
      formatter: (cell, row) => (
        <span className="no-permissions">
          {row.Permissions && row.Permissions.length}
        </span>
      ),
    },
    {
      dataField: "createdAt",
      text: "Creation",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Creataion",
      },
      formatter: (cell, row) => <FormattedDate date={cell} />,
    },
    {
      dataField: "id#",
      text: "Actions",
      isDummyField: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Actions",
      },
      hidden: hasMany([Scope.CAN_EDIT_GROUP, Scope.CAN_DELETE_GROUP]),
      formatter: (cell, row) => <ActionButtons row={row} />,
    },
  ];

  return (
    <div className="table-text p-4">
      <Table
        keyField="name"
        columns={columns}
        params={filter}
        sliceName="groups"
        sliceReducer={GroupSlice}
      />
    </div>
  );
};

export default GroupTable;
