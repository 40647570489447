import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducer";

const store = () => {
  return configureStore({
    reducer,
    // middleware: (getDefaultMiddleware) =>
    //   getDefaultMiddleware({
    //     serializableCheck: {
    //       ignoredActions: ["SysLog/Paginate/fulfilled"],
    //     },
    //   }),
  });
};

export default store;
