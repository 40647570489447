import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Map from "../../components/Map/useMap";
import { PropsTable } from "../../components/Props/PropTable";
import { getTypes } from "../../store/actions/types";
import { PageTitle } from "../../components/Layouts/shared/PageTitle";
import { Filter } from "../../components/Props/Filter";

import "./index.scss";

export const PropsPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { list } = useSelector((state) => state.entities.props);

  const [mapView, setMapView] = useState(false);

  const showMapHandler = () => setMapView(!mapView);

  useEffect(() => {
    const getInitialData = async () => {
      await dispatch(getTypes());
    };
    getInitialData();
  }, [dispatch]);

  return (
    <>
      <PageTitle location={location}>Prop List</PageTitle>
      <div className="bg-white border p-3">
        <Filter mapView={mapView} showMapHandler={showMapHandler} />
      </div>
      <div className=" bg-white border rounded-xl p-4 pb-2 my-2 ">
        {mapView ? <Map places={list} /> : <PropsTable />}
      </div>
    </>
  );
};
