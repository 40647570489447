export const Info = "info";
export const inWarn = "warn";
export const Error = "error";

export const isInfo = (status) => status === Info;
export const isWarn = (status) => status === inWarn;
export const isError = (status) => status === Error;

export const active = true;

export const isActive = (status) => status === active;
