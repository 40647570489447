import React from "react";
import "./index.scss";

export const Button = ({title, children, className, ...props}) => {
  return (
    <button className={className ? className : "custom-button"} {...props}>
      {title}
      {children}
    </button>
  );
};
