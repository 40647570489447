import { HostName } from "./endpoints";
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: HostName,
  headers: {
    "Content-Type": "application/json",
    // "x-access-token": `${localStorage.getItem("access_token")}`,
    "Access-Control-Allow-Origin": "*",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");

    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (
      originalConfig.url !== `/api/auth` &&
      originalConfig.url !== `/api/auth/token` &&
      err.response
    ) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await axiosInstance.post(`${HostName}/api/auth/token`, {
            token: localStorage.getItem("refresh_token"),
          });

          const { accessToken } = rs.data;

          localStorage.setItem("access_token", accessToken);
          axiosInstance.defaults.headers.common["x-access-token"] = accessToken;
          return axiosInstance(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 403 && err.response.data) {
        window.location = "/login";
        localStorage.clear();
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);

export default axiosInstance;
