import { createSlice } from "@reduxjs/toolkit";
import ThunkHelper from "../thunkHelper";
import moment from "moment";

const slicePrefix = "Tasks";
const endPointUrl = "/api/attempts";

const TaskSlice = {
  paginate: ThunkHelper.paginate(
    slicePrefix,
    `${endPointUrl}/tasks`,
    (thunkAPI, data) => {
      const { rows: list } = data;
      var helper = {};
      const newList = list
        .reduce((r, data) => {
          var key = data.taskName;
          if (!helper[key]) {
            helper[key] = Object.assign({}, data);
            r.push(helper[key]);
          } else {
            helper[key].incomplete =
              helper[key].incomplete * 1 + data.incomplete * 1;
            helper[key].idle = helper[key].idle * 1 + data.idle * 1;
            helper[key].sending = helper[key].sending * 1 + data.sending * 1;
            helper[key].complete = helper[key].complete * 1 + data.complete * 1;
            helper[key].delivered =
              helper[key].delivered * 1 + data.delivered * 1;
            helper[key].schedule = helper[key].schedule * 1 + data.schedule * 1;
          }
          return r;
        }, [])
        .map((row) => {
          row.rows = list.filter((f) => f.taskName === row.taskName);
          return row;
        });
      return {
        page: data.page,
        per_page: data.per_page,
        total: data.total,
        total_pages: data.total_pages,
        rows: newList,
      };
    }
  ),
  get: ThunkHelper.get(slicePrefix, endPointUrl),
  add: ThunkHelper.post(slicePrefix, `${endPointUrl}/create`),
  send: ThunkHelper.post(slicePrefix, `${endPointUrl}/send`),
  delete: ThunkHelper.delete(slicePrefix, endPointUrl),
};

const filterProps = {
  refresh: 1,
  taskName: [],
  start: moment().subtract(7, "days").valueOf(),
  end: moment().endOf("day").valueOf(),
};

const TaskSliceInit = createSlice({
  name: slicePrefix,
  initialState: { ...ThunkHelper.initialState, filterProps },
  reducers: {
    setFilter: ThunkHelper.setInitFilter,
    setReload: ThunkHelper.reloadData,
  },
  extraReducers: {
    [TaskSlice.paginate.pending]: ThunkHelper.pendingReducer,
    [TaskSlice.paginate.rejected]: ThunkHelper.rejectReducer,
    [TaskSlice.paginate.fulfilled]: ThunkHelper.setPaginateReducer,
    [TaskSlice.add.pending]: ThunkHelper.pendingReducer,
    [TaskSlice.add.rejected]: ThunkHelper.rejectReducer,
    [TaskSlice.add.fulfilled]: ThunkHelper.setAddReducer,
    [TaskSlice.get.pending]: ThunkHelper.pendingReducer,
    [TaskSlice.get.rejected]: ThunkHelper.rejectReducer,
    [TaskSlice.get.fulfilled]: ThunkHelper.setAppendReducer,
    [TaskSlice.send.pending]: ThunkHelper.pendingReducer,
    [TaskSlice.send.rejected]: ThunkHelper.rejectReducer,
    [TaskSlice.send.fulfilled]: ThunkHelper.setAddObjReducer,
    [TaskSlice.delete.pending]: ThunkHelper.pendingReducer,
    [TaskSlice.delete.rejected]: ThunkHelper.rejectReducer,
    [TaskSlice.delete.fulfilled]: ThunkHelper.setDeleteReducer,
  },
});

export const { setFilter, setReload } = TaskSliceInit.actions;
export { TaskSlice, TaskSliceInit };
