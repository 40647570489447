import React, {useState, useEffect, useRef} from "react";
import {CustomDropdown, DropdownItems} from "../CustomDropdown";

// function useOutsideAlerter(ref) {}

export const Options = ({
  arrow = false,
  buttonTitle,
  margin,
  menuItems = null,
  style,
}) => {
  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const showMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div ref={ref}>
      <CustomDropdown margin={margin}>
        <button
          onClick={showMenu}
          className={
            arrow === true ? "btn btn-link dropdown-toggle" : "btn btn-link"
          }
        >
          {buttonTitle}
        </button>

        {isOpen && <DropdownItems position={style}>{menuItems}</DropdownItems>}
      </CustomDropdown>
    </div>
  );
};
