import * as Yup from "yup";
import { PASSWORD_REGEX } from "../constants/core.js";

export const PasswordSechema = Yup.object().shape({
  oldPassword: Yup.string().required("Password"),
  password: Yup.string()
    .min(6, "password is too short")
    .max(50, "password is too long")
    .matches(
      PASSWORD_REGEX,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One special Character"
    )
    .required("Password is Required"),
  confirmPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    ),
  }),
});
