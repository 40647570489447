import { createSlice } from "@reduxjs/toolkit";
import ThunkHelper from "../thunkHelper";

const slicePrefix = "Services";
const endPointUrl = "/api/services";

const ServicesSlice = {
  getServices: ThunkHelper.getAll(slicePrefix, endPointUrl),
  updateServices: ThunkHelper.put(slicePrefix, endPointUrl),
};

const ServicesSliceInit = createSlice({
  name: slicePrefix,
  initialState: ThunkHelper.initialState,
  extraReducers: {
    [ServicesSlice.getServices.pending]: ThunkHelper.pendingReducer,
    [ServicesSlice.getServices.rejected]: ThunkHelper.rejectReducer,
    [ServicesSlice.getServices.fulfilled]: ThunkHelper.setAddObjReducer,
    [ServicesSlice.updateServices.pending]: ThunkHelper.pendingReducer,
    [ServicesSlice.updateServices.rejected]: ThunkHelper.rejectReducer,
    [ServicesSlice.updateServices.fulfilled]:
      ThunkHelper.setUpdateObjectReducer,
  },
});

export const { getServices, updateServices } = ServicesSlice;

export { ServicesSlice, ServicesSliceInit };
