import { combineReducers } from "redux";
import entitiesReducer from "./reducers/entities";
import authReducer from "./reducers/auth";
import uiReducer from "./reducers/uiReducer";

export default combineReducers({
  ui: uiReducer,
  entities: entitiesReducer,
  auth: authReducer,
});
