import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setReload } from "../../store/actions/tasks";

export const AutoRefresh = () => {
  const dispatch = useDispatch();

  const [counter, setCounter] = useState(5);
  const [timer, setTimer] = useState(false);

  useEffect(() => {
    if (timer && counter !== -1) {
      setTimeout(() => {
        setCounter((counter) => counter - 1);
      }, 1000);
    } else {
      timer && dispatch(setReload()); //ignore
      timer && setCounter(5);
    }
  }, [dispatch, counter, timer]);

  const reloadHandler = () => dispatch(setReload());
  const setTimerHandler = () => setTimer(!timer);

  return (
    <div className="d-flex justify-content-end align-items-center m-1">
      <i role="button" onClick={reloadHandler} className="fas fa-sync "></i>
      <i
        role="button"
        onClick={setTimerHandler}
        className={`fas fa-${timer ? "pause" : "play"} ms-2`}></i>
      <span className="ms-2">{`Refersh in ${counter} sec`}</span>
    </div>
  );
};
