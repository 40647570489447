import React, { useState } from "react";
import { Formik, Form } from "formik";
import moment from "moment";
import { useSelector } from "react-redux";

import { CDRTable } from "../../CDRTable";

import { Button } from "../../../../components/Layouts/shared/Button";
import FormikControl from "../../../Layouts/shared/Formik/FormikControl";
import { SelectField } from "../../../Layouts/shared/Formik/MultiSelect";
import "./index.scss";

const Records = () => {
  const { types } = useSelector((state) => state.entities.types);

  const initialValues = {
    page: 1,
    per_page: 10,
    number: "",
    cli: "",
    types: [],
    state: [],
    client: [],
    operator: [],
    country: [],
    start: moment().startOf("day").valueOf(),
    end: moment().endOf("day").valueOf(),
  };

  const [values, setValues] = useState(initialValues);

  const handleOnSubmit = async (values, { setSubmitting }) => {
    if (setSubmitting) {
      values.start = moment(values.start).valueOf();
      values.end = moment(values.end).valueOf();
      let refresh = 0;
      setValues({ ...values, refresh: refresh + 1 });
    }
    setSubmitting(false);
  };

  return (
    <div className="col-12 data-filter">
      <Formik initialValues={initialValues} onSubmit={handleOnSubmit}>
        {({ values, isSubmitting }) => (
          <Form>
            <div className="container-records">
              <div>
                <FormikControl
                  control="input"
                  type="text"
                  label="Number"
                  name="number"
                />
              </div>
              <div>
                <FormikControl
                  control="input"
                  type="text"
                  label="CLI"
                  name="cli"
                />
              </div>
              <div>
                <FormikControl
                  control="date"
                  label="From"
                  name="start"
                  selected={values.start}
                  dateFormat="yyyy-MM-dd HH:mm"
                  showTimeSelect
                />
              </div>

              <div>
                <FormikControl
                  control="date"
                  label="To"
                  name="end"
                  selected={values.end}
                  dateFormat="yyyy-MM-dd HH:mm"
                  showTimeSelect
                />
              </div>
              <div className="m-select">
                <FormikControl
                  control="multiSelect"
                  component={SelectField}
                  label="Country"
                  cName="country"
                  options={types.countryCdr ? types.countryCdr : []}
                  isMulti
                  backspaceRemovesValue
                />
              </div>
              <div className="m-select">
                <FormikControl
                  control="multiSelect"
                  component={SelectField}
                  label="Operator"
                  cName="operator"
                  options={types.operators ? types.operators : []}
                  isMulti
                  backspaceRemovesValue
                />
              </div>
              <div className="m-select">
                <FormikControl
                  control="multiSelect"
                  component={SelectField}
                  label="Client"
                  cName="client"
                  options={types.clientCdr ? types.clientCdr : []}
                  isMulti
                  backspaceRemovesValue
                />
              </div>
              <div className="m-select">
                <FormikControl
                  control="multiSelect"
                  component={SelectField}
                  label="State"
                  cName="state"
                  options={types.callState ? types.callState : []}
                  isMulti
                  backspaceRemovesValue
                />
              </div>
              <div>
                <FormikControl
                  control="multiSelect"
                  component={SelectField}
                  label="Types"
                  cName="types"
                  options={types.callType ? types.callType : []}
                  isMulti
                  backspaceRemovesValue
                />
              </div>
            </div>
            <div className="d-flex border-bottom pb-2 pt-2">
              <div className="m-1">
                <Button
                  type="submit"
                  title={isSubmitting ? "Loading ..." : "Search"}
                />
              </div>
              <div className="m-1">
                <Button
                  type="reset"
                  className="custom-button reset"
                  title="Reset"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      <div className="row">
        <div className="col-12 ">
          <CDRTable filter={values} />
        </div>
      </div>
    </div>
  );
};

export default Records;
