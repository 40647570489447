import React from "react";
import { Breadcrumb } from "antd";
import "./index.scss";

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
}

export const BreadcrumbToken = ({ location }) => {
  const crumbLow = location.pathname.split("/").filter((el) => el !== "");

  const crumb = crumbLow
    .filter((el) => !checkIfValidUUID(el) && el !== "-1")
    .map((el) => el.charAt(0).toUpperCase() + el.slice(1));

  const isLast = (index) => {
    return index === crumb.length - 1;
  };

  return (
    <Breadcrumb className="custom-crumb" separator="/">
      {["Home", ...crumb].map((crumb, ci) => {
        const selectedIem = isLast(ci) ? crumb : <span>{crumb}</span>;
        return <Breadcrumb.Item key={ci}>{selectedIem}</Breadcrumb.Item>;
      })}
    </Breadcrumb>
  );
};
