import React from "react";
import { Button } from "../../components/Layouts/shared/Button";
import * as MdIcons from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { PermissionGate } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";

export const AddButton = () => {
  const navigate = useNavigate();

  const goToGroupList = () => navigate("/security/group/-1");

  return (
    <div className="add-button  mt-4">
      <PermissionGate scope={[Scope.CAN_ADD_GROUP]}>
        <Button onClick={goToGroupList} size="sm">
          <MdIcons.MdSecurity size={17} />
          Define New Group Policy
        </Button>
      </PermissionGate>
    </div>
  );
};
