import { modules, Scope } from "../../../constants/modules";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import * as GrIcons from "react-icons/gr";

export const sidebarItems = [
  {
    icon: <MdIcons.MdDashboard size="25" />,
    title: "Dashboard",
    isVisible: true,
    cName: "nav-text",
    modules: [modules.DASHBOARD],
    link: "/dashboard",
    // subItems: [
    //   {name: "Statistics", link: "/dashboard", module: modules.GLOBAL},
    //   // {name: "online", link: "/dashboard/online", module: modules.GLOBAL},
    // ],
  },
  {
    icon: <RiIcons.RiDeviceRecoverFill size="25" />,
    title: "Props",
    isVisible: true,
    cName: "nav-text",
    modules: [modules.PROP],
    link: "/props",
    subItems: [
      { name: "Props List", link: "/props/list", module: modules.PROP_VIEW },
      { name: "CDRs", link: "/props/cdr", module: modules.CDR_VIEW },
    ],
  },
  {
    icon: <MdIcons.MdSwapCalls size="25" />,
    title: "Attempts",
    isVisible: true,
    cName: "nav-text",
    modules: [modules.ATTEMPT],
    link: "/attempt",
    subItems: [
      {
        name: "Tasks List",
        link: "/attempt/task",
        module: modules.TASK_VIEW,
      },
      {
        name: "Create Task",
        link: "/attempt/create",
        module: Scope.CAN_CREATE_TASK,
      },
      {
        name: "Attempts List",
        link: "/attempt/attempt",
        module: modules.ATTEMPT_VIEW,
      },
    ],
  },
  {
    icon: <GrIcons.GrConfigure size="20" />,
    title: "Configuration",
    isVisible: true,
    cName: "nav-text",
    modules: [modules.CONFIGURATION],
    link: "/configuration",
    subItems: [
      {
        name: "Clients",
        link: "/configuration/client",
        module: modules.CLIENT_VIEW,
      },
    ],
  },
  {
    icon: <MdIcons.MdSecurity size="25" />,
    title: "Scurity",
    isVisible: true,
    cName: "nav-text",
    modules: [modules.SECURITY],
    link: "/security",
    subItems: [
      {
        name: "Users",
        link: "/security/user",
        module: modules.USER_VIEW,
      },
      {
        name: "Group",
        link: "/security/group",
        module: modules.GROUP_VIEW,
      },
    ],
  },
  {
    icon: <AiIcons.AiFillSetting size="25" />,
    title: "Settings",
    isVisible: true,
    cName: "nav-text",
    modules: [modules.SETTING],
    link: "/settings",
    subItems: [
      {
        name: "Mobile App",
        link: "/settings/mobile-app",
        module: modules.MOBILE_VIEW,
      },
      {
        name: "Services",
        link: "/settings/service",
        module: modules.SERVICE_VIEW,
      },
      {
        name: "System Logs",
        link: "/settings/sysLog",
        module: modules.SYSLOG_VIEW,
      },
    ],
  },
];
