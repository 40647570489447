import React, { useState, useEffect } from "react";
import { Empty } from "antd";
import xlsxReader from "read-excel-file";

import { CustomModal } from "../Layouts/shared/Modals/modals";
import { Button } from "../../components/Layouts/shared/Button";
import * as RiIcons from "react-icons/ri";
import ErrorField from "../Layouts/shared/ErrorField";

export const NumbersModal = ({
  show,
  setShow,
  addHandler,
  helper,
  values,
  called,
}) => {
  const [call, setCall] = useState("");
  const [calls, setCalls] = useState([]);
  const [error, setError] = useState();
  const [xlsxFile, setXlsxFile] = useState();

  const [showExcelInput, setShowExcelInput] = useState(false);

  useEffect(() => {
    called ? setCalls(called) : setCalls([]);
  }, [called]);

  const handleAddNumber = () => {
    if (call) calls.push(call);
    setCall("");
    setError();
  };

  const removeHandler = (item) => {
    const newArray = calls.filter((el) => el !== item);
    setCalls(newArray);
  };

  const AddXlscFileHandler = async () => {
    try {
      setError();
      const rows = await xlsxReader(xlsxFile);
      rows.map((item) => calls.push(item[0]));
      setXlsxFile();
    } catch (error) {
      setError("Please select file first.");
    }
  };

  const submitHandler = () => {
    if (calls.length === 0) setError("Insert One number At least.");
    else if (calls.length > 50) setError("The Max number is 50");
    else {
      const edit = called ? true : false;
      addHandler(calls, helper, values, edit);
      setCalls([]);
      setShow(false);
    }
  };
  return (
    <CustomModal
      title="Add B-party numbers"
      showModal={show}
      setShowModal={setShow}>
      {/* <div className=" p-2"> */}
      <label className="form-label pt-2 mb-3" htmlFor="call">
        Press Enter to insert singal value or use Excel to upload bulk of
        numbers.
      </label>
      <div className="form-control border-0 p-3">
        <div className="input-group">
          <input
            placeholder="B-Party Number"
            aria-label="Recipient's username with two button addons"
            className="form-control"
            value={call}
            onFocus={() => {
              setShowExcelInput(false);
              setError();
            }}
            onChange={(e) => setCall(e.currentTarget.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleAddNumber();
              }
            }}
          />
          <button
            type="button"
            onClick={() => setShowExcelInput(!showExcelInput)}>
            <RiIcons.RiFileExcel2Fill />
          </button>
        </div>
        <ErrorField message={error} />
        {showExcelInput && (
          <div className="input-group p-2">
            <input
              className="form-control"
              accept=".xlsx"
              type="file"
              onChange={(e) => setXlsxFile(e.target.files[0])}
            />
            <button
              onClick={AddXlscFileHandler}
              type="button"
              id="inputGroupFileAddon04">
              Load File
            </button>
          </div>
        )}
      </div>
      <div className="list-container p-4">
        <ol className="list-group list-group-numbered">
          {calls.length > 0 ? (
            calls.map((item, i) => (
              <li
                key={i}
                className="list-group-item d-flex justify-content-between align-items-center">
                <div className="ms-3 me-auto">
                  <div className="fw-bold">{item}</div>
                </div>
                <span
                  className="text-danger ms-3"
                  role="button"
                  onClick={() => removeHandler(item)}>
                  <i className="fas fa-trash-alt fa-lg"></i>
                </span>
              </li>
            ))
          ) : (
            <Empty className="p-3" />
          )}
        </ol>
      </div>

      <div className="d-flex justify-content-end ">
        <div className="m-1">
          <Button onClick={submitHandler}>Add</Button>
        </div>
      </div>
    </CustomModal>
  );
};
