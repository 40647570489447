import React from "react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import FormikControl from "../Layouts/shared/Formik/FormikControl";
import { ProfileSchema } from "../../schemas/profile";
import { Button } from "../Layouts/shared/Button";
import { UserSlice } from "../../store/actions/users";

const AccountSetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const { firstName, lastName, email, userId } = JSON.parse(
    localStorage.getItem("userInfo")
  );

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const initialValues = {
    username: email,
    firstName,
    lastName,
    // password: "",
    // confirmPassword: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const { error } = await dispatch(
      UserSlice.update({ id: userId, data: values })
    );
    if (!error) {
      localStorage.setItem(
        "userInfo",
        JSON.stringify({
          ...userInfo,
          firstName: values.firstName,
          lastName: values.lastName,
        })
      );
      navigate("/dashboard");
      addToast("Updated successfully !", {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      addToast(error.message, { appearance: "error", autoDismiss: true });
    }
  };

  return (
    <div className="col-12   app-list p-4">
      {/* <div className="container-form"> */}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ProfileSchema}>
        {({ dirty, isSubmitting }) => (
          <Form>
            <div className=" row">
              <div className="col-lg-4">
                <FormikControl
                  control="input"
                  type="email"
                  name="username"
                  label="Email"
                  disabled
                />
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <FormikControl
                    control="input"
                    type="text"
                    name="firstName"
                    label="First Name"
                  />
                </div>
                <div className="col-lg-4">
                  <FormikControl
                    control="input"
                    type="text"
                    name="lastName"
                    label="Last Name"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end p-4">
                <div className="m-1">
                  <Button type="submit" disabled={!dirty}>
                    {isSubmitting ? "Loading ..." : "Submit Update"}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
    // </div>
  );
};

export default AccountSetting;
