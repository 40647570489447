import React from "react";
import { Table } from "../Layouts/shared/Table-B";
import { FormattedDate } from "../Layouts/shared/FormattedDate";
import { isInfo, isWarn } from "../../constants/status";
import { SysLogSlice } from "../../store/actions/sysLogs";
import "./index.scss";

export const SysLogTable = () => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      headerAlign: "center",
      attrs: {
        "data-label": "#",
      },
    },
    {
      dataField: "level",
      text: "Level",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Level",
      },
      formatter: (cell, row) =>
        isInfo(row.level) ? (
          <span className="is-info">Info</span>
        ) : isWarn(row.level) ? (
          <span className="is-warn">Warning</span>
        ) : (
          <span className="is-error">Error</span>
        ),
    },
    {
      dataField: "message",
      text: "Messages",
      sort: true,
      headerAlign: "left",
      align: "left",
      attrs: {
        "data-label": "Messages",
      },
    },
    {
      dataField: "meta",
      text: "Meta",
      sort: true,
      headerAlign: "left",
      align: "left",
      attrs: {
        "data-label": "Meta",
      },
      formatter: (cell, row) => (
        <span>{row.meta.client ? row.meta.client : "blank"}</span>
      ),
    },
    {
      dataField: "timestamp",
      text: "Creation",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Creataion",
      },
      formatter: (cell, row) => <FormattedDate date={cell} />,
    },
  ];

  return (
    <div className="table-text p-4">
      <Table
        keyField="id"
        columns={columns}
        sliceName="sysLogs"
        sliceReducer={SysLogSlice}
      />
    </div>
  );
};
