import React, { useState } from "react";

export const TabContainer = ({ defaultTab, title, tabs, ...rest }) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const Component = selectedTab.component;

  return (
    <div className="card">
      <div className="card-body m-2">
        {title && <div className="title m-1 mb-4 ">{title}</div>}
        <div className="profile-tab pt-1">
          <div className="custom-tab-1">
            <ul className="nav nav-tabs">
              {tabs.map((tab) => (
                <li key={tab.name} className="nav-item">
                  <button
                    className={
                      selectedTab.name === tab.name
                        ? "nav-link active show"
                        : "nav-link"
                    }
                    onClick={() => setSelectedTab(tab)}>
                    {tab.icon} {tab.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <section>
            <Component
              selectedTab={(param) => setSelectedTab(param)}
              {...rest}
            />
          </section>
        </div>
      </div>
    </div>
  );
};
