import React from "react";
import { Progress } from "antd";
import * as AiIcons from "react-icons/ai";

import { Button } from "../../components/Layouts/shared/Button";
import { PermissionGate } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";

export const UploadTag = ({ onProgress, setShowModal }) => {
  return (
    <div className="d-flex justify-content-end p-2">
      {onProgress && onProgress > 0 ? (
        <div>
          <span>Upload progress ...</span>
          <Progress strokeLinecap="square" percent={onProgress} />
        </div>
      ) : (
        <PermissionGate scope={[Scope.CAN_ADD_MOBILE_APP]}>
          <Button onClick={setShowModal}>
            <span>
              <AiIcons.AiOutlineAppstoreAdd size={19} /> Add New Release
            </span>
          </Button>
        </PermissionGate>
      )}
    </div>
  );
};
