/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";

import { ListLoader } from "../DataLoading";
import { NoDataIndication } from "./noDataIndication";
import { InnerTable } from "./expand";

import "./index.scss";

export const Table = ({
  keyField,
  columns,
  bordered = false,
  expandData,
  sliceName,
  sliceReducer,
  showSizePerPage = true,
}) => {
  const dispatch = useDispatch();

  const {
    paginate,
    list,
    isLoading,
    filterProps: params,
  } = useSelector((state) => state.entities[sliceName]);

  useEffect(() => {
    const getInitialData = async (params) => {
      const filter = { ...params, ...paginate };
      await dispatch(sliceReducer.paginate({ params: filter }));
    };
    getInitialData(params);
  }, [dispatch, params, sliceReducer]);

  const pageButtonRenderer = ({ page, active, onPageChange }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    return (
      <div key={page + 1} className="custom-pagination">
        <li>
          <button
            href="#"
            onClick={handleClick}
            className={`paginate-buttons ${active && "active"}`}>
            {page}
          </button>
        </li>
      </div>
    );
  };

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li
      key={text}
      role="button"
      onMouseDown={(e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
      className="dropdown-item">
      <a
        href="#1"
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        className="paginate-dropdown">
        {text}
      </a>
    </li>
  );

  const customTotal = (from, to, size) => (
    <span className="total-page ms-3">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    onSizePerPageChange: (sizePerPage, page) => {
      dispatch(
        sliceReducer.paginate({
          params: { ...params, page, per_page: sizePerPage },
        })
      ).then(() => {
        pagination.options.page = paginate.page;
        pagination.options.sizePerPage = paginate.per_page;
      });
    },
    onPageChange: (page, sizePerPage) => {
      dispatch(
        sliceReducer.paginate({
          params: { ...params, page, per_page: sizePerPage },
        })
      ).then(() => {
        pagination.options.page = paginate.page;
        pagination.options.sizePerPage = paginate.per_page;
      });
    },
    page: paginate.page,
    sizePerPage: paginate.per_page,
    totalSize: paginate.total,
    pageButtonRenderer,
    sizePerPageOptionRenderer,

    // paginationSize: 4,
    // pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    hideSizePerPage: !showSizePerPage, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: false, // Hide the pagination list when only one page
    firstPageText: "<<",
    lastPageText: ">>",
    showTotal: showSizePerPage,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10 Items",
        value: 10,
      },
      {
        text: "20 Items",
        value: 20,
      },
      {
        text: "50 Items",
        value: 50,
      },
      {
        text: "All Items",
        value: 1000,
      },
    ],
  };

  let pagination = paginationFactory(options);

  return (
    <>
      <BootstrapTable
        keyField={keyField}
        data={list}
        columns={columns}
        bordered={bordered}
        noDataIndication={
          isLoading ? <ListLoader height={"200px"} /> : NoDataIndication
        }
        pagination={options.totalSize > 9 ? pagination : null}
        expandRow={!expandData ? {} : InnerTable(expandData)}
        remote={{
          pagination: true,
          search: true,
          filter: false,
          sort: false,
          overlay: true,
        }}
        loading={isLoading}
        onTableChange={() => {}}
        onDataSizeChange={() => {}}
      />
    </>
  );
};
