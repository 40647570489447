import React from "react";
import { FormattedDate } from "../Layouts/shared/FormattedDate";
import { Table } from "../Layouts/shared/Table-B";

import { FileSlice } from "../../store/actions/files";
import { hasMany } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";
import { ActionButtons } from "./actions";

export const MobileTable = ({ isLoading }) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "#",
      },
      formatter: (cell, row, index) => <span>{index + 1}</span>,
    },
    {
      dataField: "fileName",
      text: "File Name",
      sort: true,
      headerAlign: "left",
      align: "left",
      style: {
        fontWeight: "bold",
      },
      attrs: {
        "data-label": "File Name",
      },
    },
    {
      dataField: "download",
      text: "",
      sort: true,
      headerAlign: "left",
      align: "left",
      style: {
        fontWeight: "bold",
      },
      attrs: {
        "data-label": "",
      },
      formatter: (cell, row) => (
        <span role="button">
          <a href={row.path}>Download</a>
        </span>
      ),
    },
    {
      dataField: "version",
      text: "Version",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Version",
      },
    },
    {
      dataField: "published",
      text: "Published",
      sort: true,
      headerAlign: "center",
      align: "center",
      style: {
        fontWeight: "bold",
      },
      attrs: {
        "data-label": "Published",
      },
      formatter: (cell, row) =>
        isLoading ? (
          <></>
        ) : cell ? (
          <span className="text-success">Yes</span>
        ) : (
          <span className="text-danger">No</span>
        ),
    },
    {
      dataField: "createdAt",
      text: "Release",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Release",
      },
      formatter: (cell, row) => <FormattedDate date={cell} />,
    },
    {
      dataField: "id#",
      text: "Actions",
      isDummyField: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Actions",
      },
      hidden: hasMany([
        Scope.CAN_DELETE_MOBILE_APP,
        Scope.CAN_PUBLISH_MOBILE_APP,
      ]),
      formatter: (cell, row) => <ActionButtons row={row} />,
    },
  ];

  return (
    <div className="table-text p-4">
      <Table
        keyField="fileName"
        sliceName="files"
        sliceReducer={FileSlice}
        columns={columns}
      />
    </div>
  );
};
