import React from "react";
import Avatar from "@material-ui/core/Avatar";

import { TabContainer } from "../../components/Layouts/shared/TabContainer";
import { defaultTab, tabs } from "../../components/Profile/tabs";
import "./profile.scss";

const Profile = () => {
  const { firstName, lastName, email } = JSON.parse(
    localStorage.getItem("userInfo")
  );

  const roleName = localStorage.getItem("roleName");

  return (
    <div className="content-body">
      <div className="container-fluid">
        <div className="row profile">
          <div className="col-lg-3 col-md-12 col-sm-12 p-2">
            <div className="profile-wrapper">
              <Avatar alt="" className="profile-img" src={null}>
                {firstName.charAt(0).toUpperCase() +
                  lastName.charAt(0).toUpperCase()}
              </Avatar>
              <h3>{`${firstName} ${lastName}`}</h3>
              <h5>{email}</h5>
              <label>{roleName ? roleName.toUpperCase() : null}</label>
            </div>
          </div>
          <div className="col-lg-9 col-md-12  box">
            <TabContainer tabs={tabs} defaultTab={defaultTab} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
