import React from "react";

import { Table } from "../Layouts/shared/Table-B";
import { AttemptSlice } from "../../store/actions/attempts";
import { FormattedDate } from "../Layouts/shared/FormattedDate";

export const AttemptTable = () => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,

      attrs: {
        "data-label": "#",
      },
      formatter: (cell, row, index) => <span>{index + 1}</span>,
    },
    {
      dataField: "username",
      text: "Prop Name",
      sort: true,
      style: {
        fontWeight: "bold",
      },
      attrs: {
        "data-label": "Prop Name",
      },
    },
    {
      dataField: "calling",
      text: "A-Party",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "A-Party",
      },
    },
    {
      dataField: "called",
      text: "B-Party",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "B-Party",
      },
    },
    {
      dataField: "pdd",
      text: "PDD",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "PDD",
      },
    },
    {
      dataField: "attemptStatus",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Status",
      },
      formatter: (cell) =>
        cell === "completed" ? (
          <span className="text-success">Completed</span>
        ) : cell === "incomplete" ? (
          <span className="text-danger">Incompleted</span>
        ) : (
          <span>{cell}</span>
        ),
    },
    {
      dataField: "taskName",
      text: "Task",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Task",
      },
    },
    {
      dataField: "scheduleTime",
      text: "Date",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Date",
      },
      formatter: (cell) => <FormattedDate date={cell} />,
    },
  ];

  return (
    <Table
      keyField="id"
      sliceName="attempts"
      sliceReducer={AttemptSlice}
      columns={columns}
    />
  );
};
