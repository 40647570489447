import { createSlice } from "@reduxjs/toolkit";
import ThunkHelper from "../thunkHelper";

const slicePrefix = "Clients";
const endPointUrl = "/api/clients";

const ClientSlice = {
  paginate: ThunkHelper.paginate(slicePrefix, endPointUrl),
  getAll: ThunkHelper.getAll(slicePrefix, endPointUrl),
  get: ThunkHelper.get(slicePrefix, endPointUrl),
  update: ThunkHelper.put(slicePrefix, endPointUrl),
  add: ThunkHelper.post(slicePrefix, endPointUrl),
  delete: ThunkHelper.delete(slicePrefix, endPointUrl),
};

const clientSliceInit = createSlice({
  name: slicePrefix,
  initialState: ThunkHelper.initialState,
  extraReducers: {
    [ClientSlice.paginate.pending]: ThunkHelper.pendingReducer,
    [ClientSlice.paginate.rejected]: ThunkHelper.rejectReducer,
    [ClientSlice.paginate.fulfilled]: ThunkHelper.setPaginateReducer,
    [ClientSlice.add.pending]: ThunkHelper.pendingReducer,
    [ClientSlice.add.rejected]: ThunkHelper.rejectReducer,
    [ClientSlice.add.fulfilled]: ThunkHelper.setAddReducer,
    [ClientSlice.get.pending]: ThunkHelper.pendingReducer,
    [ClientSlice.get.rejected]: ThunkHelper.rejectReducer,
    [ClientSlice.get.fulfilled]: ThunkHelper.setAppendReducer,
    [ClientSlice.getAll.pending]: ThunkHelper.pendingReducer,
    [ClientSlice.getAll.rejected]: ThunkHelper.rejectReducer,
    [ClientSlice.getAll.fulfilled]: ThunkHelper.setAppendReducer,
    [ClientSlice.update.pending]: ThunkHelper.pendingReducer,
    [ClientSlice.update.rejected]: ThunkHelper.rejectReducer,
    [ClientSlice.update.fulfilled]: ThunkHelper.setUpdateReducer,
    [ClientSlice.delete.pending]: ThunkHelper.pendingReducer,
    [ClientSlice.delete.rejected]: ThunkHelper.rejectReducer,
    [ClientSlice.delete.fulfilled]: ThunkHelper.setDeleteReducer,
  },
});

export { ClientSlice, clientSliceInit };
