import React from "react";
import IconTimeline from "../icons/icon-timeline.svg";
import IconChecked from "../icons/icon-checked.svg";
import IconInformation from "../icons/icon-information.svg";
import IconArrowDown from "../icons/arrowDown.png";
import IconDashboard from "../icons/iconDashboard.png";
import IconSettings from "../icons/iconSettings.png";
import IconSettingsWhite from "../icons/iconSettingsWhite.png";
import IconTickGreen from "../icons/iconTickGreen.png";
import IconHome from "../icons/iconHome.png";
import IconTickBlue from "../icons/tickBlue.png";
import IconPaypal from "../icons/iconPaypal.png";
import IconChargeBee from "../icons/iconChargeBee.png";
import IconBank from "../icons/iconBank.png";
import IconTiles from "../icons/iconTile.png";
import ArrowRightBlue from "../icons/arrow-right-blue.png";
import IconShortcutsBlue from "../icons/iconShortcuts.png";
import IconMessageMdBlack from "../icons/icon-message-md.png";
import IconSearchBlue from "../icons/icon-search-blue-23x23.png";
import IconNewWindowBlue from "../icons/icon-new-window.png";
import IconBlueEye24x16 from "../icons/icon-blue-eye-24x16.png";
import ArrowDownSilver14x8 from "../icons/arrow_down_silver.png";
import IconPlusBlue from "../icons/icon-plus-circle-blue.svg";
import IconThreeDot from "../icons/icon-three-dot.png";
import IconSortForTable from "../icons/icon-sort-for-table.png";
import IconFilterSilver from "../icons/icon-filter-silver.svg";
import IconPaginationNext from "../icons/pagination-next-icon.png";
import IconPaginationBack from "../icons/pagination-back-icon.png";
import IconAlert12x12 from "../icons/icon-alert-12x12.png";
import IconChart12x12 from "../icons/icon-chart-12x12.png";
import IconHeart12x12 from "../icons/icon-heart-12x12.png";
import IconCategory12x12 from "../icons/icon-category-12x12.png";
import IconHeartBlueBorder from "../icons/icon-heart-blue-border.png";
import IconHeartOrange from "../icons/icon-heart-orange.png";
import IconEyeBlueBold from "../icons/eye-icon-bold.png";
import IconThreeDotVertical from "../icons/three-dot-vertical.png";
import IconSelectAll16x14 from "../icons/icon-select-all-16x14.png";
import StatsMessageIcon from "../icons/stats-message.png";
import StatsGroupIcon from "../icons/stats-group.png";
import StatsFormSubmissionsIcon from "../icons/stats-submissions.png";
import StatsSupportIcon from "../icons/stats-support.png";
import StatsInvitationsIcon from "../icons/StatsInvitation.png";
import IconTilesCalendar from "../icons/tiles-calendar.png";
import IconTilesForm from "../icons/tiles-form.png";
import IconTilesGroup from "../icons/tiles-group.png";
import IconTilesMessage from "../icons/tiles-message.png";
import IconTilesPieChart from "../icons/tiles-pie-chart.png";
import IconTilesServices from "../icons/tiles-services.png";
import IconNavSilver from "../icons/icon-nav-silver.png";
import IconCalendarTick18x18 from "../icons/calendar-tick-18x18.png";
import IconBell18x18 from "../icons/bell-18x18.png";

export const Bell = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.667"
    height="20"
    viewBox="0 0 16.667 20"
  >
    <g id="bell_1_" transform="translate(-2)">
      <path
        id="Path_2650"
        d="M18.149 14.094a5.582 5.582 0 0 1-1.983-4.271V7.5a5.838 5.838 0 0 0-5-5.767v-.9a.833.833 0 0 0-1.666 0v.9a5.837 5.837 0 0 0-5 5.767v2.323A5.588 5.588 0 0 1 2.51 14.1a1.458 1.458 0 0 0 .948 2.566h13.75a1.458 1.458 0 0 0 .941-2.572z"
        className="cls-1"
      />
      <path
        id="Path_2651"
        d="M11.388 23.5a3.13 3.13 0 0 0 3.061-2.5H8.326a3.13 3.13 0 0 0 3.062 2.5z"
        className="cls-1"
        transform="translate(-1.054 -3.5)"
      />
    </g>
  </svg>
);
export const ArrowDown = <img src={IconArrowDown} alt="" />;
export const Dashboard = <img src={IconDashboard} alt="" />;
export const Settings = <img src={IconSettings} alt="" />;
export const SettingsWhite = <img src={IconSettingsWhite} alt="" />;
export const TickGreen = <img src={IconTickGreen} alt="" />;
export const TickBlue = <img src={IconTickBlue} alt="" />;
export const HomeIcon = <img src={IconHome} alt="" />;
export const PaypalIcon = <img src={IconPaypal} alt="" />;
export const ChargeBeeIcon = <img src={IconChargeBee} alt="" />;
export const BankIcon = <img src={IconBank} alt="" />;
export const ManageTile = <img src={IconTiles} alt="" />;
export const ArrowBlueR = <img src={ArrowRightBlue} alt="" />;
export const ShortcutsBlue = <img src={IconShortcutsBlue} alt="" />;
export const IconMessageBlack = <img src={IconMessageMdBlack} alt="" />;
export const SearchBlue = <img src={IconSearchBlue} alt="" />;
export const NewWindowBlue = <img src={IconNewWindowBlue} alt="" />;
export const IconBlueEye = <img src={IconBlueEye24x16} alt="" />;
export const AdaptionFormIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="adoption-form"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs></defs>
    <g id="Group_1380">
      <path
        id="Path_2635"
        d="M110.859 0h-4.687a1.172 1.172 0 0 0 0 2.344h4.688a1.172 1.172 0 0 0 0-2.344z"
        className="cls-1"
        transform="translate(-100.898)"
      />
      <g id="Group_1379" transform="translate(0 1.172)">
        <path
          id="Path_2636"
          d="M13.086 30H12.3a2.345 2.345 0 0 1-1.172 2.028v.315a2.346 2.346 0 0 1-2.344 2.344H6.445A2.346 2.346 0 0 1 4.1 32.344v-.315A2.345 2.345 0 0 1 2.93 30h-.782A2.151 2.151 0 0 0 0 32.148V46.68a2.151 2.151 0 0 0 2.148 2.148h10.938a2.151 2.151 0 0 0 2.148-2.148V32.148A2.151 2.151 0 0 0 13.086 30zM4.1 44.453H2.93a.586.586 0 0 1 0-1.172H4.1a.586.586 0 0 1 0 1.172zm0-2.344H2.93a.586.586 0 0 1 0-1.172H4.1a.586.586 0 0 1 0 1.172zm0-2.344H2.93a.586.586 0 0 1 0-1.172H4.1a.586.586 0 0 1 0 1.172zm0-2.344H2.93a.586.586 0 0 1 0-1.172H4.1a.586.586 0 0 1 0 1.172zm1.758 6.445a.586.586 0 0 1 .586-.586h3.906a.586.586 0 0 1 0 1.172H6.445a.586.586 0 0 1-.586-.585zM12.3 46.8H9.961a.586.586 0 0 1 0-1.172H12.3a.586.586 0 0 1 0 1.172zm0-4.687H6.445a.586.586 0 0 1 0-1.172H12.3a.586.586 0 0 1 0 1.172zM5.859 39.18a.586.586 0 0 1 .586-.586h4.688a.586.586 0 0 1 0 1.172H6.445a.586.586 0 0 1-.586-.586zm6.441-1.758H6.445a.586.586 0 0 1 0-1.172H12.3a.586.586 0 0 1 0 1.172z"
          className="cls-1"
          transform="translate(0 -30)"
        />
      </g>
      <path
        id="Path_2637"
        d="M136.172 91.172h2.344A1.172 1.172 0 0 0 139.688 90H135a1.172 1.172 0 0 0 1.172 1.172z"
        className="cls-1"
        transform="translate(-129.727 -86.484)"
      />
    </g>
    <path
      id="Path_2638"
      d="M454.551 432l.5 2.647a.586.586 0 0 0 1.152 0l.5-2.647z"
      className="cls-1"
      transform="translate(-436.795 -415.125)"
    />
    <g id="Group_1381" transform="translate(17.656 1.641)">
      <path
        id="Path_2639"
        d="M454.344 45.906v-2.734a1.172 1.172 0 0 0-2.344 0v2.734z"
        className="cls-1"
        transform="translate(-452 -42)"
      />
      <path
        id="Path_2640"
        d="M452 172h2.344v8.984H452z"
        className="cls-1"
        transform="translate(-452 -166.922)"
      />
    </g>
  </svg>
);
export const CalendarIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path d="M18 0H2a2.006 2.006 0 0 0-2 2v16a2.007 2.007 0 0 0 2 2h16a2.007 2.007 0 0 0 2-2V2a2.006 2.006 0 0 0-2-2zM5.4 17.6H2.333v-2.724H5.4V17.6zm0-3.639H2.333v-2.723H5.4v2.724zm-.233-10.93a.849.849 0 1 1 .849-.849.848.848 0 0 1-.849.849zM9.486 17.6H6.423v-2.724h3.063zm0-3.639H6.423v-2.723h3.063zm0-3.637H6.423V7.6h3.063zm-.335-8.142a.849.849 0 1 1 .849.849.848.848 0 0 1-.849-.849zm4.426 11.78h-3.064v-2.724h3.065v2.724zm0-3.637h-3.064V7.6h3.065v2.724zm.407-8.143a.849.849 0 1 1 .849.849.848.848 0 0 1-.848-.849zm3.682 11.78H14.6v-2.724h3.064zm0-3.637H14.6V7.6h3.064z" />
  </svg>
);
export const GearIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path d="M19.529 7.723l-1.537-.334A8.366 8.366 0 0 0 17.5 6.2l.851-1.324a.6.6 0 0 0-.08-.746l-2.397-2.398a.6.6 0 0 0-.746-.08L13.8 2.5a8.366 8.366 0 0 0-1.193-.495l-.33-1.534A.6.6 0 0 0 11.693 0H8.307a.6.6 0 0 0-.584.471l-.334 1.537A8.366 8.366 0 0 0 6.2 2.5l-1.328-.849a.6.6 0 0 0-.746.08L1.732 4.126a.6.6 0 0 0-.08.746L2.5 6.2a8.366 8.366 0 0 0-.495 1.193l-1.534.33A.6.6 0 0 0 0 8.307v3.387a.6.6 0 0 0 .471.584l1.537.334A8.366 8.366 0 0 0 2.5 13.8l-.851 1.324a.6.6 0 0 0 .08.746l2.395 2.395a.6.6 0 0 0 .746.08L6.2 17.5a8.366 8.366 0 0 0 1.193.495l.334 1.537a.6.6 0 0 0 .58.468h3.387a.6.6 0 0 0 .584-.471l.334-1.537A8.366 8.366 0 0 0 13.8 17.5l1.324.851a.6.6 0 0 0 .746-.08l2.395-2.395a.6.6 0 0 0 .08-.746L17.5 13.8a8.366 8.366 0 0 0 .495-1.193l1.537-.334a.6.6 0 0 0 .468-.58V8.307a.6.6 0 0 0-.471-.584zM13.586 10A3.586 3.586 0 1 1 10 6.414 3.59 3.59 0 0 1 13.586 10zm0 0" />
  </svg>
);
export const GroupIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="group"
    width="20"
    height="18"
    viewBox="0 0 20 18"
  >
    <g id="Group_1063" transform="translate(15.566 9.718)">
      <g id="Group_1062">
        <path
          id="Path_2422"
          d="M400.041 273.32h-1.547a4.5 4.5 0 0 1 .244 1.467v6.2a1.929 1.929 0 0 1-.1.613h2.562a1.789 1.789 0 0 0 1.732-1.838v-3.381a2.982 2.982 0 0 0-2.891-3.061z"
          className="cls-1"
          transform="translate(-398.494 -273.32)"
        />
      </g>
    </g>
    <g id="Group_1065" transform="translate(0 9.718)">
      <g id="Group_1064">
        <path
          id="Path_2423"
          d="M4.19 274.788a4.5 4.5 0 0 1 .244-1.467H2.887A2.982 2.982 0 0 0 0 276.384v3.381a1.789 1.789 0 0 0 1.732 1.835h2.557a1.931 1.931 0 0 1-.1-.613z"
          className="cls-1"
          transform="translate(0 -273.321)"
        />
      </g>
    </g>
    <g id="Group_1067" transform="translate(5.345 8.122)">
      <g id="Group_1066">
        <path
          id="Path_2424"
          d="M143.252 234.815h-3.536a2.982 2.982 0 0 0-2.887 3.063v6.2a.6.6 0 0 0 .577.613h8.155a.6.6 0 0 0 .577-.613v-6.2a2.982 2.982 0 0 0-2.886-3.063z"
          className="cls-1"
          transform="translate(-136.829 -234.815)"
        />
      </g>
    </g>
    <g id="Group_1069" transform="translate(6.528)">
      <g id="Group_1068">
        <path
          id="Path_2425"
          d="M170.586 38.84a3.587 3.587 0 0 0-3.472 3.684 3.728 3.728 0 0 0 1.775 3.213 3.293 3.293 0 0 0 3.394 0 3.728 3.728 0 0 0 1.775-3.213 3.587 3.587 0 0 0-3.472-3.684z"
          className="cls-1"
          transform="translate(-167.114 -38.84)"
        />
      </g>
    </g>
    <g id="Group_1071" transform="translate(1.306 3.434)">
      <g id="Group_1070">
        <path
          id="Path_2426"
          d="M36.04 121.689a2.76 2.76 0 0 0 0 5.51 2.455 2.455 0 0 0 1.023-.223 2.679 2.679 0 0 0 1.268-1.238 2.871 2.871 0 0 0 .305-1.294 2.682 2.682 0 0 0-2.596-2.755z"
          className="cls-1"
          transform="translate(-33.443 -121.689)"
        />
      </g>
    </g>
    <g id="Group_1073" transform="translate(13.5 3.434)">
      <g id="Group_1072">
        <path
          id="Path_2427"
          d="M348.2 121.689a2.682 2.682 0 0 0-2.6 2.755 2.871 2.871 0 0 0 .305 1.294 2.678 2.678 0 0 0 1.268 1.238 2.455 2.455 0 0 0 1.023.223 2.76 2.76 0 0 0 0-5.51z"
          className="cls-1"
          transform="translate(-345.607 -121.689)"
        />
      </g>
    </g>
  </svg>
);
export const MessageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g id="Group_1378" transform="translate(-24.495 -308)">
      <g id="Speech_Bubble_48_" transform="translate(24.495 308)">
        <g id="Group_1074">
          <path
            id="Path_2435"
            fill="#495057"
            d="M2.912 50.548H1.495l1-1.138a4.08 4.08 0 0 0 .968-2.278A8.4 8.4 0 0 1 0 40.29a9.863 9.863 0 0 1 10.034-9.742c6.487 0 9.966 4.518 9.966 9.319 0 4.832-3.515 9.348-9.966 9.348a12 12 0 0 1-3.362-.486 4.969 4.969 0 0 1-3.76 1.819z"
            transform="translate(0 -30.548)"
          />
        </g>
      </g>
      <g id="conversation" transform="translate(23.497 308.164)">
        <g id="Group_1055" transform="translate(7.035 5.835)">
          <g id="Group_1054">
            <path
              id="Path_2413"
              d="M187.309 139.512h-6.656a.741.741 0 0 0 0 1.436h6.656a.741.741 0 0 0 0-1.436z"
              className="cls-2"
              transform="translate(-180.094 -139.512)"
            />
          </g>
        </g>
        <g id="Group_1057" transform="translate(7.035 8.789)">
          <g id="Group_1056">
            <path
              id="Path_2414"
              d="M187.309 202.183h-6.656a.741.741 0 0 0 0 1.436h6.656a.741.741 0 0 0 0-1.436z"
              className="cls-2"
              transform="translate(-180.094 -202.183)"
            />
          </g>
        </g>
        <g id="Group_1059" transform="translate(7.035 11.742)">
          <g id="Group_1058">
            <path
              id="Path_2415"
              d="M184.746 264.852h-4.094a.741.741 0 0 0 0 1.436h4.094a.741.741 0 0 0 0-1.436z"
              className="cls-2"
              transform="translate(-180.093 -264.852)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export const PieChartIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.999"
    height="20"
    viewBox="0 0 19.999 20"
  >
    <g id="pie-chart_1_" transform="translate(-.016)">
      <path
        id="Path_2644"
        d="M166.809 87.106h-.586v8.111l-4.81 6.52.471.348a8.3 8.3 0 1 0 4.924-14.979z"
        className="cls-1"
        transform="translate(-155.094 -83.703)"
      />
      <path
        id="Path_2645"
        d="M2.409 118.691a11.114 11.114 0 0 0-1.333 1.8 8.3 8.3 0 0 0 .8 9.3 10.552 10.552 0 0 0 1.984 1.789l5.247-7.112z"
        className="cls-1"
        transform="translate(0 -114.055)"
      />
      <path
        id="Path_2646"
        d="M86.767 0A8.074 8.074 0 0 0 83.3.628a8.084 8.084 0 0 0-2.739 2.216l6.2 5.351z"
        className="cls-1"
        transform="translate(-77.401)"
      />
    </g>
  </svg>
);
export const PieGraphIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="pie-graph"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      id="Path_2660"
      d="M8.828 61a8.809 8.809 0 1 0 8.789 8.789V69.2h-8.2V61zm0 0"
      className="cls-1"
      transform="translate(0 -58.617)"
    />
    <path
      id="Path_2661"
      d="M299.372 79.143a8.824 8.824 0 0 0-.8-1.061l-6.364 6.364h1.859zm0 0"
      className="cls-1"
      transform="translate(-280.796 -75.032)"
    />
    <path
      id="Path_2662"
      d="M271 43.622l6.364-6.364a8.822 8.822 0 0 0-1.061-.8l-5.3 5.3zm0 0"
      className="cls-1"
      transform="translate(-260.414 -35.037)"
    />
    <path
      id="Path_2663"
      d="M271 0v5.07l4.23-4.23a8.754 8.754 0 0 0-3.644-.84zm0 0"
      className="cls-1"
      transform="translate(-260.414)"
    />
    <path
      id="Path_2664"
      d="M382.211 136.941h5.07v-.586a8.754 8.754 0 0 0-.84-3.644zm0 0"
      className="cls-1"
      transform="translate(-367.281 -127.527)"
    />
  </svg>
);
export const PuzzleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="puzzle_1_"
    width="20"
    height="17.866"
    viewBox="0 0 20 17.866"
  >
    <path
      id="Path_2654"
      d="M243.523 83.647l2.489-2.489-2.489-2.489.4-.4a1.779 1.779 0 0 0-2.516-2.516l-.4.4-2.489-2.489-.734.735v1.161a2.953 2.953 0 0 1 0 5.745v3.6h-2.155l-.43.43 3.318 3.318 2.489-2.489.346.346a1.779 1.779 0 0 0 2.516-2.516zm0 0"
      className="cls-1"
      transform="translate(-226.012 -70.787)"
    />
    <path
      id="Path_2655"
      d="M10.6 12.945v-3.52h.489a1.779 1.779 0 1 0 0-3.559H10.6v-3.52H7.079v-.567a1.779 1.779 0 1 0-3.559 0v.567H0v3.52h.489a1.782 1.782 0 0 1 1.744 1.428.6.6 0 0 1 0 .7A1.782 1.782 0 0 1 .489 9.425H0v3.52h3.52v-.567a1.779 1.779 0 0 1 3.559 0v.567zm0 0"
      className="cls-1"
    />
  </svg>
);
export const ShoppingListIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="20"
    viewBox="0 0 15 20"
  >
    <g id="shopping-list" transform="translate(-64)">
      <g id="Group_1410" transform="translate(67.75)">
        <g id="Group_1409">
          <path
            id="Path_2652"
            d="M166.875 1.25h-1.357a1.876 1.876 0 0 0-3.536 0h-1.356a.625.625 0 0 0-.625.625v2.5a.625.625 0 0 0 .625.625h6.25a.625.625 0 0 0 .625-.625v-2.5a.625.625 0 0 0-.626-.625z"
            className="cls-1"
            transform="translate(-160)"
          />
        </g>
      </g>
      <g id="Group_1412" transform="translate(64 2.5)">
        <g id="Group_1411">
          <path
            id="Path_2653"
            d="M77.75 64H76.5v1.875a1.877 1.877 0 0 1-1.875 1.875h-6.25a1.877 1.877 0 0 1-1.875-1.875V64h-1.25A1.252 1.252 0 0 0 64 65.25v15a1.236 1.236 0 0 0 1.25 1.25h12.5A1.236 1.236 0 0 0 79 80.25v-15A1.236 1.236 0 0 0 77.75 64zm-6.433 11.067l-2.5 2.5a.628.628 0 0 1-.885 0l-1.25-1.25a.625.625 0 0 1 .884-.884l.809.808 2.058-2.058a.625.625 0 0 1 .885.884zm0-5l-2.5 2.5a.628.628 0 0 1-.885 0l-1.25-1.25a.625.625 0 0 1 .884-.884l.809.808 2.058-2.058a.625.625 0 0 1 .885.884zm4.558 6.433h-2.5a.625.625 0 0 1 0-1.25h2.5a.625.625 0 0 1 0 1.25zm0-5h-2.5a.625.625 0 0 1 0-1.25h2.5a.625.625 0 0 1 0 1.25z"
            className="cls-1"
            transform="translate(-64 -64)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const SpeedometerIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="12"
    viewBox="0 0 20 12"
  >
    <g id="speedometer" transform="translate(0 -109.908)">
      <path
        id="Path_2428"
        data-name="Path 2428"
        d="M0,264.978H2.666a7.158,7.158,0,0,1,.686-2.76L.882,260.655A10.934,10.934,0,0,0,0,264.978Z"
        transform="translate(0 -144.556)"
      />
      <path
        id="Path_2429"
        data-name="Path 2429"
        d="M283.493,113.059l-.517,4.07a6.49,6.49,0,0,1,2.644,1.568l3.023-3.039A9.775,9.775,0,0,0,283.493,113.059Z"
        transform="translate(-271.922 -3.022)"
      />
      <path
        id="Path_2430"
        data-name="Path 2430"
        d="M36.539,161.531l2.54,1.608a6.72,6.72,0,0,1,2.093-1.917l-1.917-2.8A10.389,10.389,0,0,0,36.539,161.531Z"
        transform="translate(-35.112 -46.524)"
      />
      <path
        id="Path_2431"
        data-name="Path 2431"
        d="M373.878,197.776l-3.055,3.072a7.1,7.1,0,0,1,1.32,3.833h4.2A10.79,10.79,0,0,0,373.878,197.776Z"
        transform="translate(-356.338 -84.259)"
      />
      <path
        id="Path_2432"
        data-name="Path 2432"
        d="M136.142,113.874l.5-3.957c-.134-.006-.268-.009-.4-.009a9.577,9.577,0,0,0-4.868,1.329l2.026,2.955a6.306,6.306,0,0,1,2.743-.317Z"
        transform="translate(-126.241 0)"
      />
      <path
        id="Path_2433"
        data-name="Path 2433"
        d="M209.981,218.958a1.8,1.8,0,1,0,3.6,0c0-1.044-1.8-5.872-1.8-5.872S209.981,217.915,209.981,218.958Z"
        transform="translate(-201.779 -98.94)"
      />
    </g>
  </svg>
);
export const SupportIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19.999"
    viewBox="0 0 20 19.999"
  >
    <g id="support" transform="translate(0 -.018)">
      <path
        id="Path_2647"
        d="M19.985 3.5a1.142 1.142 0 0 0-1.946-.71l-.981.981a.576.576 0 0 1-.8.008.592.592 0 0 1 .008-.835l.965-.965a1.142 1.142 0 0 0-.71-1.945A3.817 3.817 0 0 0 12.5 4.841L.555 16.787a1.893 1.893 0 0 0 2.677 2.677L15.174 7.521A3.821 3.821 0 0 0 19.985 3.5z"
        className="cls-1"
      />
      <path
        id="Path_2648"
        d="M3.508 6.937a.587.587 0 0 0 .108.149l1.7 1.7a.586.586 0 0 0 .829 0L7 7.92l1.649 1.647-1.76 1.76a1.174 1.174 0 0 0 0 1.657l.185.185 6.009-6.009-.183-.184a1.172 1.172 0 0 0-1.658 0L9.478 8.738 7.831 7.091l.862-.862a.586.586 0 0 0 0-.829L7 3.7a.585.585 0 0 0-.154-.1L4.679 2.507A.586.586 0 0 0 4 2.616L2.521 4.1a.586.586 0 0 0-.108.679z"
        className="cls-1"
        transform="translate(-2.258 -2.332)"
      />
      <path
        id="Path_2649"
        d="M262.32 258.046l-4.171 4.171 4.79 4.791a2.949 2.949 0 1 0 4.171-4.171zm3.432 7.6a.586.586 0 0 1-.828 0l-3.326-3.326a.586.586 0 1 1 .828-.828l3.326 3.326a.586.586 0 0 1 0 .832z"
        className="cls-1"
        transform="translate(-248.065 -247.949)"
      />
    </g>
  </svg>
);
export const TimelineIcon = <img src={IconTimeline} alt="" />;
export const ArrowDownSilver = <img src={ArrowDownSilver14x8} alt="" />;
export const CheckedIcon = <img src={IconChecked} alt="" />;
export const InformationIcon = <img src={IconInformation} alt="" />;
export const DeleteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.667"
    height="24"
    viewBox="0 0 18.667 24"
  >
    <g id="delete_1_" transform="translate(-42.667)">
      <g id="Group_1547" transform="translate(42.667)">
        <g id="Group_1546">
          <path
            id="Path_2709"
            d="M64 101.333A2.666 2.666 0 0 0 66.667 104h10.666A2.666 2.666 0 0 0 80 101.333v-16H64z"
            className="cls-1"
            transform="translate(-62.667 -80)"
          />
          <path
            id="Path_2710"
            d="M56.667 1.333L55.334 0h-6.667l-1.333 1.333h-4.667V4h18.667V1.333z"
            className="cls-1"
            transform="translate(-42.667)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const DeleteIconRed = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="14"
    viewBox="0 0 18.667 24"
  >
    <g id="delete_1_" transform="translate(-42.667)">
      <g id="Group_1547" transform="translate(42.667)">
        <g id="Group_1546">
          <path
            fill="red"
            id="Path_2709"
            d="M64 101.333A2.666 2.666 0 0 0 66.667 104h10.666A2.666 2.666 0 0 0 80 101.333v-16H64z"
            className="cls-1"
            transform="translate(-62.667 -80)"
          />
          <path
            fill="red"
            id="Path_2710"
            d="M56.667 1.333L55.334 0h-6.667l-1.333 1.333h-4.667V4h18.667V1.333z"
            className="cls-1"
            transform="translate(-42.667)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const BluePlusIcon = <img src={IconPlusBlue} alt="" />;
export const ThreeDotIcon = <img src={IconThreeDot} alt="" />;
export const IconSort = <img src={IconSortForTable} alt="" />;
export const SilverFilterIcon = <img src={IconFilterSilver} alt="" />;
export const PaginationNext = <img src={IconPaginationNext} alt="" />;
export const PaginationBack = <img src={IconPaginationBack} alt="" />;
export const CategoryIcon12x12 = <img src={IconCategory12x12} alt="" />;
export const AlertIcon12x12 = <img src={IconAlert12x12} alt="" />;
export const HeartIcon12x12 = <img src={IconHeart12x12} alt="" />;
export const ChartIcon12x12 = <img src={IconChart12x12} alt="" />;
export const HeartIconBlueBorder = <img src={IconHeartBlueBorder} alt="" />;
export const HeartIconOrange = <img src={IconHeartOrange} alt="" />;
export const EyeIconBlueBold = <img src={IconEyeBlueBold} alt="" />;
export const ThreeDotVertical = <img src={IconThreeDotVertical} alt="" />;
export const SelectAll16x14 = <img src={IconSelectAll16x14} alt="" />;
export const StatsMessage = <img src={StatsMessageIcon} alt="" />;
export const StatsGroup = <img src={StatsGroupIcon} alt="" />;
export const StatsSubmissions = <img src={StatsFormSubmissionsIcon} alt="" />;
export const StatsSupport = <img src={StatsSupportIcon} alt="" />;
export const StatsInvitations = <img src={StatsInvitationsIcon} alt="" />;
export const TilesCalendar = <img src={IconTilesCalendar} alt="" />;
export const TilesForms = <img src={IconTilesForm} alt="" />;
export const TilesGroup = <img src={IconTilesGroup} alt="" />;
export const TilesMessage = <img src={IconTilesMessage} alt="" />;
export const TilesPieChart = <img src={IconTilesPieChart} alt="" />;
export const TilesService = <img src={IconTilesServices} alt="" />;
export const CalendarTick18x18 = <img src={IconCalendarTick18x18} alt="" />;
export const Bell18x18 = <img src={IconBell18x18} alt="" />;
export const SilverNav = (
  <img src={IconNavSilver} alt="" width="24" height="16" />
);
