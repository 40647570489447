import React from "react";
import moment from "moment";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import FormikControl from "../../components/Layouts/shared/Formik/FormikControl";
import { Button } from "../../components/Layouts/shared/Button";
import { PermissionGate } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";
import { setFilter } from "../../store/actions/tasks";

export const Filter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToCreateAttempt = () => navigate("/attempt/create");

  const { types } = useSelector((state) => state.entities.types);
  const { filterProps } = useSelector((state) => state.entities.tasks);

  const handleOnsSubmit = async (values, { setSubmitting }) => {
    if (setSubmitting) {
      const data = { ...values };
      data.start = moment(data.start).valueOf();
      data.end = moment(data.end).valueOf();
      dispatch(setFilter(data));
    }
    setSubmitting(false);
  };

  return (
    <Formik initialValues={filterProps} onSubmit={handleOnsSubmit}>
      {({ values, isSubmitting }) => (
        <Form>
          <div className="container-cdr">
            <div>
              <FormikControl
                control="multiSelect"
                label="Task"
                name="taskName"
                options={types.tasks ? types.tasks : []}
              />
            </div>
            <div>
              <FormikControl
                control="date"
                label="From"
                name="start"
                selected={values.start}
                dateFormat="yyyy-MM-dd HH:mm"
                showTimeSelect
              />
            </div>

            <div>
              <FormikControl
                control="date"
                label="To"
                name="end"
                selected={values.end}
                dateFormat="yyyy-MM-dd HH:mm"
                showTimeSelect
              />
            </div>
          </div>

          <div className="d-flex justify-content-end pb-2 pt-2">
            <div className="m-1">
              <Button
                type="submit"
                title={isSubmitting ? "Loading ..." : "Search"}
              />
            </div>
            <PermissionGate scope={[Scope.CAN_CREATE_TASK]}>
              <div className="m-1">
                <Button
                  type="button"
                  onClick={goToCreateAttempt}
                  title="New Task"
                  className="bg-success"
                />
              </div>
            </PermissionGate>
          </div>
        </Form>
      )}
    </Formik>
  );
};
