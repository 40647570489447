import React from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";

import FormikControl from "../../components/Layouts/shared/Formik/FormikControl";
import { Button } from "../../components/Layouts/shared/Button";
import { Scope } from "../../constants/modules";
import { PermissionGate } from "../../utils/auth/PermissionGate";
import { setFilter } from "../../store/actions/props";

export const Filter = ({ mapView, showMapHandler }) => {
  const dispatch = useDispatch();

  const { types } = useSelector((state) => state.entities.types);
  const { filterProps } = useSelector((state) => state.entities.props);

  const stateOptions = [
    { value: "ONLINE", key: "ONLINE", type: "Status" },
    { value: "OFFLINE", key: "OFFLINE", type: "Status" },
    { value: "ACTIVE", key: "ACTIVE", type: "Operation" },
    { value: "INACTIVE", key: "INACTIVE", type: "Operation" },
  ];

  const handleOnSubmit = (values, { setSubmitting }) => {
    if (setSubmitting) {
      const data = { ...values };
      dispatch(setFilter(data));
    }
    setSubmitting(false);
  };

  return (
    <Formik initialValues={filterProps} onSubmit={handleOnSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <div className="container-form">
            <div>
              <FormikControl
                control="input"
                label="SIM-1 or 2"
                name="phoneNumber"
              />
            </div>
            <div>
              <FormikControl
                control="multiSelect"
                label="Prop"
                name="propName"
                options={types.props ? types.props : []}
              />
            </div>
            <div>
              <FormikControl
                control="multiSelect"
                label="Client"
                name="client"
                options={types.clientProp ? types.clientProp : []}
              />
            </div>
            <div>
              <FormikControl
                control="multiSelect"
                label="State"
                name="status"
                options={stateOptions}
              />
            </div>
            <div>
              <FormikControl
                control="multiSelect"
                label="Operator"
                name="operatorName"
                options={types.operators ? types.operators : []}
              />
            </div>
            <div>
              <FormikControl
                control="multiSelect"
                label="Country"
                name="country"
                options={types.countryProp ? types.countryProp : []}
              />
            </div>
          </div>
          <div className="d-flex  justify-content-end pt-4 ">
            <div className="m-1">
              <Button
                type="submit"
                title={isSubmitting ? "Loading ..." : "Search"}
              />
            </div>
            <PermissionGate scope={[Scope.CAN_VIEW_MAP]}>
              <div className="m-1">
                <Button
                  type="button"
                  onClick={showMapHandler}
                  className={mapView ? "custom-button map" : null}
                  title={mapView ? "List View" : "Map View"}
                />
              </div>
            </PermissionGate>
          </div>
        </Form>
      )}
    </Formik>
  );
};
