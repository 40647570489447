import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

import FormikControl from "../../components/Layouts/shared/Formik/FormikControl";
import { Button } from "../../components/Layouts/shared/Button";
import { PageTitle } from "../../components/Layouts/shared/PageTitle";
import { getServices, updateServices } from "../../store/actions/services";
import { Scope } from "../../constants/modules";
import { PermissionGate } from "../../utils/auth/PermissionGate";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const ServicesPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { addToast } = useToasts();

  const { object, isLoading } = useSelector((state) => state.entities.services);

  useEffect(() => {
    const init = async () => await dispatch(getServices());
    init();
  }, [dispatch]);

  const handleOnSubmit = async (values, { setSubmitting }) => {
    if (setSubmitting) {
      const res = await dispatch(
        updateServices({ id: object.id, data: values })
      );
      if (!res.error) {
        addToast("Successfully !", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        addToast(res.payload.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
    setSubmitting(false);
  };

  return (
    <>
      <PageTitle location={location}>System Services</PageTitle>
      <div className=" bg-white border overflow-hidden rounded-xl p-3">
        <p className="p-4 text-secondary">
          This is configration system for heart beat services.
        </p>

        <Formik
          enableReinitialize
          initialValues={object}
          onSubmit={handleOnSubmit}>
          {({ values, dirty }) => (
            <Form>
              <div className="pb-5">
                <span className="fw-bold ms-5">System Status:</span>
                {isLoading ? (
                  <span className=" ms-4 fs-6">
                    <Spin indicator={antIcon} />
                  </span>
                ) : (
                  <span className="text-success fw-bold ms-4 fs-6">
                    Running
                  </span>
                )}
              </div>
              <div>
                <FormikControl
                  control="switch"
                  name="sendNotification"
                  label="Enable send notification to all active props."
                  classNameLabel={`form-check-label fs-6 ms-4 ${
                    !values.sendNotification ? "text-danger" : ""
                  }`}
                />
              </div>
              <div>
                <FormikControl
                  control="switch"
                  name="propStatus"
                  label="update all prop online status every minutes."
                  classNameLabel={`form-check-label fs-6 ms-4 ${
                    !values.propStatus ? "text-danger" : ""
                  }`}
                />
              </div>
              <div>
                <FormikControl
                  control="switch"
                  name="scheduleCheck"
                  label="Check the attempts sent in whether they are delivered to the props every five minutes."
                  classNameLabel={`form-check-label fs-6 ms-4 ${
                    !values.scheduleCheck ? "text-danger" : ""
                  }`}
                />
              </div>
              <div>
                <FormikControl
                  control="switch"
                  name="attemptTimeout"
                  label="Runs these tasks so that the scheduled attempts are checked to see if they have passed their scheduled time in every minutes."
                  classNameLabel={`form-check-label fs-6 ms-4 ${
                    !values.attemptTimeout ? "text-danger" : ""
                  }`}
                />
              </div>
              <div>
                <FormikControl
                  control="switch"
                  name="sendReadyAttempt"
                  label="Runs these tasks attempts that need to be sending to props, check every minutes."
                  classNameLabel={`form-check-label fs-6 ms-4 ${
                    !values.sendReadyAttempt ? "text-danger" : ""
                  }`}
                />
              </div>
              <div className="d-flex justify-content-end pt-4">
                <PermissionGate scope={[Scope.CAN_MANAGE_SERVICES]}>
                  <Button
                    disabled={!dirty}
                    className={!dirty && "bg-secondary"}
                    type="submit">
                    Apply
                  </Button>
                </PermissionGate>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
