import React from "react";
import { Table } from "../Layouts/shared/Table-B";
import { FormattedDate } from "../Layouts/shared/FormattedDate";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import "./index.scss";
import { CDRtSlice } from "../../store/actions/cdr";

export const CDRTable = () => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      sort: true,
      isDummyField: true,
      attrs: {
        "data-label": "#",
      },
      formatter: (cell, row, index) => <span>{index + 1}</span>,
    },
    {
      dataField: "propName",
      text: "Prop",
      sort: true,
      headerAlign: "center",
      align: "center",
      style: {
        fontWeight: "bold",
      },
      attrs: {
        "data-label": "Prop",
      },
    },
    {
      dataField: "number",
      text: "A-Party",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "A-Party",
      },
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Country",
      },
      formatter: (cell) => <span>{cell.toUpperCase()}</span>,
    },
    {
      dataField: "operatorName",
      text: "Operator",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Operator",
      },
      formatter: (cell) => <span>{cell.toUpperCase()}</span>,
    },
    {
      dataField: "cli",
      text: "B-Party",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "B-Party",
      },
      formatter: (cell) => <span>{cell.toUpperCase()}</span>,
    },
    {
      dataField: "duration",
      text: "Duration",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Duration",
      },
    },
    {
      dataField: "callType",
      text: "TYPE",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "TYPE",
      },
      formatter: (cell, row) =>
        cell === "sms" ? (
          <FaIcons.FaEnvelope size={15} />
        ) : cell === "voice" ? (
          <MdIcons.MdCall size={15} />
        ) : cell === "viber" ? (
          <FaIcons.FaViber size={15} />
        ) : (
          <span>{cell}</span>
        ),
    },
    {
      dataField: "callState",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Status",
      },
      formatter: (cell, row) => <span>{cell ? cell.toUpperCase() : ""}</span>,
    },
    {
      dataField: "client",
      text: "Client",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Client",
      },
    },
    {
      dataField: "callDate",
      text: "Data",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Data",
      },
      formatter: (cell) => <FormattedDate date={cell} />,
    },
  ];

  return (
    <div className="table-text p-4">
      <Table
        keyField="id"
        sliceName="cdrs"
        sliceReducer={CDRtSlice}
        columns={columns}
      />
    </div>
  );
};
