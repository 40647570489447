import React from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
import { canAccess } from "../../utils/auth/permission";

import routes from "../../config/routes";
import { Layout } from "../Layouts/nav/Layout";

const AppRoutes = () => {
  const location = useLocation();

  return routes.map((route, index) => {
    return (
      <Route
        key={index}
        path={route.path}
        element={
          <>
            {route.isPrivate ? (
              canAccess(route) ? (
                <Layout>
                  <route.element />
                </Layout>
              ) : (
                <Navigate to="/login" state={{ from: location }} />
              )
            ) : (
              <route.element />
            )}
          </>
        }
      />
    );
  });
};
export default AppRoutes;
