import { Login, NoAccess, ApkPage, Profile } from '../../pages';

export const defaultRoutes = [
  {
    path: '/login',
    element: Login,
    isPrivate: false,
  },
  {
    path: '/access-denied',
    element: NoAccess,
    isPrivate: false,
  },
  {
    path: '/mobile-app',
    element: ApkPage,
    isPrivate: false,
  },
  {
    path: '/profile',
    element: Profile,
    isPrivate: true,
  },
];
