import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../../services/http-common";

export const getTypes = createAsyncThunk(
  "types/getAll",
  async (_, thunkAPI) => {
    try {
      const result = await http.post("/api/types");
      if (result.status === 200) return result.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const pending = (state, action) => {
  state.isLoading = true;
  state.error = null;
};

const reject = (state, { payload }) => {
  state.error = payload && payload.message ? payload.message : payload;
  state.isLoading = false;
};

const slice = createSlice({
  name: "types",
  initialState: {
    types: {},
    isLoading: false,
    error: null,
  },
  extraReducers: {
    [getTypes.pending]: pending,
    [getTypes.rejected]: reject,
    [getTypes.fulfilled]: (state, { payload }) => {
      state.types = payload;
    },
  },
});

export default slice.reducer;
