import React from "react";
import { Field, ErrorMessage } from "formik";
import { TextError } from "./TextError";

export const CheckboxSingular = ({
  label,
  name,
  options,
  className = "form-control ",
  ...rest
}) => {
  return (
    <div className="form-control border-0 ">
      <Field name={name} {...rest} />
      <label className="ms-2">{label}</label>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
