import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import Android from "../../assets/android-4.svg";
import { FormattedDate } from "../../components/Layouts/shared/FormattedDate";
import { FileSlice } from "../../store/actions/files";

import "./apkPage.scss";

export const ApkPage = () => {
  const dispatch = useDispatch();

  const releases = useSelector((state) =>
    state.entities.files.list.filter((rel) => rel.published)
  );

  const { isLoading } = useSelector((state) => state.entities.files);

  useEffect(() => {
    const init = async () => {
      await dispatch(FileSlice.paginate());
    };
    init();
  }, [dispatch]);

  return (
    <div>
      <header>
        <span>propx</span>
      </header>
      <div className="wrapper">
        <div className="title-app">
          <span>PROPX Mobile Application</span>
        </div>
        {isLoading ? (
          <div className="text-center pt-5 mt-5">
            <div
              className="spinner-border "
              style={{ width: "5rem", height: "5rem" }}
              role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="container body content">
            <h3>
              {releases.length === 0
                ? "There is no published Releases yet !!"
                : "Releases"}
            </h3>
            <div className="row content-card">
              <div className="container-apk ">
                {releases.map((el) => (
                  <div key={el.fileName}>
                    <Card>
                      <Card.Header>
                        <div className="image-container">
                          <img src={Android} className="image" alt="" />V{" "}
                          {el.version}
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <div className="row mb-1">
                          <div className="profile-personal-info">
                            <div className="row mb-1">
                              <div className="col-4 ">
                                <h5 className="f-w-500">
                                  Name
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-8 ">
                                <span>{el.fileName}</span>
                              </div>
                            </div>
                            <div className="row mb-1">
                              <div className="col-4">
                                <h5 className="f-w-500">
                                  Version
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-8">
                                <span>{el.version}</span>
                              </div>
                            </div>
                            <div className="row mb-1">
                              <div className="col-4">
                                <h5 className="f-w-500">
                                  Release
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-8">
                                <span>
                                  {<FormattedDate date={el.createdAt} />}
                                </span>
                              </div>
                            </div>
                            <div className="row mb-1">
                              <div className="col-4">
                                <h5 className="f-w-500">
                                  Action
                                  <span className="pull-right">:</span>
                                </h5>
                              </div>
                              <div className="col-8">
                                <span>
                                  <a className="download" href={el.path}>
                                    Download
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
