import * as Yup from "yup";

export const clientSchema = Yup.object().shape({
  client: Yup.string()
    .min(3, "minimal 3 characters")
    .required("Client is Required"),
  fullName: Yup.string()
    .min(3, "full name is too short")
    .max(50, "full name is too long")
    .required("full name is Required"),
  discription: Yup.string()
    .min(3, "discription name is too short")
    .max(255, "discription name is too long")
    .required("discription name is Required"),
});
