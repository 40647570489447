import React from "react";
import { useNavigate } from "react-router-dom";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

import "./map.scss";

const UseMap = ({ places = [], google }) => {
  const navigate = useNavigate();
  const goToDeviceDetail = (id) => navigate(`/props/view/${id}`);

  const onMarkerClick = (props, marker, e) => {
    goToDeviceDetail(props.place_.id);
  };

  const center =
    places.length !== 0
      ? places[0].location
      : { lat: 37.61495814081999, lng: 29.32012546019033 };

  return (
    <div id="map-container">
      <Map
        google={google}
        style={{ height: "100%", width: "100%" }}
        zoom={4}
        initialCenter={center}>
        {places.length > 0 &&
          places.map((place, i) => {
            return (
              <Marker
                label={{ text: place.username.toUpperCase(), color: "white" }}
                onClick={onMarkerClick}
                key={place.id}
                place_={place}
                position={place.location}
              />
            );
          })}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_API,
})(UseMap);
