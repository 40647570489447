import React from "react";
import { useNavigate } from "react-router-dom";

import { Table } from "../Layouts/shared/Table-B";
import { FormattedDate } from "../Layouts/shared/FormattedDate";
import { ActionButtons } from "./actions";
import * as MdIcons from "react-icons/md";

import "./index.scss";
import { PropSlice } from "../../store/actions/props";
import { hasMany } from "../../utils/auth/PermissionGate";
import { Scope } from "../../constants/modules";

export const PropsTable = () => {
  const navigate = useNavigate();
  const goToDeviceDetail = (id) => navigate(`/props/view/${id}`);

  const columns = [
    {
      dataField: "id",
      text: "#",
      isDummyField: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "#",
      },
      formatter: (cell, row) => (
        <span role="button" onClick={() => goToDeviceDetail(row.id)}>
          <MdIcons.MdInfoOutline size={18} />
        </span>
      ),
    },
    {
      dataField: "username",
      text: "Prop",
      sort: true,
      headerAlign: "center",
      align: "center",
      style: {
        fontWeight: "bold",
      },
      attrs: {
        "data-label": "Prop",
      },
    },
    {
      dataField: "brand",
      text: "Brand",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Brand",
      },
    },
    {
      dataField: "client",
      text: "Client",
      sort: true,
      isDummyField: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Client",
      },
      formatter: (cell, row) =>
        !row.client ? (
          <span className="error m-1">Required</span>
        ) : (
          <span className="bold">{row.client}</span>
        ),
    },
    {
      dataField: "sim1",
      text: "SIM-1",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "SIM-1",
      },
      formatter: (cell, row) =>
        cell === null ? (
          <span className="error m-1">No SIM</span>
        ) : (
          <span className="bold">{cell}</span>
        ),
    },
    {
      dataField: "sim2",
      text: "SIM-2",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "SIM-2",
      },
      formatter: (cell, row) =>
        cell === null ? (
          <span className="error m-1">No SIM</span>
        ) : (
          <span className="bold">{cell}</span>
        ),
    },
    {
      dataField: "operatorName",
      text: "Operator",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Operator",
      },
    },
    {
      dataField: "operator2Name",
      text: "Operator2",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Operator",
      },
    },
    {
      dataField: "countryIso",
      text: "Country",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Country",
      },
    },
    {
      dataField: "isActive",
      text: "Run",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Run",
      },
      formatter: (cell, row) =>
        row.isActive ? (
          <span role="button" className="text-success bold">
            ACTIVE
          </span>
        ) : (
          <span className="text-danger bold">INACTIVE</span>
        ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Status",
      },
      formatter: (cell, row) =>
        row.status ? (
          <span className="text-success bold">ONLINE</span>
        ) : (
          <span className="text-danger bold">OFFLINE</span>
        ),
    },
    {
      dataField: "createdAt",
      text: "Startup",
      sort: true,
      headerAlign: "center",
      align: "center",
      attrs: {
        "data-label": "Startup",
      },
      formatter: (cell) => <FormattedDate date={cell} />,
    },
    {
      dataField: "action",
      text: "Actions",
      isDummyField: true,
      headerAlign: "center",
      hidden: hasMany([
        Scope.CAN_EDIT_PROP_NO,
        Scope.CAN_EDIT_PROP_CLIENT,
        Scope.CAN_DELETE_PROP_CLIENT,
        Scope.CAN_DELETE_PROP,
        Scope.CAN_DEACTIVE_PROP,
      ]),
      align: "center",
      attrs: {
        "data-label": "Actions",
      },
      formatter: (cell, row) => <ActionButtons row={row} />,
    },
  ];

  return (
    <div className="table-text p-4">
      <Table
        keyField="username"
        columns={columns}
        sliceName="props"
        sliceReducer={PropSlice}
      />
    </div>
  );
};
