import { combineReducers } from "redux";

import { UserSliceInit } from "../actions/users";
import { clientSliceInit } from "../actions/clients";
import { GroupSliceInit } from "../actions/groups";
import typesReducer from "../actions/types";
import { SysLogSliceInit } from "../actions/sysLogs";
import { CDRSliceInit } from "../actions/cdr";
import { PropSliceInit } from "../actions/props";
import { FileSliceInit } from "../actions/files";
import { TaskSliceInit } from "../actions/tasks";
import { AttemptSliceInit } from "../actions/attempts";
import { ServicesSliceInit } from "../actions/services";
import { PermissionSliceInit } from "../actions/permissions";

export default combineReducers({
  users: UserSliceInit.reducer,
  clients: clientSliceInit.reducer,
  groups: GroupSliceInit.reducer,
  types: typesReducer,
  sysLogs: SysLogSliceInit.reducer,
  cdrs: CDRSliceInit.reducer,
  props: PropSliceInit.reducer,
  files: FileSliceInit.reducer,
  tasks: TaskSliceInit.reducer,
  attempts: AttemptSliceInit.reducer,
  services: ServicesSliceInit.reducer,
  permissions: PermissionSliceInit.reducer,
});
