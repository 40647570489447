import React from "react";
import {Field, ErrorMessage} from "formik";
import {TextError} from "./TextError";

export const RadioButton = ({
  label,
  name,
  options,
  className = "form-control border-0",
  ...rest
}) => {
  return (
    <div className={className}>
      <label>{label}</label>
      <Field name={name} {...rest}>
        {({field}) => {
          return options.map((option) => {
            return (
              <div className="form-check" key={option.key}>
                <input
                  className="form-check-input"
                  name={option.value}
                  type="radio"
                  id={option.value}
                  {...field}
                  value={option.value}
                  checked={field.value === option.value}
                />
                <label className="form-check-label" htmlFor={option.value}>
                  {option.key}
                </label>
              </div>
            );
          });
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};
